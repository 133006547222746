import React from "react";
import { Link } from "react-router-dom";
import cardImage from "../../../src/img/content/dummy/card.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const SpecialistProfile: React.FC = () => {
  return (
    <>
      <main className="page-content overflow-auto">
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-6 px-0">
              <p className="mb-0 below__are">Below are your profile details</p>
            </div>
            <div className="col-lg-6 px-0 text-right">
              <button className="edit__button">
                <span className="text-dark font-weight-bold add-btn">
                  EDIT PROFILE
                </span>
              </button>
              <button className="edit__button mr-2">
                <span className="text-dark font-weight-bold add-btn">
                  CANCEL
                </span>
              </button>
              <button className="edit__button">
                <span className="text-dark font-weight-bold add-btn">
                  UPDATE
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="container categories_description pb-5">
          <div className="row py-4 px-3">
            <div className="col-lg-2 col-6 profile__image pb-3 pb-lg-0">
              <div className="card__image">
                <img
                  src={cardImage}
                  alt=""
                  className="img-fluid w-100 card__image--active"
                />
              </div>
            </div>
            <div className="col-lg-10">
              <div className="row profile__edit__wrapper">
                <div className="col-lg-4 profile__details position-relative pb-3 pb-lg-0">
                  <div className="profile__left">
                    <div className="pb-3">
                      <div className="profile__name text-uppercase pb-2">
                        TONY STARK
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="fname"
                        autoComplete="off"
                      />
                    </div>
                    <div className="pb-3">
                      <div className="profile__degree pb-2">PSYCHOLOGIST</div>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="professionalTitle"
                          autoComplete="off"
                        />
                        <span className="text-danger registration__error--msg"></span>
                      </div>
                    </div>
                    <span className="text-danger registration__error--msg"></span>
                    <div className="profile__gender">FEMALE</div>
                    <div id="selfDropdown">
                      <select id="selectGender" name="gender">
                        <option value="" disabled selected>
                          Select gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female"> Female</option>
                        <option value="Other"> Other</option>
                      </select>
                    </div>
                    <span className="text-danger registration__error--msg"></span>
                  </div>
                </div>
                <div className="col-lg-5 pb-3 pb-lg-0">
                  <div className="profile__right">
                    <div className="profile__right">
                      <div className="profile__name text-uppercase pb-2">
                        BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY | COUNSELING
                        PSYCHOLOGY
                      </div>
                      <div className="pb-3">
                        <div id="selfDropdown">
                          <select id="selectExperties" name="areaOfExp">
                            <option value="" disabled selected>
                              Select expertise
                            </option>
                            <option value="Neurologist">Neurologist</option>
                            <option value="Neurologist">Neurologist</option>
                            <option value="Emergency Medicine">
                              Emergency Medicine
                            </option>
                            <option value="Gynaecology">Gynaecology</option>
                            <option value="Pathology">Pathology</option>
                            <option value="Pediatrics">Pediatrics</option>
                            <option value="Dermatologist">Dermatologist</option>
                            <option value="Internal Medicine">
                              Internal Medicine
                            </option>
                            <option value="Oncologist">Oncologist</option>
                            <option value="Psychiatrist">Psychiatrist</option>
                          </select>
                        </div>
                      </div>
                      <div className="profile__degree pb-2">
                        24 YEARS EXPERIENCE
                      </div>
                      <div className="pb-3">
                        <div id="selfDropdown">
                          <select id="selectExperience" name="yrsOfExp">
                            <option value="" disabled selected>
                              Select experience
                            </option>
                            <option value="0 to 1">0 to 1</option>
                            <option value="1 to 5">1 to 5</option>
                            <option value="5 to 10">5 to 10</option>
                            <option value="10 to 15">10 to 15</option>
                            <option value="15 to 20">15 to 20</option>
                            <option value="Greater than 20">
                              Greater than 20
                            </option>
                          </select>
                        </div>
                        <span className="text-danger registration__error--msg"></span>
                      </div>
                      <div className="profile__gender">ENGLISH | SPANISH</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="certifications-download">
                    <Link
                      to={""}
                      className="d-flex justify-content-between align-items-center p-1"
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className="text-danger ml-1"
                        />
                        <span className="font-12 ml-2">Certifications</span>
                      </div>

                      <svg
                        className="icon-download mr-1 download--symbol"
                        viewBox="0 0 16 16"
                      >
                        <path d="M17.1765 8.64706H13.4118V3H7.76471V8.64706H4L10.5882 15.2353L17.1765 8.64706ZM4 17.1176V19H17.1765V17.1176H4Z" />
                      </svg>
                    </Link>
                  </div>
                  <div className="profile__rating">
                    <div className="rating-label checked">
                      <i className="fa fa-long-arrow-down" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default SpecialistProfile;
