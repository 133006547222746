import React from "react";
import DummyImage from "../../card.png";
import { Link } from "react-router-dom";

const UserCategoriesSpecialistDetails: React.FC = () => {
  return (
    <main className="page-content categories-wrpper user_wrapper">
      <div className="container pt-5 pt-lg-0 mt-3 mt-lg-0">
        <>
          {/*--Wellness Category Specilisty Top Wrapper-*/}
          <div className="row mb-5">
            <div className="col-lg-12">
              <div className="wellness__category__specilisty__top__card">
                <div className="row d-flex align-items-center position-relative">
                  <div className="col-lg-3 col-md-6">
                    <div className="wellness__category__specilisty__top__card__image">
                      <img
                        src={DummyImage}
                        alt=""
                        className="img-fluid w-100 wellness__category__specilisty__top__card__image--image"
                        style={{ width: "auto", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-6">
                    <div className="row py-3 position-relative px-3">
                      <div className="col-lg-3 doctor__name__specialist--right--border pb-5 pb-lg-0">
                        <p className="mb-0 font-18 wellness__category__second__card__name pb-1 secondary--color">
                          DR.PRATIK
                        </p>
                        <p className="mb-0 wellness__category__second__card__name pb-1 doctor--degree secondary--color">
                          PSYCHOLOGIST
                        </p>
                        <p className="mb-0  wellness__category__second__card__name pb-1 doctor--gender secondary--color">
                          FEMALE
                        </p>
                        <p className="mb-0 font-12 wellness__category__second__card__year__of__experience py-2 secondary--color">
                          24 YEARS EXPERIENCE
                        </p>
                        <p className="mb-0 extra--small wellness__category__second__card__langauge pb-2 secondary--color">
                          ENGLISH | SPANISH
                        </p>
                      </div>
                      <div className="col-lg-9">
                        <p className="mb-0 biopsychology--text pb-4 secondary--color">
                          BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY | COUNSELING
                          PSYCHOLOGY
                        </p>
                        <p className="mb-0 bio--text pb-2">BIO</p>
                        <p className="mb-0 bio--description secondary--color">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using 'Content here, content
                          here', making it look like readable English-less
                          normal distribution of letters, as opposed to using
                          'Content here, content here', making it look like
                          readable English..
                        </p>
                        <div className="wellness__category__reating__card">
                          <div
                            className="rating js-rating-stars"
                            data-rating={5}
                            data-readonly="true"
                          >
                            <span className="rating-digit pr-2">4.6</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <div className="row equal-height-row">
          <div className="col-lg-6 px-0">
            <ul
              className="nav nav-pills mb-3 my__bookings__tabs"
              id="pills-tab-one"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active px-0 mr-4"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  SPECIALISTS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link px-0 mr-4"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  PACKAGES
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="container-fluid px-0">
                  <div className="row pb-4">
                    <div className="col-lg-12 pt-3 pb-4">
                      <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium ">
                        WELLNESS CATEGORY
                      </h1>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card wellness_catagory_card--active position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            MENTAL HEALTH
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            PREGNANCY
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            HORMONE HEALTH
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-md-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            FITNESS
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 px-0">
                      <div className="my__bookings__left__second__card p-3 mx-3">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-6 pb-3 pb-lg-0">
                            <div className="my__bookings__left__card__image">
                              <img
                                src={DummyImage}
                                alt=""
                                className="img-fluid w-100 my__bookings__left__card__img"
                                style={{ width: "auto", height: "auto" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-8 col-6">
                            <div className="col-lg-12 px-0 pb-3">
                              <p className="mb-0 medium--font my__bookings__left__second__card__header">
                                BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY |
                                COUNSELING PSYCHOLOGY
                              </p>
                            </div>
                            <div className="d-none d-lg-block">
                              <div className="row">
                                <div className="col-lg-12 table-responsive specialist__details">
                                  <table className="table mb-0 table-bordered w-100">
                                    <tbody>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Name :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12 text-left my__booking--name">
                                            Tony Stark Sangli Tasgaon
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Years of experience :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                            10
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Language :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12  text-left my__booking--name">
                                            Marathi
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 d-block d-md-none">
                            <div className="table-responsive specialist__details">
                              <table className="table mb-0 table-bordered w-100">
                                <tbody>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12  specialist-user_details">
                                        Name :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 text-left my__booking--name">
                                        Tony Stark Sangli
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12  specialist-user_details">
                                        Years of experience :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                        10
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 specialist-user_details">
                                        Language :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 text-left my__booking--name">
                                        Marathi
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="container-fluid px-0">
                  <div className="row pb-4">
                    <div className="col-lg-12 pt-3 pb-4">
                      <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium ">
                        FITNESS PACKAGE
                      </h1>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card wellness_catagory_card--active position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            MENTAL HEALTH
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            PREGNANCY
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            HORMONE HEALTH
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-md-0">
                      <div className="wellness_catagory_card position-relative">
                        <div className="explore__our__top__interacting__image__block">
                          <img
                            src={DummyImage}
                            alt=""
                            className="img-fluid wellness_catagory_card_image w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="explore__our__top__interacting__content w-100">
                          <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                            FITNESS
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 px-0">
                      <div className="my__bookings__left__second__card p-3 mx-3">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-6 pb-3 pb-lg-0">
                            <div className="my__bookings__left__card__image">
                              <img
                                src={DummyImage}
                                alt=""
                                className="img-fluid w-100 my__bookings__left__card__img"
                                style={{ width: "auto", height: "auto" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-8 col-6">
                            <div className="col-lg-12 px-0 pb-3">
                              <p className="mb-0 medium--font my__bookings__left__second__card__header">
                                BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY |
                                COUNSELING PSYCHOLOGY
                              </p>
                            </div>
                            <div className="d-none d-lg-block">
                              <div className="row">
                                <div className="col-lg-12 table-responsive specialist__details">
                                  <table className="table mb-0 table-bordered w-100">
                                    <tbody>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Name :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12 text-left my__booking--name">
                                            Tony Stark Sangli Tasgaon
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Years of experience :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                            10
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="align-top max-width-130">
                                          <p className="mb-0 details_title font-12  specialist-user_details">
                                            Language :
                                          </p>
                                        </td>
                                        <td>
                                          <p className="mb-0 details_title font-12  text-left my__booking--name">
                                            Marathi
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 d-block d-md-none">
                            <div className="table-responsive specialist__details">
                              <table className="table mb-0 table-bordered w-100">
                                <tbody>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12  specialist-user_details">
                                        Name :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 text-left my__booking--name">
                                        Tony Stark Sangli
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12  specialist-user_details">
                                        Years of experience :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                        10
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 specialist-user_details">
                                        Language :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 text-left my__booking--name">
                                        Marathi
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 h-100">
            <div className="tab table-responsive">
              <table className="table table--lines table--striped">
                <colgroup>
                  <col className="colgroup-1" />
                  <col className="colgroup-2" />
                  <col className="colgroup-3" />
                  <col className="colgroup-4" />
                  <col className="colgroup-5" />
                  <col className="colgroup-6" />
                  <col />
                </colgroup>
                <thead className="table__header table__header--sticky text-center">
                  <tr>
                    <th className="text-truncate">
                      <span>Specialist</span>
                    </th>
                    <th className="text-truncate">
                      <span>Total Spend</span>
                    </th>
                    <th className="text-truncate">
                      <span>Appoinments</span>
                    </th>
                    <th className="text-truncate">
                      <span>Wellness Category</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                  <tr className="table__row">
                    <td className="table__td">
                      <div className="media-item">
                        <div className="media-item__right">
                          <h5 className="media-item__title text-truncate font-14 text-truncate">
                            Tony Stark
                          </h5>
                        </div>
                      </div>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-truncate font-14">$200</span>
                    </td>
                    <td className="table__td text-truncate">
                      <span className="text-grey text-truncate font-14">
                        42
                      </span>
                    </td>
                    <td className="table__td table__actions text-center">
                      <div className="items-more font-14 user__view--more text-truncate">
                        <Link to={"/app/dashboard/analytics/details"}>
                          {" "}
                          Mental Health
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserCategoriesSpecialistDetails;
