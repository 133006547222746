import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../authContext";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const CareerList = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [careerList, setCareerList] = useState<any>([]);
  const [career, setCareer] = useState<any>("");
  const [careerField, setCareerField] = useState<any>(false);
  const [careerPuckData, setCareerPuckData] = useState<any>([]);

  useEffect(() => {
    getCareerList();
    getCareerPuckData();
    passData();
  }, []);
  const getCareerList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          isActive: true,
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "careerList",
              appName: "selfMaximized",
              folder: "career",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCareerList(result?.data?.data);
        } else {
          setCareerList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const deleteCareer = async (careerId: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              id: careerId,
              menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
              action: "command",
              command: [
                {
                  agent: "deleteCareer",
                  appName: "selfMaximized",
                  folder: "career",
                },
              ],
            },
            {
              headers: { "x-access-token": `${authContext.token}` },
            }
          )
          .then((result) => {
            if (result?.data?.statusCode === 200) {
              Swal.fire({
                title: "Deleted!",
                text: "Your career point has been deleted.",
                icon: "success",
              });
              getCareerList();
            } else {
              return;
            }
          })
          .catch((err) => {
            return toast.error("Something went wrong", err);
          });
      }
    });
  };

  const passData = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: "CAREER",
          data: [
            {
              root: {
                props: {},
              },
              content: [
                {
                  type: "Career",
                  props: {
                    title: "CAREER",
                    id: "Career-2a8c1801-5cb8-4c6f-bb4f-83e8d8c1088f",
                  },
                },
              ],
              zones: {},
            },
          ],

          action: "command",
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          command: [
            {
              agent: "createPage",
              appName: "selfMaximized",
              folder: "pages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success("Heading title edited successfully!");
        } else {
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong," + err);
      });
  };

  const handleCareer = (e: any) => {
    setCareer(e.target.value);
  };

  const getCareerPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "CAREER",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setCareerPuckData(data);
        } else {
          setCareerPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const careerData = careerPuckData[0]?.content?.find(
    (elem: any) => elem.type === "Career"
  );

  console.log(careerData ? careerData?.props?.title : "");

  useEffect(() => {
    setCareer(careerData ? careerData?.props?.title : "");
  }, [careerData]);

  const updateCareer = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: "CAREER",
          data: [
            {
              root: {
                props: {},
              },
              content: [
                {
                  type: "Career",
                  props: {
                    title: career,
                    id: "Career-2a8c1801-5cb8-4c6f-bb4f-83e8d8c1088f",
                  },
                },
              ],
              zones: {},
            },
          ],
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          action: "command",
          command: [
            {
              agent: "editPage",
              appName: "selfMaximized",
              folder: "pages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCareerField(false);
          toast.success("Header title updated successfully!");
          getCareerPuckData();
        }
      });
  };
  return (
    <main className="page-content categories-wrpper user_wrapper overflow-auto">
      <div className="container mt-4 mt-lg-0 mb-3">
        <div className="row">
          <div className="site-wrapper w-100 position-relative magazine_wrapper-inner-wrapper px-0 pt-5 pt-lg-0">
            <section className="tabs-wrapper">
              <div className="tabs-container">
                <div className="tabs-block">
                  <div className="tabs">
                    <input type="radio" name="tabs" id="tab1" checked />
                    <label htmlFor="tab1" className="pl-0 pb-2">
                      <span className="material-icons active text-uppercase">
                        Recent Career
                      </span>
                    </label>
                    <div className="tab magazine_wrapper px-4 my-4">
                      <div className="row">
                        {careerField ? (
                          <input
                            type="text"
                            value={career}
                            className="text-white"
                            name="career"
                            style={{ border: "1px solid white" }}
                            onChange={handleCareer}
                          />
                        ) : (
                          <h5 className="text-white">
                            {careerData ? careerData?.props?.title : ""}
                          </h5>
                        )}

                        <span
                          style={{
                            cursor: "pointer",
                            top: "25px",
                            color: "white",
                          }}
                          onClick={() => {
                            setCareerField(!careerField);
                          }}
                        >
                          <img
                            src="https://img.icons8.com/ios-glyphs/30/edit.png" // Replace with your icon URL
                            alt="Edit"
                            style={{
                              width: "20px",
                              height: "20px",
                              filter: "brightness(0) invert(1)",
                            }}
                          />
                        </span>
                        <button
                          className="btn btn-light btn-sm py-0"
                          onClick={updateCareer}
                        >
                          UPDATE
                        </button>
                      </div>
                      <div className="row faq_wrapper-inner">
                        {careerList.length !== 0 && careerList !== null ? (
                          careerList.map((elem: any) => (
                            <div className="col-lg-6  pb-2 pb-lg-0 mb-3">
                              <div className="row magazine_wrapper-inner align-items-end py-2 mr-1">
                                <div className="col-10">
                                  <div className="media-item">
                                    <div className="media-item__right faq_box">
                                      <h5 className="mb-0 people_joined text-uppercase">
                                        {elem.title}
                                      </h5>
                                      <p className="mb-0 font-14 congratulation_text text-truncate text-uppercase">
                                        {elem.summary}
                                      </p>
                                      <p className="mb-0 font-12 congratulation_text text-truncate text-uppercase">
                                        {elem.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1">
                                  <Link
                                    to="/dashboard/edit_career"
                                    className="edit_icon"
                                    state={{ careerId: elem.id }}
                                  >
                                    <svg
                                      className="icon-icon-drafts border--bottom ml-4"
                                      viewBox="0 0 22 22"
                                    >
                                      <path d="M17.8736 4.12588C16.5627 2.81406 15.5782 3.01042 15.5782 3.01042L10.0763 8.51223L3.91818 14.6695L3 18.9986L7.32999 18.0804L13.4882 11.9249L18.99 6.42314C18.989 6.42314 19.1863 5.4386 17.8736 4.12588ZM7.07272 17.5604L5.59636 17.8786C5.45454 17.6122 5.28272 17.3458 4.96909 17.0313C4.65454 16.7168 4.38818 16.5468 4.12182 16.4031L4.44 14.9277L4.86727 14.5013C4.86727 14.5013 5.66999 14.5177 6.57726 15.4249C7.48363 16.3304 7.5009 17.1349 7.5009 17.1349L7.07272 17.5604Z" />
                                    </svg>
                                  </Link>
                                </div>
                                <div className="col-1">
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    onClick={() => deleteCareer(elem.id)}
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="feather feather-trash text-white ml-2 cursor_pointer"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                                    <path d="M10 11v6"></path>
                                    <path d="M14 11v6"></path>
                                    <line x1="9" y1="6" x2="15" y2="6"></line>
                                    <line x1="12" y1="2" x2="12" y2="6"></line>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span>No career points available</span>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-right">
                          <button
                            onClick={() => navigate("/dashboard/career_create")}
                            className="categories__add-btn rounded-0 add-btn btn__border--radius"
                          >
                            <svg
                              className="icon-icon-plus mr-1"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                              />
                            </svg>
                            <span className="text-dark font-weight-bold add-btn">
                              ADD
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CareerList;
