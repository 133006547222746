import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../../../authContext";
import axios from "axios";
import filterIcon from "../../../../img/content/icons/Icon feather-filter.svg";
import packageImage from "../../../../nutra-1.png";
import { toast } from "react-toastify";
import useDebounce from "../../../../customHooks/useDebounce";
import Modal from "../../../../SpecialistDashboard/Layout/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const RecentPackages: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState<any>(false);
  const [packageList, setpackageList] = useState<any>([]);
  const [filterValue, setFilterValue] = useState<any>("");
  const [filterType, setFilterType] = useState<any>("");
  const [placeholder, setPlaceholder] = useState<any>("");
  const [broadCategory, setBroadCategory] = useState<any>("");
  const [broadCatList, setBroadCategoryList] = useState<any>([]);
  const debouncedFilterValue = useDebounce(filterValue, 700);

  useEffect(() => {
    broadCategoryList();
  }, []);

  const createBroadCategory = async () => {
    if (!broadCategory) {
      return false;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: broadCategory,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf8",
          action: "command",
          command: [
            {
              agent: "createBroadCategory",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.info(result?.data?.message, {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast text-uppercase",
            bodyClassName: "custom-toast",
          });
          setBroadCategory("");
          broadCategoryList();
        } else {
          return toast.info("ERROR OCCURED WHILE CREATING BROAD CATEGORY", {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast text-uppercase",
            bodyClassName: "custom-toast",
          });
        }
      });
  };

  const broadCategoryList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5000,
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: "1",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "broadCategoryList",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setBroadCategoryList(result?.data?.data);
        } else {
          setBroadCategoryList([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getPackagesList = async (filterObj: any) => {
    let filters: any = [];

    if (filterObj) {
      filters.push(
        {
          field: "publish",
          operator: "eq",
          value: 1,
        },
        filterObj
      );
    } else {
      filters.push({
        field: "publish",
        operator: "eq",
        value: 1,
      });
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: filters,
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "packageList",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setpackageList(result?.data?.data);
        } else {
          setpackageList([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const blogList = useCallback(getPackagesList, [authCtx.token]);

  useEffect(() => {
    blogList("");
  }, [blogList]);

  useEffect(() => {
    getPackagesList("");
  }, [filterValue === "", filterType]);

  console.log(packageList);

  useEffect(() => {
    if (filterValue) {
      getPackagesList({
        field: "title",
        operator: "contain",
        value: filterValue,
      });
    }
  }, [debouncedFilterValue]);

  useEffect(() => {
    if (filterType && filterValue) {
      getPackagesList({
        field: filterType,
        operator: "contain",
        value: filterValue,
      });
    }
  }, [debouncedFilterValue]);

  useEffect(() => {
    if (filterType === "all") {
      getPackagesList("");
    }
  }, [filterType === "all"]);

  const selectFilter = (filterType: any) => {
    setFilterType(filterType);
    setPlaceholder(`Search by ${filterType}`);
  };

  const deleteBroadCat = async (id: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          id: id,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf8",
          action: "command",
          command: [
            {
              agent: "deleteBroadCategory",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authCtx.token,
          },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.info("BROAD CATEGORY DELETED SUCCESSFULLY!", {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast text-uppercase",
            bodyClassName: "custom-toast",
          });
          broadCategoryList();
        } else {
          return toast.info(result?.data?.message, {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast text-uppercase",
            bodyClassName: "custom-toast",
          });
        }
      })
      .catch((err) => {
        return toast.info(err, {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 30 30"
            >
              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
            </svg>
          ),
          className: "custom-toast text-uppercase",
          bodyClassName: "custom-toast",
        });
      });
  };

  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container mt-4 mt-lg-0">
          <div className="row">
            <Modal isOpen={open}>
              <div tabIndex={-1}>
                <div className="modal-dialog modal-dialog-custom modal-dialog-centered user__calander__booking--list modal-xl">
                  <div className="modal-content modal-content-custom min-width-800 buy__product__modal py-3">
                    <div className="modal-header py-2 border-0">
                      CREATE BROAD CATERGORY
                      <button
                        className="custom-cross-btn"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="2x"
                          color="#fff"
                          className="cross-button"
                        />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-6 bg-black-v1">
                          <div className="bg-black-v2 p-3 h-100 brod-category-outer flex flex-column justify-between">
                            <div className="pb-3">
                              <label
                                htmlFor="exampleFormControlInput2 mb-1 mt-2"
                                className="mb-2 text-uppercase text-white"
                                id="changePassword"
                              >
                                Broad Category Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase common-input-style"
                                id="enterName"
                                name="broadCategory"
                                value={broadCategory}
                                onChange={(e) => {
                                  setBroadCategory(e.target.value);
                                }}
                                placeholder="enter name"
                                autoComplete="off"
                              />
                            </div>
                            <div className="d-grid">
                              <button
                                type="button"
                                className="btn btn-primary btn-block unfilled-white-btn"
                                onClick={createBroadCategory}
                              >
                                ADD CATEGORY
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 bg-black-v1">
                          <div className="bg-black-v2 p-3 h-100">
                            <div className="pb-3 font-14">
                              <p className="mb-0 text-white">
                                LIST OF ALL YOUR BROAD CATEGORIES
                              </p>
                            </div>
                            <div className="group-list-outer">
                              {broadCatList.length !== 0 &&
                              broadCatList !== null ? (
                                broadCatList.map((elem: any) => (
                                  <div
                                    className="group-list text-uppercase p-3 mb-3"
                                    key={elem?.id}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <p className="font-14 mb-0 text-white font-14 text-uppercase">
                                        {elem?.name}
                                      </p>
                                      <div className="group-list-inner">
                                        <div
                                          className="icons-group d-flex gap-3 "
                                          onClick={() =>
                                            deleteBroadCat(elem?.id)
                                          }
                                        >
                                          <button>
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <span></span>
                              )}
                            </div>

                            <div className="d-grid">
                              <Link to={"/dashboard/packages/addpackage"}>
                                <button
                                  type="button"
                                  className="btn btn-light btn-block text-uppercase filled-white-btn"
                                >
                                  Next
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="site-wrapper w-100 position-relative magazine_wrapper-inner-wrapper px-0 pt-5 pt-lg-0">
              <div className="d-none d-lg-block"></div>

              <div className="row d-block d-lg-none">
                <div className="col-lg-12">
                  <div className="header__search-mobile header__search-mobile-new magazine_wrapper-search-mobile d-flex justify-content-center align-items-center ">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle toggle_dropdown--btn mr-2"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="global-dropdown-icon">
                          <img
                            src={filterIcon}
                            alt=""
                            className="img-fluid w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                      </button>
                      <div
                        className="dropdown-menu dropdown--menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span
                          className="dropdown-item"
                          onClick={() => selectFilter("title")}
                        >
                          TITLE
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => selectFilter("duration")}
                        >
                          DURATION
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => selectFilter("price")}
                        >
                          PRICE
                        </span>
                      </div>
                    </div>

                    <div className="header__search-mobile w-100">
                      <form className="form-search" action="#" method="GET">
                        <div className="form-search__container">
                          <span className="form-search__icon-left">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 18"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.1184 13.4407C11.1132 14.1763 9.87362 14.6106 8.53259 14.6106C5.17579 14.6106 2.45456 11.8894 2.45456 8.53258C2.45456 5.17577 5.17579 2.45455 8.53259 2.45455C11.8894 2.45455 14.6106 5.17577 14.6106 8.53258C14.6106 9.87378 14.1762 11.1135 13.4404 12.1188C13.4461 12.1242 13.4518 12.1297 13.4574 12.1353L15.2716 13.9495C15.6368 14.3147 15.6368 14.9067 15.2716 15.2719C14.9064 15.6371 14.3144 15.6371 13.9492 15.2719L12.135 13.4577C12.1294 13.4521 12.1238 13.4464 12.1184 13.4407ZM12.874 8.53258C12.874 10.9303 10.9303 12.874 8.53259 12.874C6.13488 12.874 4.19114 10.9303 4.19114 8.53258C4.19114 6.13486 6.13488 4.19113 8.53259 4.19113C10.9303 4.19113 12.874 6.13486 12.874 8.53258Z"
                              />
                            </svg>
                          </span>
                          <input
                            className="form-search__input"
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <section className="tabs-wrapper pakages-relative">
                <div className="package-btn-group package-btn-group-category">
                  <button
                    className="btn btn-light font-12 mr-3 "
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    CREATE BROAD CATEGORY
                  </button>
                  <Link to={"/dashboard/packages/addpackage"}>
                    <button className="btn btn-light font-12">
                      CREATE PACKAGE
                    </button>
                  </Link>
                </div>
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="pl-0 pb-2">
                        <span className="material-icons active text-uppercase">
                          Recent Packages
                        </span>
                      </label>

                      <div className="tab  px-0 my-2">
                        {/* <div className="row">
                          <div className="col-lg-9 pr-0 pr-lg-3">
                            <div className="row">
                              {packageList.length !== 0 &&
                              packageList !== null ? (
                                packageList.map((elem: any) => (
                                  <div
                                    className="col-lg-6 pb-2 pb-lg-0 mb-3"
                                    key={elem.id}
                                  >
                                    <div className="row magazine_wrapper-inner align-items-end py-2 mr-1">
                                      <div className="col-11">
                                        <div className="media-item">
                                          <div className="media-item__icon recent--blog--image">
                                            <img
                                              className="media-item__thumb"
                                              src={elem.image}
                                              alt=""
                                              style={{
                                                width: "auto",
                                                height: "60px",
                                              }}
                                              loading="lazy"
                                            />
                                          </div>
                                          <div className="media-item__right">
                                            <h5 className="font-12 mb-0 people_joined">
                                              {elem.title.toUpperCase()}
                                            </h5>
                                            <p className="mb-0 font-12 congratulation_text text-truncate py-1 text-uppercase">
                                              {elem.summary}
                                            </p>
                                            <p className="font-10 timing mb-0">
                                              {elem.category
                                                .slice(0, 3)
                                                .map(
                                                  (
                                                    elem: any,
                                                    index: number
                                                  ) => (
                                                    <span key={index}>
                                                      {index > 0 && ", "}
                                                      {elem.name}
                                                    </span>
                                                  )
                                                )}
                                              {elem.category.length > 3 && (
                                                <button
                                                  type="button"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  className="tooltip-button"
                                                  title={elem.category
                                                    .slice(3)
                                                    .map(
                                                      (elem: any) => elem.name
                                                    )
                                                    .join(",\n")}
                                                >
                                                  <span className="text-white ml-1">
                                                    {" "}
                                                    +{elem.category.length - 3}
                                                  </span>
                                                </button>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-1">
                                        <Link
                                          to={`/dashboard/packages/editpackage/${elem.uuid}`}
                                          className="edit_icon"
                                        >
                                          <svg
                                            className="icon-icon-drafts border--bottom"
                                            viewBox="0 0 22 22"
                                          >
                                            <path d="M17.8736 4.12588C16.5627 2.81406 15.5782 3.01042 15.5782 3.01042L10.0763 8.51223L3.91818 14.6695L3 18.9986L7.32999 18.0804L13.4882 11.9249L18.99 6.42314C18.989 6.42314 19.1863 5.4386 17.8736 4.12588ZM7.07272 17.5604L5.59636 17.8786C5.45454 17.6122 5.28272 17.3458 4.96909 17.0313C4.65454 16.7168 4.38818 16.5468 4.12182 16.4031L4.44 14.9277L4.86727 14.5013C4.86727 14.5013 5.66999 14.5177 6.57726 15.4249C7.48363 16.3304 7.5009 17.1349 7.5009 17.1349L7.07272 17.5604Z" />
                                          </svg>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-lg-12 text-center">
                                  <span className="text-white font-14 text-uppercase">
                                    No Data Available
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 mt-3 mt-lg-0 px-0">
                            <Link to={"/dashboard/packages/addpackage"}>
                              <div className="add-blog-wrapper_magazine h-100 w-100 d-flex align-items-center justify-content-center">
                                <div className="add-new-blog text-center p-5 p-lg-0 d-flex align-items-center">
                                  <div className="font-13 text-center">
                                    <svg
                                      className="icon-icon-plus mb-2"
                                      viewBox="0 0 14 14"
                                      height={40}
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                      />
                                    </svg>
                                    <p className="add-the-eatured-photos mb-0 text-uppercase">
                                      Create Package
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div> */}
                        {/* 
                        <ul
                          className="nav nav-tabs mt-0 border-0"
                          id="nestedTab"
                          role="tablist"
                        >
                          <li className="nav-item mr-2">
                            <a
                              className="nav-link active text-uppercase font-12"
                              id="nested-home-tab"
                              data-toggle="tab"
                              href="#nested-home"
                              role="tab"
                              aria-controls="nested-home"
                              aria-selected="true"
                            >
                              Active
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link text-uppercase font-12"
                              id="nested-settings-tab"
                              data-toggle="tab"
                              href="#nested-settings"
                              role="tab"
                              aria-controls="nested-settings"
                              aria-selected="false"
                            >
                              Inactive
                            </a>
                          </li>
                        </ul> */}

                        <div className="tab-content mt-2" id="nestedTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="nested-home"
                            role="tabpanel"
                            aria-labelledby="nested-home-tab"
                          >
                            <div className="row mobile-category-gap">
                              {packageList.length !== 0 &&
                              packageList !== null ? (
                                packageList.map((elem: any) => (
                                  <div className="col-md-3">
                                    <div className="common-card-pattern common-card-pattern-new h-100 position-relative">
                                      <div className="common-card-image">
                                        <img
                                          src={elem?.image}
                                          alt=""
                                          className="img-fluid w-100 common-card-image-img"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                          }}
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="common-card-disc">
                                        <p className="text-white text-uppercase mb-0 font-12 mb-1">
                                          {elem?.title}
                                        </p>
                                        <p className="text-white text-uppercase mb-1">
                                          {elem?.summary}
                                        </p>
                                        <p className="text-white text-uppercase mb-0 font-12 mb-1">
                                          WELLNESS THERAPIES :{" "}
                                          {elem?.category?.length}
                                        </p>
                                        <div className="common-card-footer-group">
                                          <div className="coomon-cart-footer-box text-white text-uppercase mb-0">
                                            <Link
                                              to={`/dashboard/packages/editpackage/${elem?.uuid}`}
                                              className="text-uppercase"
                                            >
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <span>NO PACKAGE AVAILABLE</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RecentPackages;
