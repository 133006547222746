import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import axios, { AxiosResponse } from "axios";
import { useParams, useNavigate } from "react-router-dom";
import admin_logo from "../../../../src/img/content/logo/admin-logo.png";
import { toast } from "react-toastify";

interface NewPasswordProps {}

interface ChangePassword {
  statusCode: number;
  status: string;
  message: string;
  data: {
    password: string;
    confirmPassword: string;
    resetPassword: string;
    action: string;
    command: [
      {
        agent: string;
        appName: string;
        folder: string;
      }
    ];
    response: {
      status: string;
      statusCode: number;
      agent: string;
      data: Array<{ [key: string]: string }>;
      code: number;
      message: string;
    };
  };
}

const NewPassword: React.FC<NewPasswordProps> = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
  const [verifyPasswordData, setVerifyPasswordData] = useState<any>("");
  const [eyeChecked, setEyeChecked] = useState<any>("password");
  const [validationMsg, setValidationMsg] = useState<any>("");
  const [eyeConfirmPasswordChecked, setEyeConfirmPasswordChecked] =
    useState<any>("password");
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleEyeBtn = (eyeType: any) => {
    if (eyeType === "closeEye") {
      setEyeChecked("text");
    } else if (eyeType === "openEye") {
      setEyeChecked("password");
    }
  };

  const handleNewPassword = (e: any) => {
    setNewPassword(e.target.value);
    setValidationMsg("");
  };

  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value);
    setValidationMsg("");
  };

  const toggleConfirmPasswordEyeBtn = (eyeType: any) => {
    if (eyeType === "closeEye") {
      setEyeConfirmPasswordChecked("text");
    } else if (eyeType === "openEye") {
      setEyeConfirmPasswordChecked("password");
    }
  };

  useEffect(() => {
    // Check the condition and set shouldNavigate accordingly
    const status = verifyPasswordData?.status;
    if (status === "success") {
      setShouldNavigate(true);
    }
  }, [verifyPasswordData]); // Add relevant dependencies here

  useEffect(() => {
    if (shouldNavigate) {
      navigate("/login", { replace: true });
    }
  }, [shouldNavigate, navigate]);

  const sendNewAndConfirmPassword = async () => {
    if (!newPassword && !confirmPassword) {
      return setValidationMsg("Please enter password first");
    }
    if (newPassword && !confirmPassword) {
      return setValidationMsg("Please enter confirm password");
    }
    if (!newPassword && confirmPassword) {
      return setValidationMsg("Please enter new password");
    }
    if (newPassword !== confirmPassword) {
      return setValidationMsg("Passwords didnt match");
    }

    try {
      const response: AxiosResponse<ChangePassword> = await axios.post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          password: newPassword,
          confirmPassword: confirmPassword,
          resetPassword: id,
          action: "command",
          command: [
            {
              agent: "resetPassword",
              appName: "selfMaximized",
              folder: "auth",
            },
          ],
        }
      );
      setVerifyPasswordData(response.data);
      if (response?.data?.status === "failed") {
        toast.error(
          `${response?.data?.message} by clicking on reset password button again`
        );
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {/* New Password */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="auth-logo-inner-r">
              <img
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                className="img-fluid admin__logo mt-2"
                alt="logo"
                style={{ width: "220px", height: "auto" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-lg-4 mx-auto">
            <form
              className="auth-panel__wrapper"
              method="POST"
              onSubmit={sendNewAndConfirmPassword}
            >
              <div className="auth-panel__container">
                <div className="auth-panel__body">
                  <div className="auth-panel__body-wrapper">
                    <div className="login-div-wrapper">
                      <h1 className="login-heading mb-0 font-weight-bold pb-4">
                        SET PASSWORD
                      </h1>
                    </div>
                    <div className="form-group position-relative">
                      <label htmlFor="email_id">
                        <span className="text-white pr-1">*</span> NEW PASSWORD
                      </label>
                      <div className="input_group">
                        <input
                          className="input text-white pr-2"
                          type={`${eyeChecked}`}
                          name="newPassword"
                          value={newPassword}
                          onChange={handleNewPassword}
                          autoComplete="off"
                          placeholder="Enter New Password"
                        />
                      </div>
                      <div className="password__show__hide__icons">
                        {eyeChecked === "password" ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => toggleEyeBtn("closeEye")}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => toggleEyeBtn("openEye")}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-1 position-relative">
                      <label htmlFor="password text-white">
                        <span className="text-white pr-1">*</span> CONFIRM
                        PASSWORD
                      </label>
                      <div className="input_group">
                        <input
                          className="input text-white pr-2"
                          type={`${eyeConfirmPasswordChecked}`}
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                          autoComplete="off"
                          placeholder="Enter Confirm Password"
                        />
                      </div>
                      <div className="password__show__hide__icons">
                        {eyeConfirmPasswordChecked === "password" ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() =>
                              toggleConfirmPasswordEyeBtn("closeEye")
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() =>
                              toggleConfirmPasswordEyeBtn("openEye")
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <span className="text-danger text-uppercase font-12">
                        {validationMsg}
                      </span>
                    </div>
                    <div className="auth-panel__submit">
                      <button
                        className="button login-btn w-100 rounded-0"
                        type="button"
                        onClick={sendNewAndConfirmPassword}
                      >
                        <span className="button__text font-weight-bold font-14">
                          SUBMIT
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
