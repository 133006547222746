import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../../../authContext";
import axios from "axios";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";

const ApproveSpecialistDetails: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [loader, setLoader] = useState<any>("");
  const [approveLoader, setApproveLoader] = useState<any>("");
  const [rejectLoader, setRejectLoader] = useState<any>("");
  const [specialistData, setSpecialistData] = useState<any>([]);
  const [specialistTags, setSpecialistTags] = useState<any>("");
  const [specialistBio, setSpecialistBio] = useState<any>("");
  const [showFullDescription, setShowFullDescription] = useState<any>(false);
  const [truncatedSpecialistBio, setTruncatedSpecialistBio] = useState<any>("");
  const [showData, setShowData] = useState<any>(false);
  const [areaOfExpData, setAreaOfExpData] = useState<any>([]);

  const [yrsOfExp, setExp] = useState<any>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getSpecialistDetails = useCallback(async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5,
              filters: [
                {
                  field: "uuid",
                  operator: "eq",
                  value: `${id}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  table: "Users",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
          action: "command",
          command: [
            {
              agent: "specialistListsData",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((response) => {
        setSpecialistData(response.data.data[0]);
        setExp(response.data.data[0].DoctorExperiences[0]);
        setSpecialistTags(response.data.data[0].tags);
        setLoader(false);

        const areaOfExpertise = response.data.data[0].DoctorExperiences
          ? response.data.data[0].DoctorExperiences[0].areaofexpertise
          : "";

        const areaOfExpData =
          typeof areaOfExpertise === "string"
            ? JSON.parse(areaOfExpertise)
            : areaOfExpertise;

        setAreaOfExpData(areaOfExpData);

        if (response?.data?.data[0].bio.split(" ").length > 20) {
          setSpecialistBio(response?.data?.data[0].bio.split("\n").join("\n"));
          let sentences =
            response?.data?.data[0].bio.match(/[^\.!\?]+[\.!\?]+/g); // Match sentences
          setTruncatedSpecialistBio(sentences.slice(0, 3).join(" ")); // Take the first three sentences and join them
        } else if (response?.data?.data[0].bio.split(" ").length < 20) {
          setSpecialistBio(response?.data?.data[0].bio);
          setShowData(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, authContext.token]);

  const approveSpecialist = async () => {
    setApproveLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: `${specialistData.uuid}`,
          isActive: 1,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          role: "specialist",
          email: `${specialistData.email}`,
          baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}/#`,
          action: "command",
          command: [
            {
              agent: "approveSpecialist",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          toast.success(`Email sent to specialist ${specialistData.name}`);
          setApproveLoader(false);
          navigate("/dashboard/specialists");
        } else {
          toast.info("Something went wrong!");
          return;
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setApproveLoader(false);
        return;
      });
  };

  const rejectSpecialist = async () => {
    setRejectLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: `${specialistData.uuid}`,
          isActive: 2,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          role: "specialist",
          email: `${specialistData.email}`,
          baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}`,
          action: "command",
          command: [
            {
              agent: "rejectSpecialist",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          toast.success("Specialist rejected successfully!");
          setRejectLoader(false);
          navigate("/dashboard/specialists");
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setRejectLoader(false);
      });
  };

  useEffect(() => {
    getSpecialistDetails();
  }, [getSpecialistDetails]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderLanguage = (language: any): React.ReactNode => {
    let filteredLanguage: any;
    if (language) {
      const parsedLang = JSON.parse(language);
      filteredLanguage = parsedLang.map((elem: any) => elem).join(" | ");
    }
    return filteredLanguage ? filteredLanguage : null;
  };
  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0 mt-4 mt-md-0">
          <div className="row pb-4 align-items-center ">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 d-flex justify-content-end px-0">
              <div className="d-flex">
                <div className="move-specialist-wrapper">
                  {approveLoader ? (
                    <button
                      className="approve__reject--btn mr-3 btn__border--radius"
                      type="button"
                    >
                      {" "}
                      <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                      <span className="font-14 font-weight-bold text-dark">
                        {" "}
                        LOADING...
                      </span>{" "}
                    </button>
                  ) : (
                    <button
                      className="approve__reject--btn mr-3 btn__border--radius"
                      type="button"
                      onClick={approveSpecialist}
                    >
                      APPROVE{" "}
                    </button>
                  )}
                  {rejectLoader ? (
                    <button className="approve__reject--btn" type="button">
                      {" "}
                      <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                      <span className="font-14 font-weight-bold text-dark">
                        {" "}
                        LOADING...
                      </span>{" "}
                    </button>
                  ) : (
                    <button
                      className="approve__reject--btn"
                      type="button"
                      onClick={rejectSpecialist}
                    >
                      REJECT{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="specialist-container_wrapper specialists-approve-reject-wrapper ">
            <div className="row pb-4">
              <div className="col-lg-12 specialist-container_wrapper-left-part py-4">
                <div className="row border-bottom">
                  <div className="col-lg-6 pb-3">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 pb-3">
                        <div className="thumb specialist__doctor--image-1 w-100">
                          <img
                            src={specialistData.profile}
                            alt=""
                            className="w-100"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive specialist__details">
                              <table className="table mb-0 table-bordered w-100">
                                <tbody>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Name :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left">
                                        {specialistData.name}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Phone no :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left">
                                        {specialistData.phone}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Email ID :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left text-transform-unset">
                                        {specialistData.email}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Years of experience :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left">
                                        {yrsOfExp
                                          ? `${yrsOfExp.yearofexperience}`
                                          : "No experience found"}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Language :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left">
                                        {renderLanguage(
                                          specialistData?.language
                                        )}
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="align-top max-width-130">
                                      <p className="mb-0 details_title font-12 pb-1 specialist-user_details">
                                        Gender :
                                      </p>
                                    </td>
                                    <td>
                                      <p className="mb-0 details_title font-12 pb-1 text-left">
                                        {specialistData.gender}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pb-3">
                    <div className="row here-is-what_describe pb-3">
                      <div className="col-lg-8 col-md-6 pb-3">
                        <div className="col-lg-12 pb-3 px-0">
                          <p className="mb-0 here-is-what-title font-14">
                            Here’s what describes me the best…
                          </p>
                        </div>
                        <div className="col-lg-12 px-0">
                          {specialistTags.split(",").map((elem: any) => (
                            <span className="here-box font-10 mr-2" key={elem}>
                              {elem}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-6 pb-3">
                        <div className="certifications-download">
                          <Link
                            to={yrsOfExp ? `${yrsOfExp.file}` : "No file found"}
                            className="d-flex justify-content-between align-items-center p-1"
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="text-danger ml-1"
                              />
                              <span className="font-12 ml-2">
                                Certifications
                              </span>
                            </div>

                            <svg
                              className="icon-download mr-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M17.1765 8.64706H13.4118V3H7.76471V8.64706H4L10.5882 15.2353L17.1765 8.64706ZM4 17.1176V19H17.1765V17.1176H4Z" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row my-experties_wrapper text-uppercase">
                      <div className="col-lg-12 pb-2">
                        <p className="mb-0 here-is-what-title font-14">
                          My expertise
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-12">
                                <ul className="pl-0">
                                  {(() => {
                                    try {
                                      return areaOfExpData.length > 0 ? (
                                        <>
                                          {areaOfExpData.map(
                                            (expertise: any, index: number) => (
                                              <div
                                                className="col-12"
                                                key={index}
                                              >
                                                <ul className="p-0">
                                                  <li className="my-experties_sub font-12">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="6"
                                                      height="6"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      className="feather feather-circle text-white"
                                                    >
                                                      <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                      ></circle>
                                                    </svg>{" "}
                                                    <span className="pl-1">
                                                      {expertise.category}
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <span>No expertise listed.</span>
                                      );
                                    } catch (error) {
                                      console.error(
                                        "Invalid JSON in areaofexpertise:",
                                        error
                                      );
                                      return (
                                        <span>Invalid expertise data.</span>
                                      );
                                    }
                                  })()}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bio-text-wrapper py-3">
                  <div className="col-lg-12">
                    <p className="mb-0 font-14">Bio</p>
                  </div>
                </div>
                <div className="row bio-wrapper align-items-center">
                  <div className="col-lg-12">
                    {!showData && (
                      <p className="mb-0 font-14">
                        {showFullDescription
                          ? specialistBio
                          : truncatedSpecialistBio}
                      </p>
                    )}
                    {showData && (
                      <p className="mb-0 font-14">{specialistData.bio}</p>
                    )}
                    {!showData &&
                      specialistBio.length > truncatedSpecialistBio.length && (
                        <button onClick={toggleDescription}>
                          {showFullDescription ? (
                            <span className="font-14 view__more__less text-primary">
                              Less
                            </span>
                          ) : (
                            <span className="font-14 view__more__less text-primary">
                              More
                            </span>
                          )}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ApproveSpecialistDetails;
