import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../authContext";
import CategoryList from "./Common/CategoryList";
import TodayAppointment from "./Common/TodayAppointment";
import WeeklyAppoinments from "./Common/WeeklyAppointments";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "../../SpecialistDashboard/Layout/Modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment-timezone";
import { format, startOfWeek, endOfWeek, endOfMonth } from "date-fns";

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}
const UserBody: React.FC = () => {
  const [userAppointments, setUserAppointmentData] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [slots, setSlots] = useState<any>([]);
  const [cartProductData, setCartProductData] = useState<any>();
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const authCtx = useContext(AuthContext);
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;

  useEffect(() => {
    userRemainingAppointment();
  }, []);

  useEffect(() => {
    getSpecialistBookingSlots("", "");
  }, [cartProductData?.doctorID]);

  const handleOpen: any = (cartProductData: any) => {
    setCartProductData(cartProductData);
    setOpen(true);
  };

  const handleClose: any = () => {
    setOpen(false);
  };

  const handleRangeSelection = async (selectedRange: any) => {
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate);
    } else {
      console.error("Invalid date range selected");
    }
  };

  const handleMonthChange = ({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  };

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };
  const getSpecialistBookingSlots = async (
    newStartDate: any,
    newEndDate: any
  ) => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: moment(newStartDate ? newStartDate : startDate).format(
                  "YYYY-MM-DD"
                ),
                opr: "AND",
                value2: moment(newEndDate ? newEndDate : endDate).format(
                  "YYYY-MM-DD"
                ),
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: cartProductData?.doctorID,
              },
              {
                field: "Duration",
                operator: "eq",
                table: "BookingCalendar",
                value: parseFloat(
                  cartProductData?.appointment_duration
                ).toFixed(2),
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          // Get the current date and time in the selected timezone
          const now = moment.tz(userTimezone);

          // Helper function to preprocess StartTime and convert to localized datetime
          const getSlotDateTime = (startTime: string): moment.Moment => {
            return moment
              .tz(
                startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                "YYYY-MM-DDTHH:mm:ssZ", // Parse format
                "UTC"
              )
              .tz(userTimezone);
          };

          // Filter out past dates and times
          const filteredSlots = responseData.filter((slot) => {
            const slotDateTime = getSlotDateTime(slot.StartTime);

            // Include only slots in the future
            return slot.isBooked === 0 && slotDateTime.isAfter(now);
          });

          // Group slots by their localized calendarDate
          const groupedSlots: GroupedSlots = filteredSlots.reduce(
            (acc: GroupedSlots, slot: Slot) => {
              const slotDate = getSlotDateTime(slot.StartTime).format(
                "YYYY-MM-DD"
              );
              acc[slotDate] = [...(acc[slotDate] || []), slot];
              return acc;
            },
            {}
          );

          // Organize slots into days with their corresponding slots
          const filteredSlotsArray = Object.entries(groupedSlots)
            .map(([day, slots]: [string, Slot[]]) => ({
              dayOfWeek: moment(day, "YYYY-MM-DD")
                .tz(userTimezone)
                .format("dddd"), // Convert to day of the week
              slots,
            }))
            .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

          setSlots(filteredSlotsArray);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const userRemainingAppointment = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userID: userInfo.id,
          action: "command",
          command: [
            {
              agent: "remainAppointment",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUserAppointmentData(result?.data?.data[0]?.carts);
        } else {
          setUserAppointmentData([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const bookSlot = async () => {
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          start_time: selectedSlot.StartTime,
          end_time: selectedSlot.EndTime,
          duration: selectedSlot.Duration,
          appointment_day: selectedSlot.calendarDay,
          appointment_date: selectedSlot.calendarDate,
          cartProductId: cartProductData.cpid,
          userId: userInfo.id,
          onCreate: moment().tz(userTimezone).format("YYYY-DD-YY"),
          action: "command",
          command: [
            {
              agent: "updateSlot",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setOpen(false);
          toast.success("YOUR APPOINTMENT IS BOOKED SUCCESSFULLY!");
          setLoader(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  function getCssClass() {
    if (
      userAppointments.every((elem: any) => elem?.cartProduct?.length === 0)
    ) {
      return "col-lg-5";
    } else {
      return "col-lg-12";
    }
  }
  return (
    <>
      <main className="page-content doctot-detail-user_wrapper">
        <div className="container pb-3 pt-3 pt-lg-0">
          {/* Dashboard */}
          {/* Fitness container */}
          <div className="fittness-wrapper-background-image-one">
            <div className="container px-0">
              <div className="row pb-3">
                <div className="col-lg-12">
                  <CategoryList />
                </div>
              </div>
              {/* Container Content */}
              <div className="row g-2">
                <div className="col pb-3 pb-lg-0">
                  {/* Today Box */}
                  <div className="today-join-time-premimum-version-box p-3 today__card h-100">
                    <div className="row">
                      <div className={getCssClass()}>
                        <p className="mb-0 please-select-the-day pb-2 font-15 text-uppercase d-flex justify-content-between">
                          <TodayAppointment />
                        </p>
                        {userAppointments.some(
                          (elem: any) => elem?.cartProduct?.length > 0
                        ) ? (
                          <div className="mt-5">
                            <span className="pl-2 mt-0 pb-2 d-block">
                              WEELY APPOINTMENTS SCHEDULED
                            </span>
                            <WeeklyAppoinments />
                          </div>
                        ) : null}
                      </div>
                      {userAppointments.every(
                        (elem: any) => elem?.cartProduct?.length === 0
                      ) ? (
                        <div className="col-lg-7 px-0">
                          <div className="fittness-right-box p-4 fittness__right--box this__week__app__spec--dashboard">
                            <div className="row">
                              <div className="col-lg-12 px-0">
                                <p className="mb-0 please-select-the-day font-weight-bold this__week__appointments text-uppercase">
                                  This week appointments
                                </p>
                                <p className="mb-0 email-city-registration-date font-12 pb-3 text-uppercase">
                                  Join the session and make the most out of it
                                </p>
                              </div>
                            </div>
                            <div className="row doctor-dashboard-wrapper mb-3">
                              <WeeklyAppoinments />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {userAppointments.every(
                  (elem: any) => elem?.cartProduct?.length === 0
                ) ? null : (
                  <div className="col">
                    <div className="fittness-right-box p-3 week__appoint__user--dashboard">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="mb-0 please-select-the-day pb-2 text-uppercase">
                            BOOK YOUR SLOT HERE !
                            <span className="d-block ">
                              PLEASE BOOK YOUR SLOTS BEFORE THE DEADLINE
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row doctor-dashboard-wrapper doctor-dashboard-wrapper-new">
                        {userAppointments.length !== 0 &&
                        userAppointments !== null ? (
                          userAppointments.map((elem: any) =>
                            elem?.cartProduct?.map((cp: any) => (
                              <div className="days-left-box">
                                <span className="pb-2 font-14 mb-0 d-block">
                                  {cp.days_left} DAYS LEFT
                                </span>
                                <div className="days-left-inner d-flex">
                                  <div className="specialist-box d-flex">
                                    <div className="specilist-image">
                                      <img
                                        src={cp?.specialist_profile}
                                        alt="#"
                                      />
                                    </div>
                                    <div className="specialist-info">
                                      <p className="text-uppercase mb-2 font-12">
                                        DR {cp.specialist_name}{" "}
                                        <span className="specialist-gender-text d-block font-14">
                                          {cp.specialist_gender}
                                        </span>
                                      </p>
                                      <p className="text-uppercase font-16">
                                        {" "}
                                        {cp.category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="singular-appointment-box">
                                    <p className="text-uppercase mb-2 font-12">
                                      {cp.appnt_type} APPOINTMENT
                                    </p>
                                    <p className="text-uppercase mb-0 font-12">
                                      {cp.appointment_duration === 0.5
                                        ? "30 MIN"
                                        : "1 HR"}{" "}
                                      APPOINTMENT
                                    </p>
                                    <p className="text-uppercase mb-0 text-right font-12 book-slots-outer cursor_pointer">
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => handleOpen(cp)}
                                      >
                                        Book slots
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))
                          )
                        ) : (
                          <span>NO DATA AVAILABLE</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal isOpen={open}>
            <div tabIndex={-1}>
              <div className="modal-dialog model-md modal-dialog-centered user__calander__booking--list modal-xl modal-dialog-new">
                <div className="modal-content buy__product__modal py-3">
                  <div className="modal-header py-2">
                    <p className="reschedule__available__title mb-0 text_uppercase ml-3">
                      AVAILABLE SLOTS: DR. {cartProductData?.specialist_name}
                    </p>

                    <button
                      className="reschedule__book mr-3"
                      onClick={handleClose}
                    >
                      <i className="fa fa-close"></i> CANCEL
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row border-top m-0 ">
                      <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                        <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                          Please select your time slot before we proceed
                        </p>
                        <div>
                          <div className="react-calendar-outer">
                            <Calendar
                              selectRange={true}
                              onChange={handleRangeSelection}
                              value={[startDate, endDate]}
                              onActiveStartDateChange={handleMonthChange} // Handle month change
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                        <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                          Available time slots for the month of {selectedMonth}
                        </p>
                        <div className="book-overflow">
                          <div className="slot-outer text-uppercase text-white">
                            <div className="slot-inner row g-3">
                              {slots.map((slot: any, index: any) => (
                                <div
                                  key={index}
                                  className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3 gap-14"
                                >
                                  <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-1">
                                    {slot.dayOfWeek}
                                  </h3>
                                  {slot?.slots
                                    .sort((a: any, b: any) => {
                                      const timeA = moment(
                                        a.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                                        "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                      ).tz(userTimezone);

                                      const timeB = moment(
                                        b.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                                        "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                      ).tz(userTimezone);

                                      // Prioritize AM before PM
                                      if (
                                        timeA.format("A") !== timeB.format("A")
                                      ) {
                                        return timeA.format("A") === "AM"
                                          ? -1
                                          : 1;
                                      }

                                      // Sort by time within the same period
                                      return timeA.diff(timeB);
                                    })
                                    .map((elem: any) => (
                                      <div
                                        className="slot-box slot-box-new col-md-6 cursor_pointer p-0"
                                        key={elem.uuid}
                                        onClick={() => passSlots(elem)}
                                      >
                                        <div
                                          className="slot-box-inner border pt-2 pb-2 pl-3 pr-3"
                                          style={{
                                            backgroundColor:
                                              selectedSlot === elem
                                                ? "white"
                                                : "",
                                            color:
                                              selectedSlot === elem
                                                ? "black"
                                                : "",
                                          }}
                                        >
                                          <h5 className="mb-0 explore-heading-two pb-0">
                                            AVAILABILITY | {elem?.Duration} HR
                                          </h5>
                                          <p
                                            className="mb-0 explore-heading-two pb-0"
                                            style={{
                                              color:
                                                selectedSlot === elem
                                                  ? "black"
                                                  : "white",
                                            }}
                                          >
                                            {userTimezone && elem?.StartTime
                                              ? moment
                                                  .tz(
                                                    elem.StartTime.replace(
                                                      /:000Z$/,
                                                      ":00Z"
                                                    ),

                                                    userTimezone
                                                  )
                                                  .format("hh:mm A")
                                              : "Date Not Available"}
                                            <span className="to"> TO </span>
                                            {userTimezone && elem?.EndTime
                                              ? moment
                                                  .tz(
                                                    elem.EndTime.replace(
                                                      /:000Z$/,
                                                      ":00Z"
                                                    ),

                                                    userTimezone
                                                  )
                                                  .format("hh:mm A")
                                              : "Date Not Available"}
                                          </p>
                                          <p
                                            className="mb-0 explore-heading-two pb-0"
                                            style={{
                                              color:
                                                selectedSlot === elem
                                                  ? "black"
                                                  : "white",
                                            }}
                                          >
                                            {userTimezone && elem?.StartTime
                                              ? moment
                                                  .tz(
                                                    elem.StartTime.replace(
                                                      /:000Z$/,
                                                      ":00Z"
                                                    ),
                                                    "UTC"
                                                  )
                                                  .tz(userTimezone)
                                                  .format("YYYY-MM-DD")
                                              : "Date Not Available"}
                                            <span className="day-name-right floar-right">
                                              {userTimezone && elem?.StartTime
                                                ? moment
                                                    .tz(
                                                      elem.StartTime.replace(
                                                        /:000Z$/,
                                                        ":00Z"
                                                      ),
                                                      "UTC"
                                                    )
                                                    .tz(userTimezone)
                                                    .format("dddd")
                                                : "Date Not Available"}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </div>

                          <hr className="custom-hr custom-hr-white text-white"></hr>
                          {slots.length === 0 && (
                            <h4 className="text-white text-center letter_spacing text-uppercase">
                              No slots available for selected date{" "}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-right" onClick={bookSlot}>
                        {loader ? (
                          <div className="d-flex">
                            <div
                              className="spinner-grow spinner-grow-sm text-dark"
                              role="status"
                            >
                              <span className="sr-only text-dark">
                                LOADING...
                              </span>
                            </div>
                            <span className="text-dark letter_spacing pl-1">
                              LOADING
                            </span>
                          </div>
                        ) : (
                          <button className="reschedule__book mr-3">
                            BOOK
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/*  */}
        </div>
        {/* */}
      </main>
    </>
  );
};

export default UserBody;
