import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import AuthContext from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../../SpecialistDashboard/Layout/Modal";
import useBroadCatList from "../../../../customHooks/useBroadCatList";

const CreatePackage: React.FC = () => {
  const [packageInput, setPackageInput] = useState<any>({
    packageName: "",
    description: "",
    broadCategory: "",
    price: "",
  });
  const [open, setOpen] = useState<any>(false);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>([]);
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [imgSource, setImgSource] = useState<any>("");
  const [imgFile, setImgFile] = useState<any>("");
  const authCtx = useContext(AuthContext);
  const [loader, setLoader] = useState<any>("");
  const [durationList, setDurationList] = useState<any>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileLoader, setFileLoader] = useState<any>(false);
  const navigate = useNavigate();
  const broadCategoryList = useBroadCatList();

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSource(imageSrc);
        setImgFile(file);
        setFileLoader(true);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSource("");
        setImgFile(null);
        event.target.files = null;
      }
    }
  };

  const handleChange = (event: any) => {
    const value = event.target.value;

    if (value.length > 40) {
      setErrorMessage("Please enter only up to 40 letters.");
    } else {
      setInputValue(value);
      setErrorMessage("");
    }
  };

  const handlePackageDetails = (event: any) => {
    const { name, value } = event.target;
    setPackageInput({
      ...packageInput,
      [name]: name === "packageName" ? value.toUpperCase() : value,
    });
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
    setFileLoader(false);
  };
  console.log(fileResponse);
  const createPackage = async () => {
    if (
      !packageInput.packageName ||
      !inputValue ||
      categoryId.length === 0 ||
      !imgSource ||
      !packageInput.broadCategory
    ) {
      let errorMessage = "";

      if (!packageInput.packageName) {
        errorMessage += "Please enter package name. ";
      }

      if (!packageInput.broadCategory) {
        errorMessage += "Please select broad category ";
      }

      if (categoryId.length === 0) {
        errorMessage += "Please select category. ";
      }
      if (!imgSource) {
        errorMessage += "Please select image. ";
      }
      if (
        !packageInput.blogTitle &&
        !inputValue &&
        !imgSource &&
        categoryId.length === 0 &&
        !packageInput.broadCategory
      ) {
        errorMessage = "All fields are mandatory. ";
      }

      if (packageInput.blogTitle) {
        if (
          !inputValue &&
          categoryId.length === 0 &&
          !imgSource &&
          !packageInput.broadCategory
        ) {
          errorMessage = "All fields are mandatory. ";
        }
      }

      if (inputValue) {
        if (
          !packageInput.blogTitle &&
          categoryId.length === 0 &&
          !imgSource &&
          !packageInput.broadCategory
        ) {
          errorMessage = "All fields are mandatory. ";
        }
      }

      if (categoryId.length !== 0) {
        if (
          !inputValue &&
          !packageInput.blogTitle &&
          !imgSource &&
          !packageInput.broadCategory
        ) {
          errorMessage = "All fields are mandatory. ";
        }
      }
      if (imgSource) {
        if (
          !inputValue &&
          !packageInput.blogTitle &&
          categoryId.length === 0 &&
          !packageInput.broadCategory
        ) {
          errorMessage = "All fields are mandatory. ";
        }
      }

      if (packageInput.broadCategory) {
        if (
          !inputValue &&
          !packageInput.blogTitle &&
          categoryId.length === 0 &&
          !imgSource
        ) {
          errorMessage = "All fields are mandatory. ";
        }
      }

      return toast.warning(errorMessage);
    }
    setLoader(true);

    setLoader(true);

    await fileUpload(imgFile);

    let imageUrl = "";
    if (fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    } else {
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          title: `${packageInput.packageName}`,
          description: `abc`,
          image: `${imageUrl}`,
          summary: `${inputValue}`,
          categoriesId: categoryId,
          publish: 1,
          duration: `0`,
          price: `${packageInput.price}`,
          broad_category_id: Number(packageInput?.broadCategory),
          metadescription: "",
          metakeyword: "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf8",
          action: "command",
          command: [
            {
              agent: "createPackages",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success("Package Created!");
          packageInput.packageName = "";
          packageInput.broadCategory = "";
          packageInput.price = "";
          packageInput.description = "";
          setCategoryId("");
          setImgSource("");
          setImgFile("");
          setFileResponse("");
          setLoader(false);
          navigate("/dashboard/packages");
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoader(false);
      });
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const getCategoryList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        const data = result.data.data;
        setCategoryList(data);
      })
      .catch((err) => {
        toast.error(`Something went wrong, ${err}`);
      });
  };

  const getCatList = useCallback(getCategoryList, [authCtx.token]);

  const handleStyle = (id: any) => {
    if (categoryId.includes(id)) {
      setCategoryId(categoryId.filter((elemId: any) => elemId !== id));
    } else {
      setCategoryId([...categoryId, id]);
    }
  };

  useEffect(() => {
    getCatList();
  }, [getCatList]);

  const getPackageDurationList = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          command:
            '[ { "agent": "getTime", "appName": "selfMaximized", "folder": "specialist" } ]',
        },
        headers: { "x-access-token": `${authCtx.token}` },
      })
      .then((result) => {
        if (result?.data?.data?.status === "resolve") {
          setDurationList(result?.data?.data?.response);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getPackageDurationList();
  }, [authCtx.token, authCtx.role, authCtx.refreshToken]);

  const deleteImage = () => {
    setImgSource("");
    setFileLoader(false);
    setFileResponse([]);
  };

  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
              <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                {loader ? (
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn"
                    disabled
                  >
                    <span className="spinner-border spinner-border-sm mr-2 text-dark create__blog--publish"></span>
                    LOADING...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn"
                    onClick={createPackage}
                  >
                    PUBLISH
                  </button>
                )}
              </div>
              <section
                className="tabs-wrapper magazine_wrapper-tabs"
                id="magazine_wrapper-view_more"
              >
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs edit__blog__tab">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="">
                        <span className="material-icons text-uppercase">
                          Create Package
                        </span>{" "}
                      </label>
                      <Modal isOpen={open}>
                        <div tabIndex={-1}>
                          <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
                            <div className="modal-content buy__product__modal py-3">
                              <div className="modal-header py-2">
                                WELLNESS THERAPIES MODAL
                                <button
                                  className="btn btn-light"
                                  onClick={() => {
                                    setOpen(false);
                                  }}
                                >
                                  CLOSE
                                </button>
                              </div>
                              <div className="modal-body">ABCDES</div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                        <div className="row mb-4 mb-md-0">
                          <div className="col-lg-3 custom-select-v1-outer p-0 mb-3">
                            <div className="form-group mb-0 P-3">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                SELECT BROAD CATEGORY
                              </label>
                              <div id="createProduct">
                                <select
                                  id="select"
                                  className="text-uppercase custom-select-v1"
                                  name="broadCategory"
                                  value={packageInput.broadCategory}
                                  onChange={handlePackageDetails}
                                  onKeyDown={handleKeyDownEvent}
                                >
                                  <option value="" disabled>
                                    SELECT BROAD CATEGORY
                                  </option>
                                  {broadCategoryList.length !== 0 &&
                                  broadCategoryList !== null ? (
                                    broadCategoryList.map((elem: any) => (
                                      <option key={elem?.id} value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0" disabled selected>
                                      CATEGORY NOT AVAILABLE
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-9 pl-0 pr-0 pr-lg-3">
                            <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                                id="blogTitle text-white blog__title--edit"
                                className="text-uppercase pb-0"
                              >
                                Package Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100"
                                id="blogTitle"
                                name="packageName"
                                value={packageInput.packageName}
                                onChange={handlePackageDetails}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                            </div>
                            <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                One line summary
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase"
                                id="oneLineSummary"
                                name="oneLineSummary"
                                value={inputValue}
                                onChange={handleChange}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                              <span className="text-danger mt-4 error--message text-uppercase">
                                {errorMessage}
                              </span>
                            </div>
                            <div className="select-category">
                              <div className="col-lg-12 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  id="blogTitle text-white"
                                  className="text-uppercase pb-0"
                                >
                                  Select category
                                </label>
                                <div
                                  className="select-category_inner p-2"
                                  id="create__blog__select--category"
                                >
                                  {categoryList.length !== 0 &&
                                  categoryList !== null ? (
                                    categoryList.map((elem: any) => (
                                      <p
                                        onClick={() => handleStyle(elem.id)}
                                        className={`font-12 category-inner_box p-2 mb-3 mb-lg-0 ${
                                          categoryId.includes(elem.id)
                                            ? ""
                                            : "text-white"
                                        }`}
                                        style={{
                                          backgroundColor: `${
                                            categoryId.includes(elem.id)
                                              ? "#fff"
                                              : "#4a4a4a"
                                          }`,
                                          display: "inline-block",
                                        }}
                                        key={elem.id}
                                        id="createCategory"
                                      >
                                        {elem.name}
                                      </p>
                                    ))
                                  ) : (
                                    <div className="col-lg-12 text-center">
                                      <span className="text-white font-14 text-uppercase">
                                        No Data Available
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="form-group mb-4 mb-md-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows={4}
                                name="description"
                                value={packageInput.description}
                                onChange={handlePackageDetails}
                                onKeyDown={handleKeyDownEvent}
                              ></textarea>
                            </div> */}
                          </div>
                          <div
                            className="col-lg-3 add-blog-wrapper_magazine p-0"
                            onClick={handleTriggerClick}
                          >
                            <div
                              className="cancel__image cursor_pointer"
                              onClick={deleteImage}
                            >
                              <svg
                                className="d-flex justify-content-end text-white"
                                id="icon-cross"
                                viewBox="0 0 14 14"
                                height={40}
                              >
                                <path d="M4.91 3.992C5.14252 4.21583 7.41432 6.56773 7.41432 6.56773C7.53837 6.68788 7.60068 6.84423 7.60068 7.00058C7.60068 7.15694 7.53837 7.31329 7.41432 7.43234C7.41432 7.43234 5.14252 9.78533 4.91 10.0081C4.67747 10.2319 4.25916 10.2473 4.01162 10.0081C3.76352 9.76997 3.74405 9.43696 4.01162 9.14455L6.09596 7.00058L4.01162 4.85661C3.74405 4.5642 3.76352 4.23065 4.01162 3.992C4.25916 3.75281 4.67747 3.76762 4.91 3.992Z" />
                                <path d="M9.08999 3.992C8.85747 4.21583 6.58566 6.56773 6.58566 6.56773C6.46162 6.68788 6.39931 6.84423 6.39931 7.00058C6.39931 7.15694 6.46162 7.31329 6.58566 7.43234C6.58566 7.43234 8.85747 9.78533 9.08999 10.0081C9.32251 10.2319 9.74083 10.2473 9.98837 10.0081C10.2365 9.76997 10.2559 9.43696 9.98837 9.14455L7.90402 7.00058L9.98837 4.85661C10.2559 4.5642 10.2365 4.23065 9.98837 3.992C9.74083 3.75281 9.32251 3.76762 9.08999 3.992Z" />
                              </svg>
                            </div>
                            <div className="add-new-blog--create d-flex justify-content-center align-items-center position-relative">
                              {fileLoader && !fileResponse?.data?.url ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : !fileLoader && fileResponse?.data?.url ? (
                                <img
                                  src={imgSource}
                                  alt=""
                                  className=" w-100"
                                />
                              ) : (
                                <Link to={""} className="font-13 text-center">
                                  <svg
                                    className="icon-icon-plus mb-2"
                                    viewBox="0 0 14 14"
                                    height={40}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                    />
                                  </svg>

                                  <p className="add-the-eatured-photos text-uppercase">
                                    Add the featured photos
                                  </p>
                                </Link>
                              )}
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreatePackage;
