import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import JoditEditor from "jodit-react";
import axios from "axios";
import { toast } from "react-toastify";
import AuthContext from "../../../../authContext";
import moment from "moment";

const Policies = () => {
  const [menuType, setMenuType] = useState<string>("");
  const [policiesData, setPoliciesData] = useState<any[]>([]);
  const [newPolicyData, setNewData] = useState<string>("");
  const [pageDataUser, setPageDataUser] = useState<string>("");
  const [pageTypeUuid, setPageTypeUuid] = useState<string>("");
  const [pageDataSpecialist, setPageDataSpecialist] = useState<string>("");
  const [firstPageDataUser, setFirstPageDataUser] = useState<string>("");
  const [firstPageDataSpecialist, setFirstPageDataSpecialist] =
    useState<string>("");
  const [firstPageName, setFirstPageName] = useState<string>("");
  const [firstPageUuid, setFirstPageUuid] = useState<string>("");
  const [roleType, setRoleType] = useState<string>("specialist");
  const [loader, setLoader] = useState<boolean>(false);
  const authCtx = useContext(AuthContext);

  const config = React.useMemo(
    () => ({
      readonly: false,
      placeholder: "",
    }),
    []
  );

  const editor = useRef(null);

  const handleData = (content: string) => {
    setNewData(content);
  };

  const handleSelectedMenu = (
    type: string,
    pageDataUser: string,
    pageDataSpecialist: string,
    pageTypeUuid: string
  ) => {
    setMenuType(type);
    setPageDataUser(pageDataUser);
    setPageDataSpecialist(pageDataSpecialist);
    setPageTypeUuid(pageTypeUuid);
  };

  const getPolicyContent = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getTermsAndConditions",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authContext.token,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setPoliciesData(response?.data?.data);
        setFirstPageName(response?.data?.data[0].pageName);
        setFirstPageDataUser(response?.data?.data[0].pageDataUser);
        setFirstPageDataSpecialist(response?.data?.data[0].pageDataSpecialist);
        setFirstPageUuid(response?.data?.data[0].uuid);
      } else {
        toast.error("Something went wrong: " + response.data.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const addPolicyContent = async () => {
    let payload: any = {};

    if (roleType === "consumer" && newPolicyData) {
      payload = {
        pageName: menuType ? menuType : firstPageName,
        uuid: pageTypeUuid ? pageTypeUuid : firstPageUuid,
        pageDataUser: newPolicyData,
        handel: "",
        meta: "[]",
        menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
        action: "command",
        command: [
          {
            agent: "termsAndConditions",
            appName: "selfMaximized",
            folder: "policies",
          },
        ],
      };
    } else if (roleType === "specialist" && newPolicyData) {
      payload = {
        pageName: menuType ? menuType : firstPageName,
        uuid: pageTypeUuid ? pageTypeUuid : firstPageUuid,
        pageDataSpecialist: newPolicyData,
        handel: "",
        meta: "[]",
        menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
        action: "command",
        command: [
          {
            agent: "termsAndConditions",
            appName: "selfMaximized",
            folder: "policies",
          },
        ],
      };
    }

    if (payload) {
      try {
        setLoader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          payload
        );
        if (response?.data.statusCode === 200) {
          toast.success(response.data.message);
          setNewData("");
          setLoader(false);
        } else {
          toast.error("Something went wrong: " + response.data.message);
          setLoader(false);
        }
      } catch (err) {
        toast.error("Something went wrong");
        setLoader(false);
      }
    }
  };

  const handleRoleType = (roleType: string) => {
    setRoleType(roleType);
  };

  useEffect(() => {
    getPolicyContent();
  }, [authCtx.token, menuType, roleType]);

  const getValue = useCallback(() => {
    if (!menuType && firstPageDataUser && roleType === "consumer") {
      return firstPageDataUser;
    } else if (
      !menuType &&
      firstPageDataSpecialist &&
      roleType === "specialist"
    ) {
      return firstPageDataSpecialist;
    } else if (menuType && roleType === "consumer") {
      return pageDataUser;
    } else if (menuType && roleType === "specialist") {
      return pageDataSpecialist;
    }
    return newPolicyData;
  }, [
    menuType,
    roleType,
    pageDataUser,
    pageDataSpecialist,
    firstPageDataUser,
    firstPageDataSpecialist,
    newPolicyData,
  ]);

  const authContext = useContext(AuthContext);

  const [pagePuckData, setPagePuckData] = useState<any>([
    {
      id: 12,
      name: "TERMS AND CONDITIONS",
      data: [
        {
          root: {
            props: {},
          },
          content: [
            {
              type: "TermsAndConditions",
              props: {
                title: "TERMS AND CONDITIONS",
                policyForCustomer: "FOR CUSTOMER",
                policyForSpecialist: "FOR SPECIALIST",
                id: "TermsAndConditions-770f446d-a304-4010-bf0d-e889f618e061",
              },
            },
          ],
          zones: {},
        },
      ],
    },
    {
      id: 13,
      name: "CANCELLATION POLICY",
      data: [
        {
          root: {
            props: {},
          },
          content: [
            {
              type: "CancellationPolicy",
              props: {
                title: "CANCELLATION POLICY",
                policyForCustomer: "FOR CUSTOMER",
                policyForSpecialist: "FOR SPECIALIST",
                id: "CancellationPolicy-bcf50475-f980-4594-894b-e2f86823f5bf",
              },
            },
          ],
          zones: {},
        },
      ],
    },
    {
      id: 14,
      name: "PRIVACY POLICY",
      data: [
        {
          root: {
            props: {},
          },
          content: [
            {
              type: "PrivacyPolicy",
              props: {
                title: "PRIVACY POLICY",
                policyForCustomer: "FOR CUSTOMER",
                policyForSpecialist: "FOR SPECIALIST",
                id: "PrivacyPolicy-2a8c1801-5cb8-4c6f-bb4f-83e8d8c1088f",
              },
            },
          ],
          zones: {},
        },
      ],
    },
  ]);
  const passPageId = async (pageName: any) => {
    const passData = pagePuckData.find((page: any) => page.name === pageName)
      ?.data[0];
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: pageName,
          data: passData,
          action: "command",
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          command: [
            {
              agent: "createPage",
              appName: "selfMaximized",
              folder: "pages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success("Page created successfully");
          window.open(`/pedit?pageName=${pageName}`, "_blank");
        } else {
          window.open(`/pedit?pageName=${pageName}`, "_blank");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong," + err);
      });
  };
  return (
    <main className="page-content user_wrapper overflow-auto">
      <div className="container mt-4 mt-lg-0">
        <div className="row">
          <div className="site-wrapper w-100 position-relative magazine_wrapper-inner-wrapper px-0 pt-5 pt-lg-0">
            <div className="d-none d-lg-block"></div>
            <div className="row d-block d-lg-none">
              <div className="col-lg-12">
                <div className="header__search-mobile magazine_wrapper-search-mobile d-flex justify-content-center align-items-center">
                  <div className="dropdown"></div>
                </div>
              </div>
            </div>
            <section className="tabs-wrapper">
              <div className="tabs-container">
                <div className="tabs-block">
                  <div className="tabs">
                    <input type="radio" name="tabs" id="tab1" checked />
                    <label htmlFor="tab1" className="pl-0 pb-2">
                      <span className="material-icons active text-uppercase">
                        Policies
                      </span>
                    </label>
                    <div className="tab magazine_wrapper px-4 my-4">
                      <div className="row">
                        <div className="col-3">
                          <div
                            className="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {policiesData.map((elem: any, index: any) => (
                              <div
                                key={elem.uuid}
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <button
                                  className="nav-link mt-2"
                                  onClick={() =>
                                    handleSelectedMenu(
                                      elem.pageName,
                                      elem.pageDataUser,
                                      elem.pageDataSpecialist,
                                      elem.uuid
                                    )
                                  }
                                  style={{
                                    backgroundColor: `${
                                      (index === 0 && !menuType) ||
                                      menuType === elem.pageName
                                        ? "white"
                                        : ""
                                    }`,
                                    color: `${
                                      (index === 0 && !menuType) ||
                                      menuType === elem.pageName
                                        ? "black"
                                        : "white"
                                    }`,
                                    border: `${
                                      menuType !== elem.pageName
                                        ? "0.5px solid white"
                                        : "none"
                                    }`,
                                    outline: "2px solid black", // Adds an outline to the button
                                    width: "100%",
                                    paddingRight: "40px", // Add padding to make space for the icon
                                  }}
                                  type="button"
                                >
                                  {elem.pageName.toUpperCase()}
                                </button>

                                {/* Edit Icon */}
                                <span
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    passPageId(elem.pageName.toUpperCase());
                                  }}
                                  // Function to handle edit click
                                >
                                  <img
                                    src="https://img.icons8.com/ios-glyphs/30/edit.png" // Replace with your icon URL
                                    alt="Edit"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-9">
                          <nav className="mb-4 mt-2">
                            <div
                              className="nav nav-tabs"
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className={`nav-link active`}
                                id="nav-home-tab"
                                data-toggle="tab"
                                data-target="#nav-home"
                                type="button"
                                role="tab"
                                style={{
                                  color: `${
                                    roleType === "specialist"
                                      ? "black"
                                      : "white"
                                  }`,
                                }}
                                aria-controls="nav-home"
                                aria-selected="true"
                                onClick={() => handleRoleType("specialist")}
                              >
                                SPECIALIST
                              </button>
                              <button
                                className="nav-link"
                                id="nav-profile-tab"
                                data-toggle="tab"
                                style={{
                                  color: `${
                                    roleType === "consumer" ? "black" : "white"
                                  }`,
                                }}
                                data-target="#nav-profile"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                                onClick={() => handleRoleType("consumer")}
                              >
                                CONSUMER
                              </button>
                            </div>
                          </nav>
                          <div className="tab-content" id="v-pills-tabContent">
                            <div>
                              {policiesData.length && (
                                <JoditEditor
                                  ref={editor}
                                  className="editor-container dark h-100"
                                  value={getValue()}
                                  config={config}
                                  onChange={(newContent: string) =>
                                    handleData(newContent)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-start mt-4">
                            <button
                              className="btn btn-light"
                              onClick={addPolicyContent}
                            >
                              {loader ? "LOADING..." : "SET POLICY"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Policies;
