import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SpecialistHeader from "./SpecialistHeader";
import SpecialistSidebar from "./SpecialistSidebar";
import { useNavigate, Outlet } from "react-router-dom";
import AuthContext from "../../authContext";
import Footer from "../../pages/AdminDashboard/Footer";
import axios from "axios";
import { toast } from "react-toastify";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;

  useEffect(() => {
    getUserPolicy();
  }, [navigate, userInfo.uuid]);

  const goToDashboardPage = useCallback(() => {
    if (authCtx.token || authCtx.isLoggedIn) {
      return false;
    } else if (!authCtx.token || !authCtx.isLoggedIn) {
      navigate("/", { replace: true }); // or return <LoadingSpinner /> if you have a loading component
    }
  }, [authCtx.token, authCtx.isLoggedIn, navigate]);

  const getUserPolicy = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getTermsAndConditions",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authCtx.token,
          },
        }
      )
      .then(async (result) => {
        const result2 = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            user_id: userInfo.uuid,
            menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
            action: "command",
            command: [
              {
                agent: "getUserPolicies",
                appName: "selfMaximized",
                folder: "policies",
              },
            ],
          },
          {
            headers: {
              "x-access-token": authCtx.token,
            },
          }
        );

        if (result?.data?.statusCode === 200) {
          if (
            result?.data?.data?.length > 0 &&
            result?.data?.data?.some(
              (elem: any) =>
                elem.pageDataSpecialist !== "" || elem.pageDataUser !== ""
            ) &&
            result2?.data?.data?.length !== 0
          ) {
            return;
          } else if (result?.data?.data?.length === 0) {
            navigate("/dashboard");
          } else if (
            result?.data?.data?.length > 0 &&
            result?.data?.data?.some(
              (elem: any) =>
                elem.pageDataSpecialist !== "" && elem.pageDataUser !== ""
            ) &&
            result2?.data?.data?.length === 0
          ) {
            navigate("/termsandconditions");
          }
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    goToDashboardPage();
  }, [goToDashboardPage]);

  const logOut = () => {
    localStorage.clear();
    localStorage.clear();
  };
  let timeoutId = useRef<number | undefined>(undefined);

  const handleActivityOrVisibilityChange = useCallback(() => {
    if (timeoutId.current !== undefined) {
      clearTimeout(timeoutId.current);
    }

    if (!document.hidden) {
      timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    document.addEventListener("mousemove", handleActivityOrVisibilityChange);
    document.addEventListener("keydown", handleActivityOrVisibilityChange);
    document.addEventListener(
      "visibilitychange",
      handleActivityOrVisibilityChange
    );

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId.current);
      }
      document.removeEventListener(
        "mousemove",
        handleActivityOrVisibilityChange
      );
      document.removeEventListener("keydown", handleActivityOrVisibilityChange);
      document.removeEventListener(
        "visibilitychange",
        handleActivityOrVisibilityChange
      );
    };
  }, [handleActivityOrVisibilityChange]);

  const [sidebarClass, setSideBarClass] = useState<boolean>(false);
  const [dashboardClass, setDashboardClass] = useState<boolean>(false);

  const updateDashboardClass = (isActive: boolean) => {
    setDashboardClass(isActive);
  };

  return (
    <>
      <div
        id="app"
        className="app"
        // style={{ fontFamily: "cutomFontNormal" }}
      >
        <div
          className={`page-home load ${
            dashboardClass ? "sidebar-show sidebar-collapse sidebar-active" : ""
          } `}
        >
          <div className="page-wrapper">
            <SpecialistHeader
              isActive={sidebarClass}
              setActive={setSideBarClass}
              updateDashboardClass={updateDashboardClass}
            />
            <SpecialistSidebar isActive={sidebarClass} />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
