import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../authContext';
import bigCard from "../../../new-big.png";
import { Link } from 'react-router-dom';
import axios from "axios";

const CategoryList: React.FC = () => {
    const [categoryList, setCategoryList] = useState<any>([]);
    const [loader, setLoader] = useState<any>(true);
    const authCtx = useContext(AuthContext);

    const getCategories = async () => {
        setLoader(true);
        const response = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            filter: {
                filter: {
                    logic: 'and',
                    offset: 0,
                    limit: 3,
                    filters: [
                        {
                            field: "isActive",
                            operator: "eq",
                            value: 1
                        }
                    ],

                    sort: [
                        {
                            field: 'id',
                            dir: 'desc'
                        }
                    ]
                }
            },
            menuId: '9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2',
            action: 'command',
            command: [
                {
                    agent: 'categoryLists',
                    appName: 'selfMaximized',
                    folder: 'category'
                }
            ]
        }, {
            headers: { 'x-access-token': `${authCtx.token}` }
        })

        const newData = response.data.data;
        setCategoryList(newData);
        console.log("newData", newData);
        setLoader(false);
    }
    useEffect(() => {
        getCategories();
    }, [])

    const authContext = useContext(AuthContext);
    console.log(authContext);
    return (
        <div className="explore__our__top__interacting p-3">
            <div className="row pb-3">
                <div className="col-lg-6 pb-3 pb-md-0">
                    <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium">
                        EXPLORE OUR TOP INTERACTING CATEGORY
                    </h1>
                </div>
                <div className="col-lg-6 text-right">
                    <Link
                        to={'/wellness-category'}
                        className="font-weight-bold explore__our__top__interacting--viewall text-uppercase"
                    >
                        View all
                    </Link>
                </div>
            </div>
            <div className="row">

                {loader ? (
                    <div className='text-uppercase'>Loading...</div>
                ) : (
                    categoryList.map((item: any, index: any) => (
                        <div className="col-lg-4 col-md-6 col-6 mb-3 mb-lg-0 w-100" key={index}>
                            <div className="explore__our__top__interacting__image__block position-relative">
                                <img
                                    src={item?.image}
                                    alt="image"
                                    className="img-fluid explore__our__top__interacting__image w-100"
                                />
                            </div>
                            <div className="explore__our__top__interacting__content w-100">
                                <h2 className="mb-0 explore__our__top__interacting__title interacting--title text-center text-uppercase">
                                    {item?.name}
                                </h2>
                            </div>
                        </div>
                    ))
                )}


            </div>
        </div>
    )
};


export default CategoryList;
