import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import Shimmer from "../../Shimmer";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const RecentBlogs: React.FC = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [blogData, setBlogData] = useState<any>([]);
  const [loader, setLoader] = useState<any>([]);

  const getBlogs = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: "1",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "blogList",
              appName: "selfMaximized",
              folder: "blogs",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        setBlogData(result?.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const blogList = useCallback(getBlogs, [authCtx.token]);

  useEffect(() => {
    blogList();
  }, [blogList]);

  const deleteBlog = async (blogUuid: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              blogUuid: blogUuid,
              menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf5",
              action: "command",
              command: [
                {
                  agent: "deleteBlog",
                  appName: "selfMaximized",
                  folder: "blogs",
                },
              ],
            },
            {
              headers: { "x-access-token": `${authCtx.token}` },
            }
          )
          .then((result) => {
            if (result?.data?.statusCode === 200) {
              Swal.fire({
                title: "Deleted!",
                text: "Your blog has been deleted.",
                icon: "success",
              });
              blogList();
            } else {
              return;
            }
          })
          .catch((err) => {
            return toast.error("Something went wrong", err);
          });
      }
    });
  };
  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container mt-4 mt-lg-0 mb-3">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-inner-wrapper px-0 pt-5 pt-lg-0">
              <section className="tabs-wrapper">
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="pl-0 pb-2">
                        <span className="material-icons active text-uppercase">
                          Recent blogs
                        </span>
                      </label>
                      <div className="tab magazine_wrapper px-4 my-4">
                        <div className="row">
                          <div className="col-lg-9 pr-0 pr-lg-3">
                            <div className="row">
                              {blogData.length !== 0 && blogData !== null ? (
                                blogData.map((elem: any) => (
                                  <div
                                    className="col-lg-6  pb-2 pb-lg-0 mb-3"
                                    key={elem.id}
                                  >
                                    <div className="row magazine_wrapper-inner align-items-end py-2 mr-1">
                                      <div className="col-10">
                                        <div className="media-item">
                                          <div className="media-item__icon recent--blog--image">
                                            <img
                                              className="media-item__thumb"
                                              src={elem.image}
                                              alt=""
                                              style={{
                                                width: "auto",
                                                height: "60px",
                                              }}
                                              loading="lazy"
                                            />
                                          </div>
                                          <div className="media-item__right">
                                            <h5 className="font-12 mb-0 people_joined">
                                              {elem.title.toUpperCase()}
                                            </h5>
                                            <p className="mb-0 font-12 congratulation_text text-truncate py-1 text-uppercase">
                                              {elem.summary}
                                            </p>
                                            <p className="font-10 timing mb-0">
                                              {elem.category
                                                .slice(0, 3)
                                                .map(
                                                  (
                                                    elem: any,
                                                    index: number
                                                  ) => (
                                                    <span key={index}>
                                                      {index > 0 && ", "}
                                                      {elem.name}
                                                    </span>
                                                  )
                                                )}
                                              {elem.category.length > 3 && (
                                                <button
                                                  type="button"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={elem.category
                                                    .slice(3)
                                                    .map(
                                                      (elem: any) => elem.name
                                                    )
                                                    .join(",\n")}
                                                >
                                                  <span className="text-white ml-1">
                                                    {" "}
                                                    +{elem.category.length - 3}
                                                  </span>
                                                </button>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-2 d-flex align-items-center">
                                        <Link
                                          to={`/dashboard/magazine/editblog/${elem.uuid}`}
                                          className="edit_icon"
                                        >
                                          <svg
                                            className="icon-icon-drafts border--bottom"
                                            viewBox="0 0 22 22"
                                          >
                                            <path d="M17.8736 4.12588C16.5627 2.81406 15.5782 3.01042 15.5782 3.01042L10.0763 8.51223L3.91818 14.6695L3 18.9986L7.32999 18.0804L13.4882 11.9249L18.99 6.42314C18.989 6.42314 19.1863 5.4386 17.8736 4.12588ZM7.07272 17.5604L5.59636 17.8786C5.45454 17.6122 5.28272 17.3458 4.96909 17.0313C4.65454 16.7168 4.38818 16.5468 4.12182 16.4031L4.44 14.9277L4.86727 14.5013C4.86727 14.5013 5.66999 14.5177 6.57726 15.4249C7.48363 16.3304 7.5009 17.1349 7.5009 17.1349L7.07272 17.5604Z" />
                                          </svg>
                                        </Link>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="16"
                                          viewBox="0 0 24 24"
                                          onClick={() => deleteBlog(elem.uuid)}
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          className="feather feather-trash text-white ml-2 cursor_pointer"
                                        >
                                          <polyline points="3 6 5 6 21 6"></polyline>
                                          <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                                          <path d="M10 11v6"></path>
                                          <path d="M14 11v6"></path>
                                          <line
                                            x1="9"
                                            y1="6"
                                            x2="15"
                                            y2="6"
                                          ></line>
                                          <line
                                            x1="12"
                                            y1="2"
                                            x2="12"
                                            y2="6"
                                          ></line>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="col-lg-12 text-center">
                                  <span className="no__slots--available font-14 text-white">
                                    No Data Available
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3 mt-3 mt-lg-0 px-0">
                            <Link to={"/dashboard/magazine/addblog"}>
                              <div className="add-blog-wrapper_magazine h-100 w-100 d-flex align-items-center justify-content-center">
                                <div className="add-new-blog text-center p-5 p-lg-0 d-flex align-items-center">
                                  <div className="font-13 text-center">
                                    <svg
                                      className="icon-icon-plus mb-2"
                                      viewBox="0 0 14 14"
                                      height={40}
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                      />
                                    </svg>
                                    <p className="add-the-eatured-photos mb-0 text-uppercase">
                                      Add a new Blog
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RecentBlogs;
