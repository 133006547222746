import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../authContext";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import useUserRefundList from "../../../customHooks/useUserRefundList";

interface SidebarProps {
  isActive: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isActive }) => {
  const authCtx = useContext(AuthContext);
  const [activeMenu, setActive] = useState<any>("");
  const [routeMenu, setMenuFromRoute] = useState<any>("");
  const [menuData, setMenuData] = useState<MenuItem[]>([]);
  const refundList = useUserRefundList();
  const location = useLocation();
  const pathname = location.pathname;

  interface SubMenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
  }

  interface MenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
    subMenu: SubMenuItem[];
  }

  useEffect(() => {
    if (authCtx.userMenu) {
      if (typeof authCtx.userMenu === "string") {
        setMenuData(JSON.parse(authCtx.userMenu));
      } else {
        setMenuData(authCtx.userMenu);
      }
    }
  }, [authCtx.userMenu]); // Add authCtx.userMenu as a dependency

  useEffect(() => {
    let fieldName = pathname.split("/");
    if (fieldName.length <= 2) {
      setMenuFromRoute(fieldName[1]);
    } else {
      setMenuFromRoute(fieldName[2]);
    }
  }, [pathname, activeMenu]);

  const activeRoute = (type: any) => {
    setActive(type);
    setMenuFromRoute(type);
  };

  const iconMap: any = {
    Dashboard: null,
    Categories: null,
    Specialists: null,
    Users: null,
    Magazine: null,
    Analytics: null,
    Settings: null,
  };

  const goToWebsite = () => {
    window.location.href = "/";
    return;
  };

  const filteredMenus = menuData.filter((elem: any) => {
    return (
      elem.menu !== "faq" && elem.menu !== "career" && elem.menu !== "Policies"
    );
  });

  return (
    <aside
      className={`sidebar ${
        isActive ? "sidebar--show sidebar--translate" : ""
      } overflow-auto`}
    >
      <div className="sidebar__backdrop"></div>
      <div className="sidebar__container">
        <div className="sidebar__top pb-5 my-5">
          <div className="container container--sm">
            <span
              className="sidebar__logo d-flex justify-content-center"
              onClick={goToWebsite}
            >
              <img
                className="sidebar__logo-icon cursor_pointer"
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                alt="#"
                style={{ width: "auto", height: "auto" }}
                loading="lazy"
              />
            </span>
          </div>
        </div>
        <div className="sidebar__content" data-simplebar="data-simplebar">
          <nav className="sidebar__nav">
            <ul className="sidebar__menu">
              {filteredMenus.map((elem: MenuItem) => (
                <li
                  className="sidebar__menu-item"
                  key={elem?.menu}
                  onClick={() => activeRoute(elem?.menu)}
                >
                  <Link
                    to={
                      elem?.menu === "Dashboard"
                        ? ""
                        : elem?.menu === "Refunds"
                        ? "/dashboard/refunds-list"
                        : elem?.menu.toLowerCase()
                    }
                    className={`sidebar__link active ${
                      activeMenu === elem?.menu ||
                      routeMenu === elem?.menu.toLocaleLowerCase()
                        ? ""
                        : "fixed_active"
                    }`}
                  >
                    <span className="sidebar__link-icon">
                      {iconMap[elem?.menu] && (
                        <img
                          src={
                            activeMenu === elem?.menu ||
                            routeMenu === elem?.menu.toLocaleLowerCase()
                              ? iconMap[elem?.menu].active
                              : iconMap[elem?.menu].inactive
                          }
                          alt=""
                          className="img-fluid"
                          style={{ width: "18px", height: "18px" }}
                        />
                      )}
                    </span>
                    {elem?.menu === "refunds" ? (
                      <div className="position-relative d-inline-block">
                        <span className="sidebar__link-text text-uppercase">
                          {elem?.menu}
                        </span>
                        <span className="badge-circle">
                          {
                            refundList.filter(
                              (elem: any) => elem?.chargeId !== null
                            ).length
                          }
                        </span>
                      </div>
                    ) : (
                      <span className="sidebar__link-text text-uppercase">
                        {elem?.menu}
                      </span>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
