import React, { useContext, useEffect, useRef, useState } from "react";
// import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import { toast } from "react-toastify";
import AuthContext from "../../../../authContext";
import Modal from "../../../../SpecialistDashboard/Layout/Modal";
import { Editor } from "react-draft-wysiwyg";
// import '/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import JoditEditor from "jodit-react";

import "/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Notifications: React.FC = () => {
  const [notificationType, setNotificationTypes] = useState<any>([]);
  const [type, setType] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [loader2, setLoader2] = useState<any>(false);
  const [loader3, setLoader3] = useState<any>(false);
  const [emailTemplate, setEmailTemplate] = useState<any>("");
  const [requiredFields, setRequiredFields] = useState<any>([]);
  const [firstTypeActive, setFirstTypeActive] = useState<any>("");
  const [firstRequiredType, setFirstRequiredType] = useState<any>("");
  const [editorText, setEditorText] = useState<any>("");
  const [emailObj, setEmailObj] = useState<any>("");
  const [open, setIsOpen] = useState<any>(false);
  const [handleEditor, setHandleEditor] = useState<any>(false);
  const [openTestEmailModal, setOpenModal] = useState<any>(false);
  const [email, setEmail] = useState<any>("");
  const authContext = useContext(AuthContext);

  const handleNotificationType = (type: any, emailObj: any) => {
    setType(type);
    setEmailObj(emailObj);
    setFirstTypeActive("");
    notificationType
      .filter((elem: any) => elem.templateName === type)
      .map((elem: any) => setEmailTemplate(elem.templateBody));
    notificationType
      .filter((elem: any) => elem.templateName === type)
      .map((elem: any) => setRequiredFields(elem.requiredField));
  };

  const getEmailTemplateList = async (text: any, emObj: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          action: "command",
          command: [
            {
              agent: "emailTemplateList",
              appName: "selfMaximized",
              folder: "build",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result.data.statusCode === 200) {
          setNotificationTypes(result?.data?.data);
          setFirstTypeActive(result?.data?.data[0]);
          setFirstRequiredType(result?.data?.data[0].requiredField[0]);
          if (emObj) {
            setEmailTemplate(emObj.templateBody);
          }
        } else {
          setNotificationTypes([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getEmailTemplateList("", "");
  }, [authContext.token]);

  const handleData = (content: any) => {
    setHandleEditor(true);
    setEditorText(content);
  };

  const handleClick = () => {
    setOpenModal(false);
    setIsOpen(true);
  };

  const resetEmail = async (editorTxtObj: any, emObj: any) => {
    setLoader2(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: firstTypeActive ? firstTypeActive.uuid : emailObj.uuid,
          action: "command",
          command: [
            {
              agent: "resetEmailTemplate",
              appName: "selfMaximized",
              folder: "build",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data.statusCode === 200) {
          setLoader2(false);
          getEmailTemplateList(editorTxtObj, emObj);
          return toast.success(result?.data?.message);
        } else {
          setLoader2(false);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader2(false);
        return toast.error("Something went wrong", err);
      });
  };

  const updateEmail = async () => {
    setLoader(true);
    if (
      editorText === emailTemplate ||
      firstTypeActive.templateBody === editorText
    ) {
      setLoader(false);
      return toast.info("No changes have been made to the email template!");
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: firstTypeActive ? firstTypeActive.uuid : emailObj.uuid,
          subject: firstTypeActive ? firstTypeActive.subject : emailObj.subject,
          templateBody: editorText,
          action: "command",
          command: [
            {
              agent: "updateEmailTemplate",
              appName: "selfMaximized",
              folder: "build",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setLoader(false);
          getEmailTemplateList("", "");
          return toast.success(result?.data?.message);
        } else {
          setLoader(false);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  const toSpacedCapitalizedWords = (text: any) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const openTestModal = () => {
    setOpenModal(true);
    setIsOpen(true);
  };

  const sendTestEmail = async () => {
    if (!email) {
      return toast.error("Please enter email first");
    }

    setLoader3(true);

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: firstTypeActive ? firstTypeActive.uuid : emailObj.uuid,
          subject: firstTypeActive ? firstTypeActive.subject : emailObj.subject,
          email: email,
          templateBody:
            firstTypeActive && !editorText
              ? firstTypeActive.templateBody
              : editorText && firstTypeActive
              ? editorText
              : editorText
              ? editorText
              : emailObj.templateBody,
          requiredField: [
            {
              key: "link",
              value: "link",
            },
          ],
          action: "command",
          command: [
            {
              agent: "sendTestMail",
              appName: "selfMaximized",
              folder: "build",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setLoader3(false);
          setEmail("");
          return toast.success(result?.data?.message);
        } else {
          setLoader3(false);
          return toast.warning(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader3(false);
        return toast.error("Something went wrong", err);
      });
  };
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const editor = useRef(null);

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper overflow-auto">
        <div className="container pb-3 pt-3 pt-lg-0">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                EMAILS
              </a>

              <a
                className="nav-item nav-link"
                id="nav-contact-tab"
                data-toggle="tab"
                href="#nav-contact"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                NOTIFICATIONS
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="row">
                <div className="col-lg-12 text-right px-0 pb-4 mt-4">
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn-1 mr-3"
                    onClick={() => resetEmail(editorText, emailObj)}
                    disabled={loader2}
                  >
                    {!loader2 && <span>RESET</span>}
                    {loader2 && (
                      <>
                        <span className="spinner spinner-border spinner-border-sm mr-2"></span>
                        Loading...
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn-1 mr-3"
                    onClick={updateEmail}
                    disabled={loader}
                  >
                    {!loader && <span>UPDATE</span>}
                    {loader && (
                      <>
                        <span className="spinner spinner-border spinner-border-sm mr-2"></span>
                        Loading...
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn-1"
                    onClick={openTestModal}
                  >
                    TEST EMAIL
                  </button>
                </div>
              </div>
              <div
                className="row tab magazine_wrapper p-3"
                id="notificationsTab"
              >
                <div className="col-lg-2 col-sm-6 col-md-2 px-0 col-4">
                  <div
                    className="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {notificationType.map((elem: any) => (
                      <button
                        key={elem.uuid}
                        className={`nav-link ${
                          type
                            ? type === elem.templateName
                            : firstTypeActive.templateName === elem.templateName
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-home-tab"
                        data-toggle="pill"
                        data-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                        onClick={() =>
                          handleNotificationType(elem.templateName, elem)
                        }
                      >
                        {toSpacedCapitalizedWords(elem.templateName)}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="col-lg-8 col-sm-6 col-md-8 px-1 px-md-3 col-8">
                  <div className="tab-content p-2" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active h-100"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <JoditEditor
                        ref={editor}
                        value={
                          emailTemplate
                            ? emailTemplate
                            : firstTypeActive.templateBody
                        }
                        config={config}
                        onChange={(newContent) => handleData(newContent)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 preview-wrapper mt-2 mt-md-0">
                  <div className="">
                    <h6>Mandatory Fields</h6>
                    {requiredFields.map((elem: any, index: any) => (
                      <p className="mb-0" key={index}>
                        {"${"}
                        {elem.key}
                        {"}"}
                      </p>
                    ))}
                  </div>
                  {type ? null : (
                    <p>
                      {"${"}
                      {firstRequiredType.key}
                      {"}"}
                    </p>
                  )}
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-lg-12 text-right px-0">
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn-1"
                    data-toggle="modal"
                    data-target="#myModal"
                    onClick={handleClick}
                  >
                    PREVIEW
                  </button>
                </div>
              </div>

              {openTestEmailModal ? (
                <Modal isOpen={open}>
                  <div tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
                      <div className="modal-content buy__product__modal py-3">
                        <div className="modal-header py-2">
                          <h6>Enter Email</h6>
                          <button
                            type="button"
                            className="btn btn-light btn-close btn-sm"
                            data-bs-dismiss="modal"
                            onClick={() => setIsOpen(false)}
                          >
                            Close
                          </button>
                        </div>
                        <div className="modal-body">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Type here..."
                          />
                          <button
                            className="btn btn-dark btn-sm d-flex justify-content-end mt-4"
                            onClick={sendTestEmail}
                            style={{ border: "1px solid white" }}
                            disabled={loader3}
                          >
                            {!loader3 && <span>SEND</span>}
                            {loader3 && (
                              <>
                                <span className="spinner spinner-border spinner-border-sm mr-2"></span>
                                SENDING...
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              ) : (
                <Modal isOpen={open}>
                  <div tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
                      <div className="modal-content buy__product__modal py-3">
                        <div className="modal-header py-2">
                          <h2>{type}</h2>
                          <button
                            type="button"
                            className="btn btn-light btn-close btn-sm"
                            data-bs-dismiss="modal"
                            onClick={() => setIsOpen(false)}
                          >
                            Close
                          </button>
                        </div>
                        <div className="modal-body">
                          <iframe
                            srcDoc={
                              !type && !handleEditor
                                ? firstTypeActive.templateBody
                                : !type && handleEditor
                                ? editorText
                                : type && !handleEditor
                                ? emailTemplate
                                : type && handleEditor
                                ? editorText
                                : emailTemplate
                            }
                            title="HTML Template"
                            width={650}
                            height={600}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              ...
            </div>
            <div
              className="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              ...
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Notifications;
