import React from "react";
import { useParams } from "react-router-dom";

const Meeting: React.FC = () => {
    let { id } = useParams();
    let { name } = useParams();
    return (
        <>
            <main className="page-content doctot-detail-user_wrapper">
                <iframe src={process.env.REACT_APP_LOCALHOST_URL_VIDEO + "" + id+"/"+name} allow="camera; microphone" style={{ width: "100%", height: "100vh", border: "none" }} />

            </main>

        </>
    )
};

export default Meeting;