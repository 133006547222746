import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";
import AuthContext from "../../../../authContext";
import { useLocation, useNavigate } from "react-router-dom";

const CategoryWiseSpecialist: React.FC = () => {
  const [specialistList, setCatWiseSpecialist] = useState<any>([]);
  const [catWiseSpecialist, setcatWiseSpecialist] =
    useState<any>("catWiseSpecialist");
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const goToSpecialistDetailsPage = (id: number, specialistList: any) => {
    navigate(`/dashboard/specialists/specialistDetails/${id}`, {
      state: { specialistList, catWiseSpecialist },
    });
  };
  const getCatWiseSpecialist = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 1,
                },
                {
                  field: "uuid",
                  operator: "eq",
                  value: `${location.state}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryDoctorList",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        setCatWiseSpecialist(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCatWiseSpecialistCb = useCallback(getCatWiseSpecialist, [
    authContext.token,
    location.state,
  ]);

  useEffect(() => {
    getCatWiseSpecialistCb();
  }, [getCatWiseSpecialistCb]);

  return (
    <>
      <main className="page-content user_wrapper specialists__wrapper categories-wrpper user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="col-lg-12">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs justify-content-sm-between"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <button
                    className={`nav-button  active`}
                    id="pills-all-tab"
                    data-toggle="pill"
                    aria-controls="pills-all"
                  >
                    ALL
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab table-responsive">
                    <table className="table table--lines table--striped active">
                      <colgroup>
                        <col className="colgroup-1" />
                        <col className="colgroup-3" />
                        <col className="colgroup-4" />
                        <col className="colgroup-5" />
                        <col className="colgroup-6" />
                        <col />
                      </colgroup>
                      <thead className="table__header table__header--sticky text-center">
                        <tr>
                          <th>
                            <span>Name</span>
                          </th>
                          <th>
                            <span>Gender</span>
                          </th>
                          <th>
                            <span>Email- Id</span>
                          </th>
                          <th>
                            <span>Mobile No.</span>
                          </th>
                          <th>
                            <span>Department</span>
                          </th>
                          <th className="table__actions"></th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {specialistList.length !== 0 &&
                        specialistList !== null ? (
                          specialistList.map((elem: any) => (
                            <tr className="table__row" key={elem.id}>
                              <td className="table__td">
                                <div className="media-item">
                                  <div className="media-item__right">
                                    <h5 className="media-item__title text-truncate font-14">
                                      {elem.name}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td className="table__td">
                                <span className="text-grey text-truncate font-14">
                                  {elem.gender}
                                </span>
                              </td>
                              <td className="table__td">
                                <span className="text-truncate font-14 text-transform-unset">
                                  {elem.email}
                                </span>
                              </td>
                              <td className="table__td">
                                <span className="text-grey text-truncate font-14">
                                  {elem.phone}
                                </span>
                              </td>
                              <td className="table__td">
                                <div className="table__status text-truncate font-14">
                                  Innovative Labs
                                </div>
                              </td>
                              <td className="table__td table__actions">
                                <div
                                  className="items-more font-14 user__view--more text-truncate"
                                  onClick={() =>
                                    goToSpecialistDetailsPage(
                                      elem.userUUID,
                                      elem
                                    )
                                  }
                                >
                                  View
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="col-lg-12">
                            <td colSpan={6}>
                              <img
                                src={noDataFoundImage}
                                alt=""
                                className="img-fluid user__all__active__inactive__card--image"
                                style={{ width: "auto", height: "500px" }}
                                loading="lazy"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CategoryWiseSpecialist;
