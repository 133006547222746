import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../authContext";

const useBroadCatList = () => {
  const [broadCategoryList, setBroadCategoryList] = useState<any>([]);
  const authContext = useContext(AuthContext);
  useEffect(() => {
    broadCatList();
  }, [authContext?.token]);

  const broadCatList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5000,
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: "1",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "broadCategoryList",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext?.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setBroadCategoryList(result?.data?.data);
        } else {
          setBroadCategoryList([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  return broadCategoryList;
};

export default useBroadCatList;
