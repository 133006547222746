import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useUserProfile = (token: string | null) => {
  const [userProfile, setUserProfile] = useState<any>([]);
  useEffect(() => {
    getUserProfile();
  }, [token]);

  const getUserProfile = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          command: JSON.stringify([
            {
              agent: "getUserProfile",
              appName: "selfMaximized",
              folder: "users",
            },
          ]),
        },
        headers: { "x-access-token": token },
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUserProfile(result?.data?.data[0]);
        } else {
          setUserProfile([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return userProfile;
};

export default useUserProfile;
