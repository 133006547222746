import React from "react";
import { CChart } from '@coreui/react-chartjs';

interface TimeSlotsProps {
    timeSlots : any[]
};

const DoughChart:React.FC<TimeSlotsProps> = ({timeSlots}) => {

    const timeSlotsArray = timeSlots.map((elem : any) => {
        return elem.time;
      });
    
      const timeSlotsCount = timeSlots.map((elem : any) => {
        return elem.count
      })

    return (
        <div style={{ width: '250px' }} className="mt-3">
            <CChart
                type="doughnut"
                data={{
                    labels: timeSlotsArray,
                    datasets: [
                        {
                            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                            data: timeSlotsCount,
                        },
                    ],
                }}
                options={{
                    aspectRatio: 1.5,
                    plugins: {
                    tooltip: {
                        enabled: true,
                    }
                    }
                }}
            />
        </div>
       
    )
};

export default DoughChart;