import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../authContext";
import pdf from "../../../src/img/PDF_file_icon.svg.png";
import { toast } from "react-toastify";
import Select from "react-select";
import { title } from "process";
import { useNavigate } from "react-router-dom";

const SpecialistSetting = (ModifiedSettingComp: any) => {
  const EnhancedComponent = (props: any) => {
    const authContext = useContext(AuthContext);
    const [profile, setProfile] = useState<any>({});
    const [categoryList, setCategoryList] = useState<any>([]);
    const [langaugeOptions, setLanguageOptions] = useState<any>([]);
    const [selectedFile, setSelectedFile] = useState<any>("");
    const [imgSource, setImgSrc] = useState<any>("");
    const [fileResponse, setFileResponse] = useState<any>("");
    const [pdfResponse, setPdfResponse] = useState<any>("");
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState<any>("");
    const [pdfFile, setPdfFile] = useState<any>("");
    const [userProfile, setUserProfile] = useState<any>("");
    const [validationError, setValidationError] = useState<any>(false);
    const specialistInfo = localStorage.getItem("userInfo");
    const specialistId =
      typeof specialistInfo === "string"
        ? JSON.parse(specialistInfo)
        : specialistInfo;

    const pDFFILE = profile?.exprience && profile?.exprience[0]?.file;
    const title = profile?.exprience && profile?.exprience[0]?.title;
    const userPic = profile && profile?.profile;
    const expId = profile?.exprience && profile?.exprience[0]?.id;

    useEffect(() => {
      setPdfFile(pDFFILE);
      setUserProfile(userPic);
    }, [pDFFILE, userPic]);

    const areaOfExpertise =
      profile?.exprience && profile?.exprience[0]?.areaOfExpertise
        ? JSON.parse(profile?.exprience[0]?.areaOfExpertise)
        : "";

    const filteredAreaOfExp =
      areaOfExpertise &&
      areaOfExpertise.map((elem: any, index: any) => {
        return { value: index, label: elem.category };
      });

    const education = profile?.exprience && profile?.exprience[0]?.education;
    const yearOfexp =
      profile?.exprience && profile?.exprience[0]?.yearofexperience;

    useEffect(() => {
      if (filteredAreaOfExp) {
        setSpecialistEditData((prevData: any) => ({
          ...prevData,
          areaOfExp: filteredAreaOfExp,
        }));
      }
    }, [profile?.exprience]);

    useEffect(() => {
      getLanguagesList();
      getCategories();
    }, []);

    //For Language
    const selectedLanguage = profile?.language;
    const splitSelectedLang = selectedLanguage
      ? JSON.parse(selectedLanguage)
      : [];

    const setLang = splitSelectedLang.map((elem: any, index: any) => ({
      value: index,
      label: elem,
    }));

    const [speciailistEditData, setSpecialistEditData] = useState<any>({
      areaOfExp: [],
      selectedExp: yearOfexp,
      language: setLang,
      education: education,
      gender: profile?.gender,
      name: profile?.name,
      phone: profile?.phone,
      bio: profile?.bio,
      title: title,
    });

    const [modifiedPayloadAreaOfExp, setModifiedPayloadAreaOfExp] = useState<
      any[]
    >([]);

    useEffect(() => {
      let payloadAreaOfExp =
        speciailistEditData && speciailistEditData?.areaOfExp;
      if (payloadAreaOfExp) {
        const updatedPayloadAreaOfExp = payloadAreaOfExp.map((elem: any) => {
          return { category: elem.label, other: true };
        });
        setModifiedPayloadAreaOfExp(updatedPayloadAreaOfExp);
      }
    }, [speciailistEditData && speciailistEditData?.areaOfExp?.length]);

    useEffect(() => {
      if (setLang || education) {
        setSpecialistEditData((prevData: any) => ({
          ...prevData,
          language: setLang,
          gender: profile?.gender,
          education: education,
          selectedExp: yearOfexp,
          name: profile?.name,
          phone: profile?.phone,
          bio: profile?.bio,
          title: title,
        }));
      }
    }, [profile?.language, education, yearOfexp, title]);

    console.log(speciailistEditData.selectedExp);
    const handleSelectChange = (value: any, actionMeta: any) => {
      console.log(value, "line 114");
      console.log(actionMeta, "action meta");
      setSpecialistEditData((prevData: any) => ({
        ...prevData,
        [actionMeta.name]: value, // Update the value for the specified field
      }));
    };

    const getCategories = async () => {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          filter: {
            filter: {
              logic: "and",
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setCategoryList(result?.data?.data);
          } else {
            setCategoryList([]);
          }
        })
        .catch((err) => {
          return err;
        });
    };

    const getLanguagesList = async () => {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          action: "command",
          command: [
            {
              agent: "languageLists",
              appName: "selfMaximized",
              folder: "build",
            },
          ],
        })
        .then((result) => {
          setLanguageOptions(result?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const mappedOptions = langaugeOptions
      .sort((a: any, b: any) => a.language.localeCompare(b.language))
      .map((option: any) => ({
        value: option.id,
        label: option.language,
      }));

    const mappedAreaOfExpOptions =
      categoryList && categoryList.length !== 0
        ? [
            ...categoryList.map((option: any, index: any) => ({
              value: index,
              label: option.name,
            })),
            { value: "OTHER", label: "OTHER" }, // Adding the "Other" option
          ]
        : [{ value: 1, label: "ENGLISH" }];

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setSpecialistEditData((speciailistEditData: any) => ({
        ...speciailistEditData,
        [name]: value,
      }));
    };

    const handleFileChange2 = async (e: any) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (allowedTypes.includes(file.type)) {
          setSelectedFile(file);
          const imageSrc = URL.createObjectURL(file);
          setImgSrc(imageSrc);
          await fileUpload(file);
        } else {
          toast.error("Please select a PNG, JPG, or JPEG file.");
          setSelectedFile(null);
          setImgSrc("");
          e.target.files = null;
        }
      }
    };

    const fileUpload = async (imgFile: any) => {
      const formData = new FormData();
      formData.append("file", imgFile);
      formData.append("action", "formcommand");
      formData.append(
        "command",
        '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
      );
      formData.append("docType", "profile");

      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, formData, {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXVpZCI6ImRhZjc5ZmEyLWQzNWMtNGZiZS1hZDVkLTMwY2ZjOTFiNzY5MyIsImVtYWlsIjoic3VwZXJhZG1pbkBzZWxmbWF4aW1pemVkLmNvbSIsInJvbGUiOiJhZG1pbiIsIm9uQ3JlYXRlIjoiMjAyNC0wMi0wOFQwODo1MzozOC4wMDBaIiwib25VcGRhdGUiOiIyMDI0LTAyLTA4VDA4OjUzOjM4LjAwMFoiLCJpYXQiOjE3MDczODI2OTYsImV4cCI6MTcwNzM5MTY5Nn0.RtpLrloMLDoWxzEMSPLwssPr53U5GsiEYj0kHK2Hz64",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (result?.data?.data?.url) {
            setFileResponse(result?.data?.data?.url);
          } else {
            return;
          }
        });
    };

    const handleFileChange = async (e: any) => {
      const selectedFile = e.target.files[0];
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

      const allowedTypes = ["application/pdf"];

      if (
        allowedTypes.includes(selectedFile.type) &&
        selectedFile.size < maxSizeInBytes
      ) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
      }
      if (
        allowedTypes.includes(selectedFile.type) &&
        selectedFile.size > maxSizeInBytes
      ) {
        return toast.error(
          "Selected file exceeds the maximum size limit (5 MB)"
        );
      }
      if (!allowedTypes.includes(selectedFile.type)) {
        setFile(null);
        setFileName("");
        toast.error("Please select a pdf file");
        return;
      }
    };

    useEffect(() => {
      if (file) {
        pdfUpload(file);
      }
    }, [file]);

    const pdfUpload = async (file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("action", "formcommand");
      formData.append(
        "command",
        '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
      );
      formData.append("docType", "profile");

      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, formData, {
          headers: {
            "x-access-token":
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXVpZCI6ImRhZjc5ZmEyLWQzNWMtNGZiZS1hZDVkLTMwY2ZjOTFiNzY5MyIsImVtYWlsIjoic3VwZXJhZG1pbkBzZWxmbWF4aW1pemVkLmNvbSIsInJvbGUiOiJhZG1pbiIsIm9uQ3JlYXRlIjoiMjAyNC0wMi0wOFQwODo1MzozOC4wMDBaIiwib25VcGRhdGUiOiIyMDI0LTAyLTA4VDA4OjUzOjM4LjAwMFoiLCJpYXQiOjE3MDczODI2OTYsImV4cCI6MTcwNzM5MTY5Nn0.RtpLrloMLDoWxzEMSPLwssPr53U5GsiEYj0kHK2Hz64",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          if (result?.data?.data?.url) {
            setPdfResponse(result?.data?.data?.url);
            setPdfFile(1);
          } else {
            return;
          }
        });
    };

    const getSpecialistProfile = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/pipeline`, {
          params: {
            action: "getcommand",
            command: JSON.stringify([
              {
                agent: "getSpecialistProfile",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ]),
          },
          headers: { "x-access-token": authContext.token },
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setProfile(result?.data?.data[0]);
            setFileResponse("");
            setFileName(true);
          } else {
            setProfile([]);
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    };

    useEffect(() => {
      getSpecialistProfile();
    }, []);

    const updateSpecialist = async () => {
      console.log(userProfile);

      if (!userProfile && !fileResponse) {
        return setValidationError(true);
      }
      if (pdfFile === null) {
        return setValidationError(true);
      }
      console.log(validationError);

      if (
        speciailistEditData.language.length === 0 ||
        speciailistEditData.areaOfExp.length === 0 ||
        !speciailistEditData.phone ||
        !speciailistEditData.bio ||
        !speciailistEditData.gender ||
        !speciailistEditData.selectedExp ||
        !speciailistEditData.name ||
        !speciailistEditData.education ||
        !speciailistEditData.title
      ) {
        setValidationError(true); // Trigger error if validation fails
        return;
      } else {
        const filterLang = speciailistEditData?.language?.map((elem: any) => {
          return elem.label;
        });

        if (filterLang && areaOfExpertise) {
          await axios
            .post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                id: specialistId.id,
                uuid: "8fcbb062-5f56-4bd0-a796-5087be74adda",
                email: specialistId.email,
                profile: fileResponse || userProfile,
                name: speciailistEditData.name,
                phone: speciailistEditData.phone,
                gender: speciailistEditData.gender,
                language: filterLang,
                bio: speciailistEditData.bio,
                dob: "",
                margin: null,
                tags: "doctor,NSS,Test",

                experience: {
                  id: expId,
                  doctorId: specialistId.id,
                  uuid: "1fef9723-50be-11ef-a7f1-80000b80582c",
                  title: speciailistEditData.title,
                  education: speciailistEditData.education,
                  yearOfExprience: speciailistEditData.selectedExp,
                  gender: speciailistEditData.gender,
                  language: filterLang,
                  areaOfExpertise: modifiedPayloadAreaOfExp,
                  details: "details",
                  file: pdfResponse || pdfFile,
                },

                menuId: "cce692df-7d87-48b9-8fcf-974ef927bf41",
                action: "command",
                command: [
                  {
                    agent: "updateSpecialist",
                    appName: "selfMaximized",
                    folder: "specialist",
                  },
                ],
              },
              {
                headers: {
                  "x-access-token": `${authContext.token}`,
                },
              }
            )
            .then((response) => {
              if (response?.data?.statusCode === 200) {
                return toast.success("Profile updated successfully!");
              }
            })
            .catch((err) => {
              return;
            });
        }
      }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleTriggerClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current?.click();
      }
    };

    const fileInputRef2 = useRef<HTMLInputElement>(null);
    const handleTriggerClick2 = () => {
      if (fileInputRef2.current) {
        fileInputRef2.current?.click();
      }
    };

    //COMPONENT
    const BankDetails = () => {
      const [bankDetails, setBankDetails] = useState<any>({
        bank: "",
        branch: "",
        country: "",
        city: "",
        swiftCode: "",
      });
      const [editBtn, setEditBtnDisabled] = useState<any>(false);
      const [loader, setLoader] = useState<any>(false);
      const navigate = useNavigate();
      const [showBtn, setShowBtn] = useState<any>(false);

      const handleBankingDetails = (event: any) => {
        const { name, value } = event.target;
        setBankDetails({ ...bankDetails, [name]: value });
      };

      const publishBankDetails = async (type: any) => {
        let errorMessage = "";

        if (
          !bankDetails?.bank ||
          !bankDetails?.branch ||
          !bankDetails?.country ||
          !bankDetails?.city ||
          !bankDetails?.swiftCode
        ) {
          let anyErrorFound = false;

          if (!bankDetails?.bank) {
            errorMessage += "Bank is mandatory. ";
            anyErrorFound = true;
          }

          if (!anyErrorFound && !bankDetails?.branch) {
            errorMessage += "Branch is mandatory. ";
            anyErrorFound = true;
          }
          if (!anyErrorFound && !bankDetails?.country) {
            errorMessage += "Country is mandatory. ";
            anyErrorFound = true;
          }

          if (!anyErrorFound && !bankDetails?.city) {
            errorMessage += "City is mandatory. ";
            anyErrorFound = true;
          }

          if (!anyErrorFound && !bankDetails?.swiftCode) {
            errorMessage += "Swift code is mandatory. ";
            anyErrorFound = true;
          }
          // If all fields are empty
          if (
            !bankDetails?.bank &&
            !bankDetails?.branch &&
            !bankDetails?.country &&
            !bankDetails?.city &&
            !bankDetails?.swiftCode
          ) {
            errorMessage = "All fields are mandatory.";
          }
        }

        if (errorMessage === "") {
          // If no error, proceed with your code
        } else {
          return toast.warning(errorMessage);
        }

        setLoader(true);
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              bank: bankDetails.bank,
              branch: bankDetails.branch,
              country: bankDetails.country,
              city: bankDetails.city,
              swiftCode: bankDetails.swiftCode,
              action: "command",
              command: [
                {
                  agent: "specialistBank",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
              ],
            },
            {
              headers: { "x-access-token": authContext.token },
            }
          )
          .then((result) => {
            if (result?.data?.status === "success") {
              setBankDetails({
                bank: "",
                branch: "",
                country: "",
                city: "",
                swiftCode: "",
              });
              if (type === "update") {
                setShowBtn(false);
                setEditBtnDisabled(true);
                getBankDetails();
                setLoader(false);

                return toast.success("Bank details updated successfully");
              } else {
                setLoader(false);
                return toast.success(result?.data?.message);
              }
            } else {
              setLoader(false);

              toast.info(result?.data?.message);
            }
          })
          .catch((err) => {
            setLoader(false);

            return toast.error("Something went wrong", err);
          });
      };

      useEffect(() => {
        getBankDetails();
      }, [authContext.token]);

      const getBankDetails = async () => {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              filter: {
                filter: {
                  logic: "and",
                  offset: 0,
                  limit: 1,
                  filters: [
                    {
                      field: "userId",
                      operator: "eq",
                      table: "UserBankDetails",
                      value: specialistId.id,
                    },
                  ],
                  sort: [
                    {
                      field: "id",
                      dir: "desc",
                    },
                  ],
                },
              },
              action: "command",
              command: [
                {
                  agent: "bankAccountList",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
              ],
            },
            {
              headers: { "x-access-token": authContext.token },
            }
          )
          .then((result) => {
            if (result?.data?.status === "success") {
              setBankDetails(result?.data?.data[0]);
              if (result?.data?.data[0]) {
                setEditBtnDisabled(true);
              }
            } else {
              setBankDetails([]);
            }
          })
          .catch((err) => {
            return toast.error("Something went wrong", err);
          });
      };

      const showUpdateAndCancelBtn = () => {
        setShowBtn(true);
        setEditBtnDisabled(false);
      };

      const cancelBtn = () => {
        setShowBtn(false);
        setEditBtnDisabled(true);
        getBankDetails();
      };

      useEffect(() => {
        if (bankDetails && bankDetails === undefined) {
          setShowBtn(true);
        }
      }, [showBtn]);
      return (
        <>
          <div className="col-lg-12">
            <h2 className="lets-fill-basic mb-0">LETS FILL BANK DETAILS</h2>
            <div className="border-bottom-line mt-3">
              <p className="mb-2">BANK DETAILS</p>
            </div>
          </div>
          {/* <main className="page-content doctot-detail-user_wrapper"> */}
          <div className="container pb-3">
            <div className="row">
              {!editBtn && !showBtn ? (
                <div className="col-lg-12 text-right px-0 pb-3 mt-4">
                  <button
                    type="button"
                    className="font-weight-bold publish-btn product__publish__btn"
                    onClick={() => publishBankDetails("publish")}
                  >
                    {loader ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "PUBLISH"
                    )}
                  </button>
                </div>
              ) : !showBtn && editBtn && bankDetails?.bank !== "" ? (
                <div className="col-lg-12 text-right px-0 pb-3 mt-4">
                  <button
                    type="button"
                    className="font-weight-bold publish-btn product__publish__btn"
                    onClick={showUpdateAndCancelBtn}
                  >
                    {loader ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "EDIT"
                    )}
                  </button>
                </div>
              ) : null}
              {showBtn ? (
                <div className="col-lg-12 text-right px-0 pb-3 mt-4">
                  <button
                    type="button"
                    className="font-weight-bold publish-btn product__publish__btn"
                    onClick={cancelBtn}
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="font-weight-bold publish-btn product__publish__btn ml-3"
                    onClick={() => publishBankDetails("update")}
                  >
                    UPDATE
                  </button>
                </div>
              ) : null}
            </div>
            <div className="row user_details-inner_wrapper pb-4">
              <div className="col-lg-12">
                <div className="row pb-4 py-4">
                  <div className="col-12">
                    <div className="form-group mb-0">
                      <p className="mb-0 short__title__product font-14 pb-1">
                        Bank
                      </p>
                      <input
                        type="text"
                        className="form-control w-100"
                        id="shortTitleProduct"
                        name="bank"
                        autoComplete="off"
                        onChange={handleBankingDetails}
                        value={bankDetails?.bank || ""}
                        disabled={editBtn}
                      />
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <p className="mb-0 short__title__product font-14 pb-1">
                      Branch
                    </p>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="shortTitleProduct"
                      name="branch"
                      autoComplete="off"
                      onChange={handleBankingDetails}
                      value={
                        bankDetails && bankDetails.branch !== ""
                          ? bankDetails.branch
                          : ""
                      }
                      disabled={editBtn}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="short__title__product pb-1 font-14">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="shortTitleProduct"
                      name="country"
                      autoComplete="off"
                      onChange={handleBankingDetails}
                      value={
                        bankDetails && bankDetails.country !== ""
                          ? bankDetails.country
                          : ""
                      }
                      disabled={editBtn}
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-6 pt-3 pt-lg-0">
                        <div className="form-group mb-0">
                          <p className="mb-0 short__title__product font-14 pb-1">
                            City
                          </p>
                          <input
                            type="text"
                            className="form-control w-100"
                            id="price"
                            name="city"
                            autoComplete="off"
                            onChange={handleBankingDetails}
                            value={
                              bankDetails && bankDetails.city !== ""
                                ? bankDetails.city
                                : ""
                            }
                            disabled={editBtn}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pt-3 pt-lg-0">
                        <div className="form-group mb-0mt-4">
                          <p className="mb-0 short__title__product font-14 pb-1">
                            Swift Code
                          </p>
                          <input
                            type="text"
                            className="form-control w-100"
                            id="compareatprice"
                            name="swiftCode"
                            autoComplete="off"
                            onChange={handleBankingDetails}
                            value={
                              bankDetails && bankDetails.swiftCode !== ""
                                ? bankDetails.swiftCode
                                : ""
                            }
                            disabled={editBtn}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    return (
      <main className="page-content doctot-detail-user_wrapper overflow-auto">
        <div className="container pb-3 pt-5 pt-lg-0">
          <div className="row">
            <div className="col-lg-12 text-right px-0 pb-3">
              <button className="edit__profile" onClick={updateSpecialist}>
                <span className="text-dark font-weight-bold add-btn">
                  <i className="fa fa-save pr-2"></i>SAVE
                </span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <p className="below-are-you">BELOW ARE YOUR PERSONAL DETAILS</p>
              <div className="border-bottom-line">
                <p className="mb-2">PERSONAL DETAILS</p>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-lg-12">
              <form className="personal-details-form">
                <div className="row d-flex align-items-center">
                  <div className="col-lg-2">
                    <div className="d-flex justify-content-between pb-2">
                      <label
                        htmlFor="update-pdf-file"
                        className="update-pdf-file"
                      >
                        <span className="text-white pe-1 vertical-top">*</span>
                        Upload Profile Pic
                      </label>
                      <span
                        className="cancel-icon cursor_pointer"
                        onClick={() => {
                          setImgSrc("");
                          setFileResponse("");
                          setUserProfile("");
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>

                    <div className="col-ting pb-2">
                      <div
                        className="control-group file-upload"
                        id="file-upload1"
                      >
                        {fileResponse || userProfile ? (
                          <img src={fileResponse || userProfile} />
                        ) : (
                          ""
                        )}
                        <div
                          className={`${
                            fileResponse || userProfile
                              ? ""
                              : "text-center d-flex justify-content-center align-items-center file__upload--inner rounded"
                          }`}
                          onClick={handleTriggerClick}
                          style={{
                            display: "none",
                            margin: "0 auto",
                            height: "10em",
                            backgroundColor: "#3A3838",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                        >
                          <p className="mb-0">Upload Image</p>
                        </div>
                        <div className="controls" style={{ display: "block" }}>
                          <input
                            type="file"
                            name="contact_image_1"
                            autoComplete="off"
                            ref={fileInputRef}
                            onChange={handleFileChange2}
                            style={{ display: "none" }}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                      </div>
                      <span className="text-danger please__add--image">
                        {validationError &&
                          !userProfile &&
                          "PLEASE SELECT FILE"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-6">
                        <label htmlFor="email">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Email
                        </label>
                        <p className="mb-0 font-14 text-transform-unset">{profile?.email}</p>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="name">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Name
                        </label>
                        <input
                          type="text"
                          pattern="[A-Za-z\s]+"
                          className="form-control"
                          name="name"
                          value={speciailistEditData?.name}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            !speciailistEditData?.name &&
                            "PLEASE ENTER NAME"}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="cnumber">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Contact number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="00-00-00-00-00"
                          name="phone"
                          value={speciailistEditData?.phone}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <span className="text-danger registration__error--msg">
                          {" "}
                          {validationError &&
                            !speciailistEditData?.phone &&
                            "PLEASE ENTER CONTACT NO"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 py-3">
                    <label htmlFor="comment">
                      <span className="text-white pe-1 vertical-top">*</span>
                      Bio
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      cols={5}
                      id="message"
                      name="bio"
                      value={speciailistEditData?.bio}
                      onChange={handleChange}
                    ></textarea>
                    <span className="text-danger  registration__error--msg">
                      {validationError &&
                        !speciailistEditData?.bio &&
                        "PLEASE ENTER BIO"}
                    </span>
                  </div>
                </div>
                <p className="row py-4 mb-0">
                  <div className="col-lg-12">
                    <p className="border-bottom-line"></p>
                  </div>
                </p>
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="lets-fill-basic mb-0">
                      LETS FILL MEDICAL DETAIL
                    </h2>
                    <div className="border-bottom-line mt-3">
                      <p className="mb-2">MEDICAL DETAILS</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-12 personal-details-form">
              <form className="pt-5">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="d-flex justify-content-between pb-1">
                      <label
                        htmlFor="update-pdf-file"
                        className="update-pdf-file"
                      >
                        <span className="text-white pe-1 vertical-top">*</span>
                        Upload PDF File
                      </label>
                      <span
                        className="cancel-icon cursor_pointer"
                        onClick={() => {
                          setFile(null);
                          setFileName("");
                          setPdfFile(null);
                        }}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <div className="col-ting_pdf" onClick={handleTriggerClick2}>
                      <div className="drop-zone">
                        <div>
                          <img
                            className="drop-zone__prompt img-fluid fs-12"
                            src={
                              pdfFile
                                ? pdf
                                : fileName || file || pdfFile
                                ? pdf
                                : ""
                            }
                            alt="upload-icon"
                          />
                          <p>{fileName}</p>
                        </div>

                        <input
                          type="file"
                          name="myFile"
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={handleFileChange}
                          ref={fileInputRef2}
                          autoComplete="off"
                          id="input_file"
                        />
                      </div>
                      <span className="text-danger d-flex justify-content-center registration__error--msg">
                        {validationError &&
                          file === null &&
                          pdfFile === null &&
                          "PLEASE SELECT FILE"}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-12 pb-3">
                        <label htmlFor="education">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Education
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="education"
                          value={speciailistEditData?.education}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            !speciailistEditData?.education &&
                            "PLEASE ENTER EDUCATION"}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="ptitle">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Professional title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={speciailistEditData?.title}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            !speciailistEditData?.title &&
                            "PLEASE ENTER TITLE"}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="yexperience">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Years of experience
                        </label>
                        <div id="selfDropdown">
                          <select
                            id="select"
                            name="selectedExp"
                            value={speciailistEditData.selectedExp}
                            onChange={handleChange}
                          >
                            <option disabled>Select experience</option>
                            <option value="5">LESS THAN 5</option>
                            <option value="6">OVER 5</option>
                            <option value="10">OVER 10</option>
                            <option value="15">OVER 15</option>
                          </select>
                        </div>
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            !speciailistEditData?.selectedExp &&
                            "PLEASE SELECT EXPERIENCE"}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="gender">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Gender
                        </label>

                        <div id="selfDropdown">
                          <select
                            id="select"
                            name="gender"
                            value={speciailistEditData.gender}
                            onChange={handleChange}
                          >
                            <option disabled selected>
                              Select gender
                            </option>
                            <option value="Female"> Female</option>
                            <option value="Male">Male</option>
                          </select>
                        </div>
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            !speciailistEditData?.gender &&
                            "PLEASE SELECT GENDER"}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="language">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Languages
                        </label>
                        <div id="selfDropdown">
                          <Select
                            value={speciailistEditData.language}
                            onChange={handleSelectChange}
                            options={mappedOptions}
                            isSearchable={true}
                            name="language"
                            className="text-dark text-uppercase letter-spacing"
                            isMulti
                            styles={{
                              control: (provided: any, state: any) => ({
                                ...provided,
                                fontSize: "16px",
                                color: "inherit",
                                backgroundColor: "#fff",
                                letterSpacing: "1px",
                                border: state.isFocused
                                  ? "2px solid black"
                                  : "2px solid transparent", // Custom border style
                                boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                                "&:hover": {
                                  borderColor: "black", // Border color on hover
                                },
                              }),
                              option: (provided: any, state: any) => ({
                                ...provided,
                                color:
                                  state.isFocused || state.isSelected
                                    ? "white"
                                    : "black", // Change color to black when focused or selected
                                backgroundColor: state.isFocused
                                  ? "#000"
                                  : "#fff", // Background color when focused
                              }),
                              singleValue: (provided: any) => ({
                                ...provided,
                                color: "#000", // Set color of the selected option to white
                              }),
                              input: (provided: any) => ({
                                ...provided,
                                color: "#000",
                              }),
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "black",
                              }),
                              menu: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#000",
                              }),
                            }}
                            placeholder="SEARCH..."
                          />
                        </div>
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            speciailistEditData?.language.length === 0 &&
                            "PLEASE SELECT LANGUAGE"}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 pb-3">
                        <label htmlFor="aexpertise">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          Area of expertise
                        </label>
                        <div id="selfDropdown">
                          <Select
                            value={speciailistEditData.areaOfExp}
                            onChange={handleSelectChange}
                            options={mappedAreaOfExpOptions}
                            isSearchable={true}
                            name="areaOfExp"
                            className="text-dark letter_spacing"
                            isMulti
                            styles={{
                              control: (provided: any, state: any) => ({
                                ...provided,
                                fontSize: "16px",
                                color: "inherit",
                                backgroundColor: "#fff",
                                letterSpacing: "1px",
                                border: state.isFocused
                                  ? "2px solid black"
                                  : "2px solid transparent", // Custom border style
                                boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                                "&:hover": {
                                  borderColor: "black", // Border color on hover
                                },
                              }),
                              option: (provided: any, state: any) => ({
                                ...provided,
                                color:
                                  state.isFocused || state.isSelected
                                    ? "white"
                                    : "black", // Change color to black when focused or selected
                                backgroundColor: state.isFocused
                                  ? "#000"
                                  : "#fff", // Background color when focused
                              }),
                              singleValue: (provided: any) => ({
                                ...provided,
                                color: "#000", // Set color of the selected option to white
                              }),
                              input: (provided: any) => ({
                                ...provided,
                                color: "black",
                              }),
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "white",
                              }),
                              menu: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#000",
                              }),
                            }}
                            placeholder="SEARCH..."
                          />
                        </div>
                        <span className="text-danger registration__error--msg">
                          {validationError &&
                            speciailistEditData.areaOfExp.length === 0 &&
                            "PLEASE SELECT AREA OF EXPERTISE"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 py-5">
              <p className="border-bottom-line mb-0"></p>
            </div>
          </div>

          <BankDetails />
          {/* </main> */}
        </div>
      </main>
    );
  };

  return EnhancedComponent;
};

export default SpecialistSetting;
