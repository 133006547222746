import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../authContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Shimmer from "../../../pages/AdminDashboard/Shimmer";
import moment from "moment-timezone";
import useUserProfile from "../../../customHooks/useUserProfile";

const WeeklyAppoinments: React.FC = () => {
  const [upcomingWeeklyBooking, setUpcomingWeeklyBooking] = useState<any>([]);
  const [loader, setLoader] = useState<any>(true);
  const authCtx = useContext(AuthContext);
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;
  const userMenu =
    typeof authCtx.userMenu === "string"
      ? JSON.parse(authCtx.userMenu)
      : authCtx.userMenu;
  const dashboardMenuItem: any = userMenu.find(
    (item: any) => item.menu === "Dashboard"
  );

  const getWeeklyAppointments = async () => {
    setLoader(true);

    const dates: any = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const startDate = new Date(today);
      startDate.setDate(today.getDate() + i);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);

      const startYear = startDate.getFullYear();
      const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
      const startDay = String(startDate.getDate()).padStart(2, "0");

      const endYear = endDate.getFullYear();
      const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
      const endDay = String(endDate.getDate()).padStart(2, "0");

      dates.push({
        startDate: `${startYear}-${startMonth}-${startDay}`,
        endDate: `${endYear}-${endMonth}-${endDay}`,
      });
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: dates[0].startDate,
                opr: "AND",
                value2: dates[6].endDate,
              },
              {
                field: "uuid",
                table: "Users",
                operator: "eq",
                value: `${userInfo.uuid}`,
              },
            ],

            sort: [
              {
                field: "calendarDate",
                dir: "asc",
              },
            ],
          },
        },
        menuId: `${dashboardMenuItem.uuid}`,
        action: "command",
        command: [
          {
            agent: "userBooking",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );

    const newData = response.data.data;
    setUpcomingWeeklyBooking(newData);
    setLoader(false);
    let booking: any = newData && newData[0];
    const meetingUrl = booking?.meetingUrl;
    if (typeof meetingUrl === "string") {
      const meetingLink = JSON.parse(meetingUrl);
      console.log(meetingLink);
    } else {
      console.log(meetingUrl);
    }
  };

  const userTimeZone =
    upcomingWeeklyBooking?.length !== 0 && upcomingWeeklyBooking[0]?.timezone;

  useEffect(() => {
    getWeeklyAppointments();
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <div className="col-lg-12 mb-3">
        {upcomingWeeklyBooking?.length !== 0 &&
        upcomingWeeklyBooking !== null ? (
          upcomingWeeklyBooking?.map((elem: any) => (
            <div className="row doctor-dashboard_reshedule-box p-3 position-relative">
              <div className="col-8 border-left_new">
                <h2 className="mb-0 doctor-name font-16 mb-2">
                  DR - {elem.dname.toUpperCase()}
                </h2>

                <p className="mb-0 date-time-day-wrapper d-inline-block p-2 font-14">
                  <span className="appoinments-booked-time font-weight-bold">
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(
                            elem.startTime.replace(/:000Z$/, ":00Z"),
                            userTimeZone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                    -
                    {userTimeZone && elem?.endTime
                      ? moment
                          .tz(
                            elem.endTime.replace(/:000Z$/, ":00Z"),
                            userTimeZone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                   <span className="pl-1">|</span> {elem.duration === "0.50" ? "30 min" : "60 min"} |
                  </span>
                  <span className="appoinments-booked-day font-weight-bold pl-2 text-uppercase 1234">
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(elem.startTime.replace(/:000Z$/, ":00Z"), "UTC")
                          .tz(userTimeZone)
                          .format("YYYY-MM-DD")
                      : "Date Not Available"}{" "}
                    <span className="pr-1">|</span>
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(elem.startTime.replace(/:000Z$/, ":00Z"), "UTC")
                          .tz(userTimeZone)
                          .format("dddd")
                      : "Date Not Available"}{" "}
                  </span>
                </p>
              </div>
              <div className="col-4 text-right">
                <span className="reshedule-link font-14 cursor_pointer text-uppercase">
                  Reschedule
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <span className="not__available text-uppercase">
                No weekly appointments available
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WeeklyAppoinments;
