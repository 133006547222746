import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AuthContext from "../../../authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import admin_logo from "../../../../src/img/content/logo/admin-logo.png";
import axios from "axios";
import { toast } from "react-toastify";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const [eyeChecked, setEyeChecked] = useState<any>("password");
  const [validationMsg, setValidationMsg] = useState<any>("");
  const [emailValidation, setEmailValidation] = useState<any>("");
  const [passwordValidation, setPasswordValidation] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const inputEmail = useRef<any>();
  const inputPassword = useRef<any>();
  const stayLogin = useRef<any>();
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleEyeBtn = (eyeType: any) => {
    if (eyeType === "closeEye") {
      setEyeChecked("text");
    } else if (eyeType === "openEye") {
      setEyeChecked("password");
    }
  };
  let callbackUrl: any;
  let callbackurl2: any;

  useEffect(() => {
    if (authCtx.isLoggedIn && authCtx.userMenu && authCtx.role === "admin") {
      navigate("/dashboard", { replace: true });
      return;
    }
  }, [
    authCtx.isLoggedIn,
    authCtx.userMenu,
    authCtx.role === "admin",
    location.state,
    navigate,
  ]);

  useEffect(() => {
    if (!email && !password) {
      setValidationMsg("");
    } else if (email) {
      setValidationMsg("");
    }
  }, [email, password]);

  const handleLogin = async (e: any) => {
    e.preventDefault();

    const params = new URLSearchParams(window.location.search);
    callbackUrl = params.get("callback");
    callbackurl2 = params.get("callback2");

    try {
      if (!inputEmail.current.value && !inputPassword.current.value) {
        return setValidationMsg("Please enter email and password first");
      } else if (inputEmail.current.value && !inputPassword.current.value) {
        return setPasswordValidation("Please enter password");
      } else if (!inputEmail.current.value && inputPassword.current.value) {
        return setEmailValidation("Please enter email Id");
      }
      const body = {
        email: inputEmail.current.value,
        password: inputPassword.current.value,
        stayLogin: stayLogin.current.value,
        action: "command",
        command: [
          {
            agent: "login",
            appName: "selfMaximized",
            folder: "auth",
          },
        ],
      };
      const response: any = await fetch(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error("Invalid credentials");
        } else {
          return response.json();
        }
      });

      if (response?.status !== "status" && response?.statusCode !== 200) {
        setValidationMsg(response?.message);
      }
      if (response?.statusCode === 200) {
        let userCredentials = response?.data;
        let role = response?.data?.role;
        if (role === "admin") {
          navigate("/dashboard", { replace: true });
          authCtx.login(userCredentials, role);
          return;
        } else if (
          callbackUrl === "userregister" &&
          role === "admin" &&
          role === "specialist"
        ) {
          setValidationMsg("PLEASE LOGIN AS A USER");
          return;
        } else if (
          callbackUrl === "userregister" &&
          role !== "admin" &&
          role !== "specialist"
        ) {
          authCtx.login(userCredentials, role);
          const cartId = localStorage.getItem("cartId");
          const userInfo = localStorage.getItem("userInfo");
          const lastActiveCartId =
            typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
          if (lastActiveCartId && lastActiveCartId?.lastActiveCart !== null) {
            return (window.location.href = `/cart?lastactivecart=true`);
          }
          if (cartId) {
            axios
              .post(`${process.env.REACT_APP_API}/pipeline`, {
                userId: userCredentials
                  ? userCredentials?.userData?.userInfo?.id
                  : null,
                cartId: cartId,
                action: "command",
                command: [
                  {
                    agent: "createUser",
                    appName: "selfMaximized",
                    folder: "order",
                  },
                ],
              })
              .then(async (result) => {
                if (result?.data?.statusCode === 200) {
                  await axios
                    .post(
                      `${process.env.REACT_APP_API}/pipeline`,
                      {
                        successurl: `${process.env.REACT_APP_LOCALHOST_URL}/success/${cartId}`,
                        cancelurl: `${process.env.REACT_APP_LOCALHOST_URL}/cancel`,
                        cartUuid: cartId,
                        organizationId: 1,
                        action: "command",
                        command: [
                          {
                            agent: "createOrder",
                            appName: "selfMaximized",
                            folder: "order",
                          },
                        ],
                      },
                      {
                        headers: {
                          "x-access-token": userCredentials?.authTokens?.token,
                        },
                      }
                    )
                    .then((result) => {
                      if (result?.data?.statusCode === 200) {
                        window.location.href = `/checkout/${result?.data?.data?.orderID}`;
                      } else {
                        return false;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              });
          }
          return;
        } else if (callbackurl2 === "deletecart" && role !== "admin") {
          authCtx.login(userCredentials, role);
          return (window.location.href = `/cart`);
        } else {
          await axios
            .post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                user_id: userCredentials.userData.userInfo.uuid,
                menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
                action: "command",
                command: [
                  {
                    agent: "getUserPolicies",
                    appName: "selfMaximized",
                    folder: "policies",
                  },
                ],
              },
              {
                headers: {
                  "x-access-token": userCredentials?.authTokens?.token,
                },
              }
            )
            .then(async (result) => {
              if (
                result?.data?.statusCode === 200 &&
                result?.data?.data?.length === 0
              ) {
                navigate("/welcome");
                authCtx.login(userCredentials, role);
                return;
              } else {
                navigate("/dashboard");
                authCtx.login(userCredentials, role);
                return;
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      }
    } catch (error: any) {
      console.error("Login failed:", error.message);
    }
  };

  const goToRegistrationPage = (type: any) => {
    const params = new URLSearchParams(window.location.search);
    const callbackUrl = params.get("callback");

    if (type === "user" && callbackUrl === "userregister") {
      window.location.href = "/userregistration?callback=checkout";
      return;
    } else if (type === "user") {
      window.location.href = "/userregistration";
      return;
    } else {
      window.location.href = "/specialistdetails";
      return;
    }
  };

  const goToWebsite = () => {
    window.location.href = "/";
    return;
  };

  return (
    <>
      {/* Login Screen */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div
              className="auth-logo-inner-r cursor_pointer"
              onClick={goToWebsite}
            >
              <img
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                className="img-fluid admin__logo mt-2"
                alt="logo"
                style={{ width: "220px", height: "auto" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-lg-4 mx-auto">
            <form
              className="auth-panel__wrapper"
              method="POST"
              onSubmit={handleLogin}
            >
              <div className="auth-panel__container">
                <div className="auth-panel__body">
                  <div className="auth-panel__body-wrapper">
                    <div className="login-div-wrapper">
                      <h1 className="login-heading mb-0 font-weight-bold pb-4">
                        SIGN IN
                      </h1>
                    </div>
                    <div className="form-group mb-5">
                      <label htmlFor="email_id">
                        <span className="text-white mr-1">*</span>EMAIL ID
                      </label>
                      <div className="input_group">
                        <input
                          className="input text-white"
                          type="email"
                          name="inputEmail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          ref={inputEmail}
                          autoComplete="off"
                          placeholder="ENTER EMAIL"
                        />
                        <span className="text-danger mt-4 text-uppercase">
                          {!email && emailValidation}
                        </span>
                      </div>
                    </div>
                    <div className="form-group mb-1 position-relative">
                      <label htmlFor="password">
                        <span className="text-white mr-1">*</span>PASSWORD
                      </label>
                      <div className="input_group">
                        <input
                          className="input"
                          type={`${eyeChecked}`}
                          name="inputPassword"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          ref={inputPassword}
                          autoComplete="off"
                          placeholder="ENTER PASSWORD"
                        />
                        <span className="text-danger mt-2 text-uppercase">
                          {!password && passwordValidation}
                        </span>
                      </div>
                      <div className="password__show__hide__icons">
                        {eyeChecked === "password" ? (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => toggleEyeBtn("closeEye")}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => toggleEyeBtn("openEye")}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <div className="col text-danger text-uppercase font-12 pt-2">
                          {validationMsg}
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-12 text-left text-lg-right">
                        <div className="form-group">
                          <Link
                            to={"/forgotPassword"}
                            className={"forgot_password font-14"}
                          >
                            FORGOT PASSWORD?
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="auth-panel__submit" onClick={handleLogin}>
                      <button
                        className="button login-btn w-100 rounded-0"
                        type="submit"
                      >
                        <div>
                          {loader ? (
                            <span className="spinner-border spinner-border-sm mr-3"></span>
                          ) : (
                            ""
                          )}
                          <span className="button__text font-weight-bold font-14 ">
                            SIGN IN
                          </span>
                        </div>
                      </button>
                    </div>
                    <div className="form-group pt-3">
                      <div className="input-group input-group--prepend">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="stayLogin"
                            ref={stayLogin}
                          />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 14 12">
                                <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                              </svg>
                            </span>
                          </span>
                          <span className="d-inline-block ml-2 text-white font-14 stay__logged--in">
                            STAY LOGGED IN
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <>
                        <div className="form-group d-flex justify-content-center">
                          <p
                            className={
                              "forgot_password font-14 mb-0 cursor_pointer "
                            }
                            onClick={() => goToRegistrationPage("user")}
                          >
                            REGISTER AS USER
                          </p>
                        </div>
                        <div className="form-group d-flex justify-content-center">
                          <p
                            className={
                              "forgot_password font-14 mb-0 cursor_pointer "
                            }
                            onClick={() => goToRegistrationPage("specialist")}
                          >
                            REGISTER AS SPECIALIST
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
