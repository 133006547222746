import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "./authContext";
import { toast } from "react-toastify";


interface DoctorId {
    doctorId : number
};

const ViewCalendar: React.FC<DoctorId> = ({doctorId}) => {

    const authContext = useContext(AuthContext);
    const [calendarData, setCalendarData] = useState([]);
    const currentDate = new Date();
    const options : any= { weekday: 'short' }; 
    const dayName : any= currentDate.toLocaleDateString('en-US', options);

    const getCalendarData = async () => {

        axios.post(`${process.env.REACT_APP_API}/pipeline`, {
            "filter": {
                "filter": {
                    "logic": "and",
                    "offset": 0,
                    "limit": 500,
                    "filters": [
                        {
                            "field": "calendarDate",
                            "operator": "eq",
                            "table": "BookingCalendar",
                            "value": currentDate
                        },
                        {
                            "field": "doctorId",
                            "operator": "eq",
                            "table": "BookingCalendar",
                            "value": doctorId
                        }
                    ],
                    "sort": [
                        {
                            "field": "calendarDate",
                            "table": "BookingCalendar",
                            "dir": "desc"
                        }
                    ]
                }
            },
            "action": "command",
            "command": [
                {
                    "agent": "specialistCalendarBooking",
                    "appName": "selfMaximized",
                    "folder": "order"
                }
            ]
        },{
            headers: { 'x-access-token': `${authContext.token}` }
        })
        .then(result => {
            if(result?.data?.code === 1){
                setCalendarData(result?.data?.data?.response);
            }
        })
        .catch(err => {
            return toast.error('Something went wrong', err);
        })
    };

    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    useEffect(() => {
        getCalendarData();
    }, [])

  

    return (
        <>
            <div className="specialist-container_wrapper-right-top px-3 pb-3 mb-3">
                <div className="row align-items-center pb-2">
                    <div className="col-6">
                        <p className="today font-14 mb-0 mt-1 text-uppercase">Today</p>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-lg-12">
                        <p className="time-slots mb-0 font-12">Available slots</p>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-lg-12 days-wrapper">
                        <div className="days-wrapper-inner d-flex justify-content-between align-items-center">
                        {
                            dayNames.map((day, index) => (
                                <span 
                                    key={index} 
                                    className={`days-show text ${dayName === day ? 'active' : ''}`}
                                >
                                    {day}
                                </span>
                            ))
                        }
                        </div>
                    </div>
                </div>
                <div className="row pb-4 ">
                    {calendarData.length !== 0 && calendarData !== null ? calendarData.map((elem : any) => 
                        <div className="col-4" >
                            <div className="available-wrapper p-1 pl-2 mb-3 mb-lg-0 " style={{backgroundColor: `${elem.isBooked === 1 ? 'red' : ''}`}}>
                            <p className={`${elem.isBooked === 1 ? '' : 'available-heading'} font-9 mb-0`}>{elem.isBooked === 0 ? 'Availability' : 'Booked'}</p>
                            <p className="mb-0 font-13 font-weight-normal">
                                <span className={`font-weight-bold ${elem.isBooked === 1 ? 'text-dark' : ''}`}>{elem.StartTime}</span>
                            </p>
                            </div>
                        </div>
                        
                    ) :

                     <div className="col-lg-12 text-center">
                        <span className="text-white font-14">No slots available</span>
                     </div>

                    }
                </div>
            </div>  
        </>
    )
};

export default ViewCalendar;