import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../authContext";
import { toast } from "react-toastify";
import Modal from "./Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Swal from "sweetalert2";

const SpecialistBody: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [dailyAppointments, setDailyAppointments] = useState<any>([]);
  const [weeklyAppoinments, setWeeklyAppointments] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const [slots, setSlots] = useState<any>([]);
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [rescheduleUuid, setRescheduleUuid] = useState<any>("");
  const [onboardedUsers, setOnboardedUsers] = useState<any>("");
  // Parse userInfo only if it's a string
  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;
  const currentDate = new Date();

  useEffect(() => {
    getUserOnboardedList();
  }, [authContext.token]);

  const handleOpen: any = (rescheduleUuid: any) => {
    setSlots([]);
    setSelectedSlot("");
    setSelectedDate("");
    setOpen(true);
    setRescheduleUuid(rescheduleUuid);
  };

  const handleClose: any = () => {
    setSelectedSlot([]);
    setSelectedDate("");
    setSlots([]);
    setRescheduleUuid("");
    setOpen(false);
  };

  const handleSlots: any = async (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };

  const bookSlot = () => {
    if (selectedSlot) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reschedule it!",
        customClass: {
          container: "my-swal",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setOpen(false);
            Swal.fire({
              title:
                "Please wait for a moment while the appointment is being rescheduled.",
              timer: 5000,
            });
            await rescheduleSlots(selectedSlot.uuid);
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    } else {
      return toast.info("Select slot first");
    }
  };

  useEffect(() => {
    specialistWeeklyAppointments();
    specialistDailyAppointments();
  }, [authContext.token, authContext.role, authContext.userInfo]);

  const handleDateChange = (date: any) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    getSpecialistBookingSlots(formattedDate);
  };

  const rescheduleSlots = async (selectedUuid: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          newuuid: selectedUuid,
          uuid: rescheduleUuid,
          action: "command",
          command: [
            {
              agent: "specialistBookingReschedule",
              appName: "selfMaximized",
              folder: "specialist",
            },
            {
              agent: "submitSpecialistRescheduelMail",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then(async (result) => {
        Swal.fire({
          title: "Rescheduled!",
          text: "Your appointment has been rescheduled successfully",
          icon: "success",
          customClass: {
            container: "my-swal",
          },
        });
        await specialistWeeklyAppointments();
        setSelectedSlot("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialistBookingSlots = async (selectedDate: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "calendarDate",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: selectedDate,
                },
                {
                  field: "doctorId",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: specialistInfo.id,
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setSlots(result?.data?.data?.response);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    // Get month and day with padding because getMonth() returns 0-11 and getDate() can return 1-31
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const specialistDailyAppointments = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "calendarDate",
                  operator: "eq",
                  value: `${getCurrentDate()}`,
                  table: "BookingCalendar",
                },
                {
                  field: "isBooked",
                  operator: "eq",
                  value: 1,
                  table: "BookingCalendar",
                },
                {
                  field: "doctorId",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: specialistInfo.id,
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          setDailyAppointments(result?.data?.data?.response);
        } else if (result?.data?.data?.status === "failure") {
          return toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dates: any = [];
  const today = new Date();

  for (let i = 0; i < 7; i++) {
    const startDate = new Date(today);
    startDate.setDate(today.getDate() + i);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 1);

    const startYear = startDate.getFullYear();
    const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
    const startDay = String(startDate.getDate()).padStart(2, "0");

    const endYear = endDate.getFullYear();
    const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
    const endDay = String(endDate.getDate()).padStart(2, "0");

    dates.push({
      startDate: `${startYear}-${startMonth}-${startDay}`,
      endDate: `${endYear}-${endMonth}-${endDay}`,
    });
  }

  const specialistWeeklyAppointments = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "calendarDate",
                  operator: "between",
                  table: "BookingCalendar",
                  value: dates[0].startDate,
                  opr: "AND",
                  value2: dates[6].endDate,
                },
                {
                  field: "isBooked",
                  operator: "eq",
                  value: `1`,
                  table: "BookingCalendar",
                },
                {
                  field: "doctorId",
                  operator: "eq",
                  value: specialistInfo.id,
                  table: "BookingCalendar",
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          setWeeklyAppointments(result?.data?.data.response);
          selectedSlot("");
        } else if (result?.data?.data?.status === "failure") {
          return toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserOnboardedList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          action: "command",
          command: [
            {
              agent: "totalUsersOnbordingSP",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setOnboardedUsers(result?.data?.data[0].onboard);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const specialistTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeInSpecialistTimezone = moment
    .tz(specialistTimeZone)
    .format("HH:mm A");

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper overflow-auto">
        <Modal isOpen={open}>
          <div tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
              <div className="modal-content buy__product__modal py-3">
                <div className="modal-header py-2">
                  <p className="reschedule__available__title mb-0">
                    Available Slots: DR.{specialistInfo.name.toUpperCase()}
                  </p>
                  <button className="edit__profile mr-2 cancel__reschedule--popup">
                    <span
                      className="text-dark font-weight-bold add-btn"
                      onClick={handleClose}
                    >
                      <i className="fa fa-close"></i> CANCEL
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="mb-0 reschedule__please--select pb-3">
                    Please select your time slot before we process
                  </p>
                  <div>
                    <div className="reschedule__select--btn">
                      <DatePicker
                        className="reschedule__book__date"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        minDate={currentDate}
                        dateFormat="EEEE, yyyy/MM/dd" // customize date format
                        placeholderText="SELECT DATE"
                      />
                    </div>
                  </div>
                  <div className="row pb-2 pt-3">
                    {slots.length !== 0 && slots !== null ? (
                      slots.map((elem: any) => (
                        <div
                          className={` col-lg-4 col-md-6 col-sm-3 col-6 pb-4`}
                          onClick={() =>
                            elem.isBooked === 0 && handleSlots(elem)
                          }
                          key={elem.id}
                        >
                          <div
                            className={`${
                              elem.isBooked === 0
                                ? "available__time__duration"
                                : "booked_slot--red"
                            } p-2 ps-3 ${
                              selectedSlot === elem && elem.isBooked === 0
                                ? "white--color"
                                : "text--dark__green"
                            }`}
                          >
                            <span className="reschedule__available--booked d-block">
                              {elem.isBooked === 0 ? "Availability" : "Booked"}
                            </span>
                            <span
                              className={`d-block reschedule--time ${
                                selectedSlot === elem && elem.isBooked === 0
                                  ? "text-dark"
                                  : "text-white"
                              }`}
                            >
                              {specialistTimeZone &&
                                moment
                                  .tz(
                                    elem.StartTime,
                                    "hh:mm A",
                                    specialistTimeZone
                                  )
                                  .utc()
                                  .format("HH:mm A")}
                              -
                              {specialistTimeZone &&
                                moment
                                  .tz(
                                    elem.EndTime,
                                    "hh:mm A",
                                    specialistTimeZone
                                  )
                                  .utc()
                                  .format("HH:mm A")}
                            </span>
                            <span
                              className={`reschedule--time  ${
                                selectedSlot === elem && elem.isBooked === 0
                                  ? "text-dark"
                                  : "text-white"
                              }`}
                            >
                              {elem.Duration} HR
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="no__slots--available">
                        No slots available
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-right">
                      <button className="reschedule__book" onClick={bookSlot}>
                        BOOK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="container pb-3 pt-3 pt-lg-0">
          <div className="fittness-wrapper-background-image-one">
            <div className="container px-0">
              <div className="row">
                <div className="col-lg-5">
                  <div className="fittness-left-box p-3">
                    <p className="mb-0 please-select-the-day font-weight-bold font-18 text-uppercase">
                      Sessions
                    </p>
                    <p className="mb-0 email-city-registration-date font-12 text-uppercase">
                      Here is an overview of your session’s latest activity.
                    </p>
                  </div>
                  <div className="today-join-time-premimum-version-box p-4">
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <p className="mb-0 please-select-the-day pb-2 font-weight-bold font-16 text-uppercase">
                          Today
                        </p>
                      </div>
                      <div className="col-lg-12">
                        {dailyAppointments.length !== 0 &&
                        dailyAppointments !== null ? (
                          dailyAppointments.map((elem: any) => (
                            <div
                              className="row join-reshedule-time-premimum-version-box py-3 mb-2"
                              key={elem.uuid}
                            >
                              <div className="col-8">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="row px-0 d-flex align-items-center">
                                      <div className="col-4">
                                        <p className="start-time-wrapper_one mb-0 text-center">
                                          <span className="font-12 font-weight-bold">
                                            {moment
                                              .tz(
                                                elem.StartTime.replace(
                                                  /:000Z$/,
                                                  ":00Z"
                                                ),
                                                "YYYY-MM-DDTHH:mm:ssZ",
                                                "UTC"
                                              )
                                              .tz(specialistTimeZone)
                                              .format("HH:mm A")}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="col-8 px-0">
                                        <p className="mb-0 trainer-name-degree-name">
                                          <span className="mb-0 font-12 d-block">
                                            {elem.name
                                              ? elem.name.toUpperCase()
                                              : elem.name}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 text-right">
                                <p
                                  onClick={() => {
                                    const currentTimeInUserTimezone =
                                      moment.tz(specialistTimeZone); // Current time in user's timezone

                                    if (elem?.StartTime) {
                                      const startTimeInUserTimezone = moment
                                        .tz(
                                          elem.StartTime.replace(
                                            /:000Z$/,
                                            ":00Z"
                                          ),
                                          "YYYY-MM-DDTHH:mm:ssZ",
                                          "UTC"
                                        )
                                        .tz(specialistTimeZone);

                                      if (
                                        currentTimeInUserTimezone.isSameOrAfter(
                                          startTimeInUserTimezone
                                        )
                                      ) {
                                        window.location.href =
                                          `${process.env.REACT_APP_LOCALHOST_URL_VIDEO}` +
                                          elem?.meetingId +
                                          "/" +
                                          specialistInfo?.name;
                                      } else {
                                        toast.info(
                                          "MEETING IS NOT YET STARTED"
                                        );
                                      }
                                    } else {
                                      toast.error("START TIME NOT AVAILABLE");
                                    }
                                  }}
                                  className="text-success font-12 font-weight-bold cursor_pointer text-uppercase"
                                >
                                  Join
                                </p>

                                <button
                                  className="reshedule-text font-12 text-white ml-2"
                                  onClick={() => handleOpen(elem.uuid)}
                                >
                                  Reschedule
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="row">
                            <div className="col-lg-12 px-0">
                              <span className="not__available text-uppercase">
                                No Data Available
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row pt-2">
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="total-customer-onboard-wrapper p-2">
                        <p className="mb-0 total-customer-onboard-text font-9 pb-2 font-weight-bold text-uppercase">
                          Total customer Onboarded
                        </p>
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <p className="total-customer-onboard-amount text-white font-18 mb-0 font-weight-bold">
                                {onboardedUsers.length !== 0 && onboardedUsers}
                              </p>
                              <i className="fa fa-arrow-up down__arrow--icon1 pl-1"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 px-0">
                  <div className="fittness-right-box p-4 fittness__right--box this__week__app__spec--dashboard">
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <p className="mb-0 please-select-the-day font-weight-bold this__week__appointments text-uppercase">
                          This week appointments
                        </p>
                        <p className="mb-0 email-city-registration-date font-12 pb-3 text-uppercase">
                          Join the session and make the most out of it
                        </p>
                      </div>
                    </div>
                    <div className="row doctor-dashboard-wrapper mb-3">
                      <div className="col-lg-12">
                        {weeklyAppoinments.length !== 0 &&
                        weeklyAppoinments !== null ? (
                          weeklyAppoinments.map((elem: any) => (
                            <div
                              className="row doctor-dashboard_reshedule-box p-3 position-relative mb-3"
                              key={elem.uuid}
                            >
                              <div className="col-8 border-left_new ">
                                <h2 className="mb-0 doctor-name font-14 pb-3">
                                  {elem.name
                                    ? elem.name.toUpperCase()
                                    : elem.name}
                                </h2>
                                <p className="mb-0 date-time-day-wrapper d-inline-block p-2 font-14 ">
                                  <span className="appoinments-booked-time font-weight-bold 1234">
                                    {specialistTimeZone && elem?.StartTime
                                      ? moment
                                          .tz(
                                            elem.StartTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            specialistTimeZone
                                          )
                                          .format("hh:mm A")
                                      : "Date Not Available"}
                                    -{" "}
                                    {specialistTimeZone && elem?.StartTime
                                      ? moment
                                          .tz(
                                            elem.EndTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            specialistTimeZone
                                          )
                                          .format("hh:mm A")
                                      : "Date Not Available"}
                                    |
                                  </span>
                                  <span className="appoinments-booked-day font-weight-bold pl-2">
                                    {specialistTimeZone && elem?.StartTime
                                      ? moment
                                          .tz(
                                            elem.StartTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            specialistTimeZone
                                          )
                                          .format("YYYY-MM-DD")
                                      : "Date Not Available"}{" "}
                                    |
                                    {specialistTimeZone && elem?.StartTime
                                      ? moment
                                          .tz(
                                            elem.StartTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            specialistTimeZone
                                          )
                                          .format("dddd")
                                      : "Date Not Available"}
                                  </span>
                                </p>
                              </div>
                              <div className="col-4 text-right">
                                <button
                                  className="reshedule-link font-14"
                                  onClick={() => handleOpen(elem.uuid)}
                                >
                                  Reschedule
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="row">
                            <div className="col-lg-12 px-0">
                              <span className="not__available text-uppercase">
                                No Appointments Available
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SpecialistBody;
