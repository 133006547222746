import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import admin_logo from "../../../src/img/content/logo/admin-logo.png";
import AuthContext from "../../authContext";

interface SidebarProps {
  isActive: boolean;
}

const SpecialistSidebar: React.FC<SidebarProps> = ({ isActive }) => {
  const authCtx = useContext(AuthContext);

  interface SubMenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
  }

  interface MenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
    subMenu: SubMenuItem[];
  }
  const [menuData, setMenuData] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (authCtx.userMenu) {
      if (typeof authCtx.userMenu === "string") {
        setMenuData(JSON.parse(authCtx.userMenu));
      } else {
        // If it's already an object, directly use it
        setMenuData(authCtx.userMenu);
      }
    }
  }, [authCtx.userMenu]); // Add authCtx.userMenu as a dependency

  const goToWebsite = () => {
    window.location.href = "/";
    return;
  };

  return (
    <aside
      className={`sidebar ${
        isActive ? "sidebar--show sidebar--translate" : ""
      }`}
    >
      <div className="sidebar__container">
        <div className="sidebar__top py-5 my-5">
          <div className="container container--sm">
            <Link
              to={""}
              className="sidebar__logo d-flex justify-content-center"
            >
              <img
                className="sidebar__logo-icon"
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                alt="#"
                style={{ width: "auto", height: "auto" }}
                loading="lazy"
                onClick={goToWebsite}
              />
            </Link>
          </div>
        </div>
        <div className="sidebar__content" data-simplebar="data-simplebar">
          <nav className="sidebar__nav">
            <ul className="sidebar__menu">
              {menuData.map((elem: MenuItem) => (
                <>
                  <Link
                    to={
                      elem.menu === "Dashboard" ? "" : elem.menu.toLowerCase()
                    }
                    key={elem.id}
                  >
                    <li className="sidebar__menu-item">
                      <Link
                        to={`${
                          elem.link === "/Analytics"
                            ? "/specialistanalytics"
                            : elem.link
                        }`}
                        className="sidebar__link active"
                        aria-expanded="true"
                      >
                        <span className="sidebar__link-icon">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            {" "}
                            <path d="M17.4 5H4.6C3.72 5 3 5.675 3 6.5V15.5C3 16.325 3.72 17 4.6 17H17.4C18.28 17 19 16.325 19 15.5V6.5C19 5.675 18.28 5 17.4 5ZM6.6 6.3125C6.9312 6.3125 7.2 6.5645 7.2 6.875C7.2 7.1855 6.9312 7.4375 6.6 7.4375C6.2688 7.4375 6 7.1855 6 6.875C6 6.5645 6.2688 6.3125 6.6 6.3125ZM4.4 6.875C4.4 6.5645 4.6688 6.3125 5 6.3125C5.3312 6.3125 5.6 6.5645 5.6 6.875C5.6 7.1855 5.3312 7.4375 5 7.4375C4.6688 7.4375 4.4 7.1855 4.4 6.875ZM17.4 15.5H4.6V8.75H17.4V15.5ZM17.4 7.25H7.8V6.5H17.4152L17.4 7.25Z" />
                          </svg> */}
                        </span>
                        <span className="sidebar__link-text text-uppercase">
                          {elem.menu}
                        </span>
                      </Link>
                    </li>
                  </Link>
                </>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default SpecialistSidebar;
