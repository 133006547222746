import React, { useContext, useEffect, useState } from "react";
import admin_logo from "../../../src/img/content/logo/admin-logo.png";
import AuthContext from "../../authContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const UserTermsAndConditions = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [policies, setPolicies] = useState<any>([]);
  const [specialistPolicies, setSpecialistPolicies] = useState<any>([]);
  const [policiesArray, setPoliciesArray] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState<any>(0);
  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const handleCheckboxChange = (index: any) => {
    setPoliciesArray((prevState: any) => {
      if (prevState.includes(index)) {
        return prevState.filter((item: any) => item !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const getPolicies = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
        action: "command",
        command: [
          {
            agent: "getTermsAndConditions",
            appName: "selfMaximized",
            folder: "policies",
          },
        ],
      },
      {
        headers: {
          'x-access-token': authContext.token
        }
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setPolicies(result?.data?.data);
          setSpecialistPolicies(
            result?.data?.data?.filter((item: any) => item.pageDataUser)
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  const createUserPolicy = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          user_id: specialistInfo.uuid,
          terms_and_conditions: 1,
          privacy_policy: 1,
          cancellation_policy: 1,
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "createUserPolicies",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success(result?.data?.message + `${specialistInfo.name}`);
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getPolicies();
  }, []);

  const handleNextClick = () => {
    setPolicies((prevArray: any) => {
      const filteredPolicies: any = prevArray.filter(
        (item: any) => item.pageDataUser
      );
      if (filteredPolicies.length > 0) {
        setCurrentSlide((prevSlide: any) => {
          const nextSlide = (prevSlide + 1) % filteredPolicies.length;
          return nextSlide;
        });
      }
      return filteredPolicies;
    });
  };

  const handlePrevClick = () => {
    setPolicies((prevState: any) => {
      const filteredPolices = prevState.filter(
        (item: any) => item.pageDataUser
      );

      if (filteredPolices.length > 0) {
        setCurrentSlide((prevSlide: any) => {
          const prevSlideIndex =
            (prevSlide - 1 + filteredPolices.length) % filteredPolices.length;
          return prevSlideIndex;
        });
      }
      return filteredPolices;
    });
  };

  const decodeHTML = (html: any) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };
  return (
    <div>
      <body>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <img
              src={admin_logo}
              className="img-fluid admin__logo"
              alt="logo"
              style={{
                width: "auto",
                height: "auto",
                paddingLeft: "40px",
                marginTop: "20px",
              }}
              loading="lazy"
            />
          </div>

          <div
            id="carouselExampleControls"
            className="carousel"
            data-interval="false"
          >
            <div className="carousel-inner">
              {policies
                .filter((elem: any) => elem.pageDataUser !== "")
                .map((elem: any, index: any) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <div className="pl-5 pt-4">
                      <h2
                        style={{
                          fontFamily: "Helvetica, Arial, sans-serif",
                          fontWeight: "200",
                          marginBottom: "20px",
                          marginTop: "10px",
                          marginLeft: "18px",
                        }}
                      >
                        {elem.pageName}
                      </h2>
                      <iframe
                        srcDoc={`
                          
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>${elem.pageName}</title>
              <style>
                body {
                  color: white;
                  margin: 0;
                  padding: 20px;
                  background-color: transparent;
                  letter-spacing: 1px;
                  font-family: Helvetica, Arial, sans-serif;
                  font-size: 18px;
                }
              </style>
            </head>
            <body>
              ${decodeHTML(elem.pageDataUser)}
            </body>
            </html>
          `}
                        style={{
                          width: "100%",
                          height: "300px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      ></iframe>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="form-check mb-3 mt-5 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center">
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "-18px" }}
            >
              <div>
                <label
                  className="custom-checkbox term-and-condition-checkbox"
                  style={{ marginLeft: "60px" }}
                >
                  <input
                    type="checkbox"
                    className="cursor_pointer"
                    checked={policiesArray.includes(
                      policies &&
                        policies.length > 0 &&
                        policies[currentSlide].id
                    )}
                    onChange={() => {
                      handleCheckboxChange(
                        policies &&
                          policies.length > 0 &&
                          policies[currentSlide].id
                      );
                    }}
                  />
                  <span className="checkmark cursor_pointer"></span>
                </label>

                <label className="form-check-label text-white ml-2">
                  I agree to all{" "}
                  {policies &&
                    policies.length > 0 &&
                    policies[currentSlide].pageName.toLowerCase()}{" "}
                  to proceed.
                </label>
                <br />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
            <div className="d-flex justify-content-end align-items-center">
            {specialistPolicies.length > 1 ? (
              <nav aria-label="Page navigation example">
                <ul className="paginationOne mb-0 mr-4 d-flex items-center">
                  <li className="page-item">
                    <a
                      className="page-link"
                      href="#carouselExampleControls"
                      data-slide="prev"
                      style={{ color: "black" }}
                      onClick={handlePrevClick}
                    >
                      Previous
                    </a>
                  </li>

                  <li className="page-item">
                    <a
                      href="#carouselExampleControls"
                      className="page-link"
                      style={{ color: "black" }}
                      data-slide="next"
                      onClick={handleNextClick}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            ) : null}

            <button
              className="btn proceed-btn"
              onClick={createUserPolicy}
              disabled={
                specialistPolicies.length === policiesArray.length
                  ? false
                  : true
              }
            >
              PROCEED
            </button>
          </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default UserTermsAndConditions;
