import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../../../authContext";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ModifiedSettingsProps {
  data: React.ReactNode;
  name: string;
  profile: any;
}

const Setting: React.FC<ModifiedSettingsProps> = ({ data, name, profile }) => {
  const [fileResponse, setFileResponse] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [changePassword, setChangePassword] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const authContext = useContext(AuthContext);

  const handleChangePassword = (event: any) => {
    const { name, value } = event.target;
    setChangePassword({ ...changePassword, [name]: value });
  };

  const uploadImage = async () => {
    if (!fileResponse) {
      return toast.warning("Select file first");
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          profile: fileResponse.data.url,
          action: "command",
          command: [
            {
              agent: "updateUserProfilePhoto",
              appName: "selfMaximized",
              folder: "admin",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setFileResponse("");
          return toast.success("File uploaded successfully");
        } else {
          return toast.info(result.data.message);
        }
      })
      .catch((err) => {
        return toast.error("Seomthing went wrong", err);
      });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick = () => {
    if (fileInputRef) {
      fileInputRef.current?.click();
    }
  };

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        event.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");
    setLoader(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authContext.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setLoader(false);
    await setFileResponse(response?.data);
  };

  const updatePassword = async () => {
    if (fileResponse) {
      uploadImage();
    }

    if (
      fileResponse &&
      !changePassword.oldPassword &&
      !changePassword.newPassword &&
      !changePassword.confirmPassword
    ) {
      return;
    }

    if (
      !changePassword.oldPassword ||
      !changePassword.newPassword ||
      !changePassword.confirmPassword
    ) {
      if (
        !changePassword.oldPassword ||
        !changePassword.newPassword ||
        !changePassword.confirmPassword
      ) {
        return toast.warning(
          `Please enter ${
            !changePassword.oldPassword
              ? "old password"
              : !changePassword.newPassword
              ? "new password"
              : "confirm password"
          }`
        );
      }
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          oldPassword: changePassword.oldPassword,
          password: changePassword.newPassword,
          confirmPassword: changePassword.confirmPassword,
          name: name,
          action: "command",
          command: [
            {
              agent: "changePassword",
              appName: "selfMaximized",
              folder: "auth",
            },
            {
              agent: "changePasswordEmail",
              appName: "selfMaximized",
              folder: "auth",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setChangePassword({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          setFileResponse("");
          return toast.success(result?.data?.message);
        } else {
          return toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  return (
    <>
      <main className="page-content doctot-detail-user_wrapper overflow-auto">
        <div className="container pb-3 pt-5 pt-lg-0">
          <div className="row">
            <div className="col-lg-12 text-right px-0 pb-3">
              <button className="edit__profile" onClick={updatePassword}>
                <span className="text-dark font-weight-bold add-btn">
                  <i className="fa fa-save pr-2"></i>SAVE
                </span>
              </button>
            </div>
          </div>
          <div className="row profile__wrapper--container py-3 d-flex justify-content-center align-items-center">
            <div className="col-lg-3 pb-3 d-flex justify-content-center ">
              <div className="wrapper setting__upload--image">
                {loader ? (
                  <span className="spinner-border"></span>
                ) : (
                  <>
                    {" "}
                    <button
                      className="no-image"
                      id="img-result"
                      onClick={handleTriggerClick}
                      style={{
                        display: `${
                          fileResponse || profile.profile ? "none" : "block"
                        }`,
                      }}
                    >
                      Upload Image
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                    </button>
                    <img
                      src={`${
                        fileResponse
                          ? fileResponse.data.url
                          : profile
                          ? profile.profile
                          : null
                      }`}
                      style={{
                        borderRadius: "50%",
                        width: "65%",
                        aspectRatio: "1/1",
                        display: `${
                          fileResponse || profile.profile ? "block" : "none"
                        }`,
                        marginLeft: "30px",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ marginLeft: "150px", cursor: "pointer" }}
                      onClick={handleTriggerClick}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="form-group mb-0">
                {data && <div>{data}</div>}

                <div className="pb-3">
                  <label
                    htmlFor="exampleFormControlInput2 mb-0"
                    className="mb-2 text-uppercase"
                    id="changePassword"
                  >
                    Old Password:
                  </label>
                  <input
                    type="password"
                    className="form-control w-100 text-uppercase"
                    id="changePassword"
                    name="oldPassword"
                    placeholder="Please enter old password"
                    autoComplete="off"
                    value={changePassword.oldPassword}
                    onChange={handleChangePassword}
                  />
                </div>
                <div className="pb-3">
                  <label
                    htmlFor="exampleFormControlInput2 mb-1 mt-2"
                    className="mb-2 text-uppercase"
                    id="changePassword"
                  >
                    New Password:
                  </label>
                  <input
                    type="password"
                    className="form-control w-100 text-uppercase"
                    id="changePassword"
                    name="newPassword"
                    placeholder="Please enter new password"
                    autoComplete="off"
                    value={changePassword.newPassword}
                    onChange={handleChangePassword}
                  />
                </div>
                <div>
                  <label
                    htmlFor="exampleFormControlInput2 mb-1 mt-2"
                    className="mb-2 text-uppercase"
                    id="changePassword"
                  >
                    Confirm Password:
                  </label>
                  <input
                    type="password"
                    className="form-control w-100 text-uppercase"
                    id="changePassword"
                    name="confirmPassword"
                    placeholder="Please enter confirm password"
                    autoComplete="off"
                    value={changePassword.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Setting;
