import React, { ReactNode, useEffect } from "react";

interface ModalProps {
    isOpen: boolean;
    children: ReactNode;
}

const Modal : React.FC<ModalProps> = ({ isOpen, children  }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            return () => {
                document.body.style.overflow = 'unset';
            };
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className=""
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                opacity:1,
                justifyContent: "center",
                zIndex:9999,
                backgroundColor: "#707070",
                overflowY: 'auto'
            }}
        >
            <div
                style={{
                    // height: 250,
                   
                    margin: "auto",
                    padding: "2%",
                    borderRadius: "10px",
                    boxShadow: "2px solid black",
                    opacity:1,
                    zIndex: 99999999,
                }}
            >
                {children}
            </div>
        </div>
    )
};

export default Modal;