import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import NoDataFoundImage from "../../../../../src/img/content/no_data_available/no-data-found.png";

const SingleCategoryDetails: React.FC = () => {
  const location = useLocation();
  const [showFullDescription, setShowFullDescription] = useState<any>(false);
  const categoryId = location.state?.categoryId;
  const authCtx = useContext(AuthContext);
  const [categoryAndDrData, setCategoryAndDrData] = useState<any>([]);
  const { id } = useParams();

  console.log(id);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getCategoryAndDrList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 10,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 1,
                },
                {
                  field: "uuid",
                  operator: "contain",
                  value: `${categoryId || id}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
            {
              agent: "categoryDoctorList",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        console.log(result);
        if (result?.data?.status === "failed") {
          toast.error("Something went wrong");
          return false;
        } else {
          setCategoryAndDrData(result?.data?.data[0]);
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong, ${err}`);
        return false;
      });
  };

  const getData = useCallback(getCategoryAndDrList, [
    authCtx.token,
    categoryId,
  ]);

  useEffect(() => {
    getData();
  }, [getData, id]);

  // console.log(categoryAndDrData.description.length);
  if (!categoryAndDrData || !categoryAndDrData.description) {
    return null; // or display a placeholder/error message
  }

  // Extracting first 2-3 lines from the description
  const categoryDescription = categoryAndDrData.description
    .split("\n")
    .join("\n");
  const truncatedDescription = categoryAndDrData.description
    .replace(/\n/g, " ") // Replace newline characters with spaces
    .split(". ") // Split the text into an array of sentences
    .slice(0, 3) // Take the first three sentences
    .join(". "); // Join the sentences back together with periods

  return (
    <>
      <main className="page-content overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row pb-4">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 text-right">
              <input type="checkbox" id="switch3" />
              <label
                htmlFor="switch3"
                data-on-label="Active"
                data-off-label="Inactive"
              ></label>
            </div>
          </div>
        </div>
        <div className="container categories_image_heading">
          <div className="row pb-4">
            <div className="col-lg-12 position-relative px-0">
              <div className="single__categories__card__image">
                <div className="thumb">
                  <img
                    src={categoryAndDrData.image}
                    alt=""
                    className="img-fluid"
                    style={{ width: "100%", height: "100px" }}
                  />
                </div>
                <div className="content">
                  <h1 className="mb-0 single__category--title">
                    {categoryAndDrData.name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container categories_description pb-5">
          <div className="row py-4 px-3">
            <div className="col-lg-12">
              <h1 className="mb-0 pb-3">{categoryAndDrData.name}</h1>
              <p className="mb-0 font-14 text-uppercase">
                {showFullDescription
                  ? categoryDescription
                  : truncatedDescription}
              </p>
              {categoryDescription.length > truncatedDescription.length && (
                <button onClick={toggleDescription}>
                  {showFullDescription ? (
                    <span className="font-14 view__more__less text-primary text-uppercase">
                      Less
                    </span>
                  ) : (
                    <span className="font-14 view__more__less text-primary text-uppercase">
                      More
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container specialist-related_wrapper">
          <div className="row pb-4">
            <div className="col-lg-6 pl-0 pb-3 pb-lg-0">
              <h1 className="mb-0">
                SPECIALIST RELATED TO {categoryAndDrData.name}
              </h1>
            </div>
            {categoryAndDrData.length !== 0 && categoryAndDrData !== null ? (
              <div className="col-lg-6 text-right" key={categoryAndDrData.id}>
                <Link
                  to={"/dashboard/categories/allspecialists"}
                  className="view--all--categories text-uppercase"
                  state={id}
                >
                  View
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row pb-4">
            {categoryAndDrData.length !== 0 &&
              categoryAndDrData !== null &&
              categoryAndDrData.doctorList.map((elem: any) => (
                <div
                  className="col-lg-2 col-md-4 col-4 pb-4 pl-0"
                  key={elem.id}
                >
                  {elem.profile ? (
                    <div className="specialist__related__images__single--categories--image">
                      <img
                        src={elem.profile}
                        alt=""
                        className="img=fluid"
                        style={{ width: "450px", height: "auto" }}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        src={NoDataFoundImage}
                        alt=""
                        className="img-fluid"
                        style={{ width: "auto", height: "auto" }}
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default SingleCategoryDetails;
