import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

const RatingPage: React.FC = () => {
  const [rating, setRatingValue] = useState<any>(0);
  const [todaysBooking, setTodaysBooking] = useState<any>([]);
  const [rateText, setRateText] = useState<any>("");
  const handleRatingChange = (value: any) => {
    setRatingValue(value);
  };
  const userInfo: any = localStorage.getItem("userInfo");
  const userMenu: any = localStorage.getItem("userMenu");
  const token: any = localStorage.getItem("token");
  const hash = window.location.hash; // Get everything after the #
  const queryString = hash.split("?")[1]; // Extract the part after '?'
  const urlParams = new URLSearchParams(queryString);
  const navigate = useNavigate();

  const userRole: any =
    typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
  const userMenuData =
    typeof userMenu === "string" ? JSON.parse(userMenu) : userMenu;

  const dashboardMenuItem: any = userMenuData.find(
    (item: any) => item.menu === "Dashboard"
  );

  const getDailyAppointments = async () => {
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      // Get month and day with padding because getMonth() returns 0-11 and getDate() can return 1-31
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: "2024-11-15",
                opr: "AND",
                value2: "2024-11-22",
              },
              {
                field: "uuid",
                table: "Users",
                operator: "eq",
                value: userRole.uuid,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "f68ae19c-352a-4da5-bf72-6b0e31064e01",
        action: "command",
        command: [
          {
            agent: "userBooking",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      },
      {
        headers: { "x-access-token": `${token}` },
      }
    );

    const newData = response.data.data;
    setTodaysBooking(newData);
  };

  const arrayIncludesMeetingIdObj: any =
    todaysBooking.length !== 0 && todaysBooking !== null
      ? todaysBooking.find((elem: any) => {
          if (elem.meetingId === urlParams.get("meetingid")) {
            return elem;
          }
        })
      : null;

  console.log(arrayIncludesMeetingIdObj);
  useEffect(() => {
    getDailyAppointments();
  }, []);

  const giveRating = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          message: rateText,
          rate: rating,
          userId: userRole.id,
          doctorId: arrayIncludesMeetingIdObj.doctorId,
          categoryId: arrayIncludesMeetingIdObj.categoryId,
          action: "command",
          command: [
            {
              agent: "userStarRating",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setRateText("");
          setRatingValue(0);
          navigate("/dashboard");
          return toast.success("THANK YOU FOR YOUR FEEDBACK");
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  return (
    <main className="page-content overflow-auto">
      <div className="container">
        <div className="row rating__wrapper py-5">
          <div className="col-lg-12 py-3">
            <div className="rating-container">
              <div className="">
                <h4 className="how__you__rate rate__size text-uppercase">
                  How would you rate your overall experience meeting with Dr.?
                </h4>
              </div>
              <div className="stars">
                <Rating
                  onPointerMove={handleRatingChange}
                  allowFraction
                  initialValue={rating}
                />
              </div>
              <h5 className="how__you__rate rate--description">
                Did the doctor explain your diagnosis and treatment plan in a
                way that was easy to understand?
              </h5>
              <h5 className="how__you__rate rate--description">
                If you have any suggestions for how we can improve our services,
                kindly type in the feedback section given below.
              </h5>
              <textarea
                className="form-control message-field text-uppercase"
                rows={4}
                placeholder="Enter your feedback here..."
                onChange={(e) => {
                  setRateText(e.target.value);
                }}
                value={rateText}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-12 text-right" onClick={giveRating}>
            <button className="submit__feedback">SUBMIT</button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RatingPage;
