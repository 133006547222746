import React from "react";

const Footer = () => {
  return (
    <div className="footer-width">
      <footer>
        <div className="container">
          <div>
            <div className="row p-2 px-2">
              <div className="col-lg-6">
                <p className="mb-0 company-name font-14">@Selfmaximized</p>
              </div>
              <div className="col-lg-6">
                <p className="mb-0 text-right all-right-reserved font-14">
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
