import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../authContext";
import Shimmer from "../../Shimmer";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";

const Categories: React.FC = () => {
  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<any>("all");
  const [catListCopy, setCatListCopy] = useState<any>("");
  const [shimmerLoader, setShimmerLoader] = useState<any>("");
  const [catFilters, setCatFilters] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [page, setPage] = useState<any>(1); // Track current page number

  const handleCategoryList = (type: any) => async (event: any) => {
    event.preventDefault();
    let filters = [];
    let pageNumber = 1;
    setCategoryList([]);
    setCatListCopy([]);
    setPage(1);
    if (type === "active") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 1,
      });
    } else if (type === "inactive") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 0,
      });
    } else if (type === "all") {
      filters = [];
    }

    setCheckedState(type);
    setCatFilters(filters);
    setPage(pageNumber);
  };

  const goToCategoryDetailsPage = (categoryId: any) => {
    navigate(`/dashboard/categories/categorydetails/${categoryId}`, {
      state: { categoryId },
    });
  };

  const authCtx = useContext(AuthContext);

  const goToAddCategoriesPage = () => {
    navigate("addcategories");
  };

  const getCategories = async (filters: any, pageNumber: any) => {
    if (pageNumber === 1) {
      setShimmerLoader(true);
    }
    setLoader(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            filters: filters,
            offset: (pageNumber - 1) * 12,
            limit: 12,
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );

    const newData = response.data.data;
    setCatListCopy(newData);
    setCategoryList((prevData: any) => [...prevData, ...newData]); // Append new data to existing data
    setLoader(false);
    if (pageNumber === 1) {
      setShimmerLoader(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    setLoader(true);
  };

  const getMorePosts = () => {
    if (catListCopy.length !== 0) {
      setTimeout(() => {
        setPage((page: any) => page + 1);
        getCategories(catFilters, page + 1);
      }, 300);
    } else {
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getCatCB = useCallback(() => {
    getCategories(catFilters, 1);
  }, [catFilters]);

  useEffect(() => {
    getCatCB();
  }, [getCatCB]);

  const getMorePostsCB = useCallback(() => {
    getMorePosts();
  }, [loader]);

  useEffect(() => {
    if (!loader) return;
    getMorePostsCB();
  }, [loader, getMorePostsCB]);

  return shimmerLoader ? (
    <Shimmer />
  ) : (
    <main
      className="page-content categories-wrpper user_wrapper overflow-auto"
      onScroll={handleScroll}
    >
      <div className="container pt-3 pt-lg-0">
        <div className="row">
          <div className="col-lg-12 px-0">
            <ul
              className="nav nav-pills my__bookings__tabs justify-content-sm-between"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" onClick={handleCategoryList("all")}>
                <Link
                  to={"#pills-all"}
                  className={`nav-link ${
                    checkedState === "all" ? "active" : ""
                  }  mr-4`}
                  id="pills-all-tab"
                  data-toggle="pill"
                  aria-controls="pills-all"
                  aria-selected="true"
                >
                  ALL
                </Link>
              </li>
              <li className="nav-item" onClick={handleCategoryList("active")}>
                <Link
                  to={"#pills-active"}
                  className={`nav-link ${
                    checkedState === "active" ? "active" : ""
                  } mr-4`}
                  id="pills-active-tab"
                  data-toggle="pill"
                  aria-controls="pills-active"
                  aria-selected="false"
                >
                  MOST BOOKED
                </Link>
              </li>
              <li className="nav-item" onClick={handleCategoryList("inactive")}>
                <Link
                  to={"#pills-inactive"}
                  className={`nav-link ${
                    checkedState === "inactive" ? "active" : ""
                  }`}
                  id="pills-inactive-tab"
                  data-toggle="pill"
                >
                  INACTIVE
                </Link>
              </li>
            </ul>
            {/* New Tab Content */}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-all"
                role="tabpanel"
                aria-labelledby="pills-all-tab"
              >
                <div className="tab">
                  <div className="container mb-0 px-0">
                    <div className="row pb-4" onClick={goToAddCategoriesPage}>
                      <div className="col-lg-12 d-flex justify-content-end pt-3 pt-lg-0">
                        <button className="categories__add-btn rounded-0 add-btn btn__border--radius">
                          <svg
                            className="icon-icon-plus mr-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                            />
                          </svg>
                          <span className="text-dark font-weight-bold add-btn">
                            ADD
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="container pb-4 px-0">
                    <div className="row">
                      {categoryList !== null && categoryList.length !== 0 ? (
                        categoryList.map((elem: any) => (
                          <div
                            className="col-lg-4 col-md-6 pb-4 text-center cursor-pointer"
                            key={elem.id}
                            onClick={() => goToCategoryDetailsPage(elem.uuid)}
                          >
                            <div className="categories__thumb__content__card position-relative">
                              <div className="thumb">
                                <img
                                  src={elem.image}
                                  alt=""
                                  className="img-fluid"
                                  style={{ width: "100%", height: "100px" }}
                                  loading="lazy"
                                />
                              </div>
                              <div className="content ">
                                <h1 className="mb-0 category--title">
                                  {elem.name}
                                </h1>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-lg-12">
                          <img
                            src={noDataFoundImage}
                            alt=""
                            className="img-fluid user__all__active__inactive__card--image"
                            style={{ width: "auto", height: "500px" }}
                            loading="lazy"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Categories;
