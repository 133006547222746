import axios from "axios";
import React, { useContext, useState } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateFaq = () => {
  const authContext = useContext(AuthContext);
  const [faq, setFaq] = useState<any>({ title: "", description: "" });
  const navigate = useNavigate();

  const createFaq = async () => {
    if (faq.title === "") {
      return toast.warning("Please add question first");
    }
    if (faq.description === "") {
      return toast.warning("Please add answer first");
    }
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          data: {
            title: `${faq.title}`,
            description: ` ${faq.description}`,
            meta: "{}",
          },
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "createFAQ",
              appName: "selfMaximized",
              folder: "faq",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success(result?.data?.message);
          setFaq({ title: "", description: "" });
          navigate("/dashboard/faq_List");
        } else {
          return;
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const handleFaq = async (e: any) => {
    setFaq({ ...faq, [e.target.name]: e.target.value });
  };
  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
              <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                <button
                  type="button"
                  className="font-weight-bold publish-btn magazine--btn"
                  onClick={createFaq}
                >
                  PUBLISH
                </button>
              </div>
              <section
                className="tabs-wrapper magazine_wrapper-tabs"
                id="magazine_wrapper-view_more"
              >
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs edit__blog__tab">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="">
                        <span className="material-icons text-uppercase">
                          Create Faq
                        </span>{" "}
                      </label>
                      <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                        <div className="row mb-0">
                          <div className="col-lg-12 px-0">
                            <label
                              htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                              id="blogTitle text-white blog__title--edit"
                              className="text-uppercase pb-0"
                            >
                              Question
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 pl-0 pr-0 pr-lg-3">
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase"
                                id="blogTitle"
                                name="title"
                                onChange={handleFaq}
                                value={faq.title.toUpperCase()}
                                autoComplete="off"
                              />
                              <span className="text-danger mt-4 error--message"></span>
                            </div>
                            <div className="form-group mb-4 mb-md-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                Answer
                              </label>
                              <textarea
                                className="form-control text-uppercase"
                                onChange={handleFaq}
                                id="exampleFormControlTextarea1"
                                rows={4}
                                name="description"
                                value={faq.description.toUpperCase()}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateFaq;
