import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthContext from "../authContext";

const useSpecialistProfile = (token: string | null) => {
  const [specialistProfile, setSpecialistProfile] = useState<any>([]);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    getSpecialistProfile();
  }, [token]);

  const getSpecialistProfile = async () => {
    axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          command: JSON.stringify([
            {
              agent: "getSpecialistProfile",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ]),
        },
        headers: { "x-access-token": authContext.token },
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setSpecialistProfile(result?.data?.data[0]);
        } else {
          setSpecialistProfile([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return specialistProfile;
};

export default useSpecialistProfile;
