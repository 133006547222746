import React from 'react';
import { CChart } from '@coreui/react-chartjs';

interface BarGraphData {
  categoryCount : any[]
}
const BarChart:React.FC<BarGraphData> = ({categoryCount}) => {
  
  const categoryNamesArray : any= categoryCount.map((elem : any) => {
    return elem.name;
  });

  const categoryCountArray : any= categoryCount.map((elem : any) => {
    return elem.count
  })

    return (
      <CChart
          type='bar'
          data={{
              labels: categoryNamesArray,
              datasets: [
                  {
                      label: 'CATEGORIES',
                      backgroundColor: 'rgba(153, 102, 255, 0.2)',
                      borderColor: 'rgba(179,181,198,1)',
                      pointBackgroundColor: 'rgba(179,181,198,1)',
                      pointBorderColor: '#fff',
                      pointHoverBackgroundColor: '#fff',
                      pointHoverBorderColor: 'rgba(179,181,198,1)',
                      data: categoryCountArray
                  }
              ],
          }}  
          options={{
              aspectRatio: 1.5,
              plugins: {
                  tooltip: {
                      enabled: true,
                  }
              }
          }}
      /> 
)
  };
  
  export default BarChart;
