import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../authContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const MoneyManagement: React.FC = () => {
  // const [bankDetails, setBankDetails] = useState<any>([]);
  const [totalAppointments, setTotalAppointments] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);

  const authContext = useContext(AuthContext);
  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  // const getBankDetails = async () => {
  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_API}/pipeline`,
  //       {
  //         filter: {
  //           filter: {
  //             logic: "and",
  //             offset: 0,
  //             limit: 50,
  //             sort: [
  //               {
  //                 field: "id",
  //                 dir: "desc",
  //               },
  //             ],
  //           },
  //         },
  //         action: "command",
  //         command: [
  //           {
  //             agent: "bankAccountList",
  //             appName: "selfMaximized",
  //             folder: "specialist",
  //           },
  //         ],
  //       },
  //       {
  //         headers: { "x-access-token": `${authContext.token}` },
  //       }
  //     )
  //     .then((result) => {
  //       if (result?.data?.statusCode === 200) {
  //         setBankDetails(result?.data?.data);
  //         return toast.success(result?.data?.message);
  //       } else {
  //         setBankDetails([]);
  //       }
  //     })
  //     .catch((err) => {
  //       return toast.error("Something went wrong", err);
  //     });
  // };

  useEffect(() => {
    specialistTotalAppointments();
  }, [authContext.token]);

  const specialistTotalAppointments = async () => {
    const dates: any = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const startDate = new Date(today);
      startDate.setDate(today.getDate() + i);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);

      const startYear = startDate.getFullYear();
      const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
      const startDay = String(startDate.getDate()).padStart(2, "0");

      const endYear = endDate.getFullYear();
      const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
      const endDay = String(endDate.getDate()).padStart(2, "0");

      dates.push({
        startDate: `${startYear}-${startMonth}-${startDay}`,
        endDate: `${endYear}-${endMonth}-${endDay}`,
      });
    }

    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "doctorId",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: specialistInfo.id,
                },
                {
                  field: "isBooked",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: 1,
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          setTotalAppointments(result?.data?.data.response);
          setLoader(false);
        } else if (result?.data?.data?.status === "failure") {
          return toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const openMarkCompleteModal = (
    doctorUUid: any,
    startTime: any,
    calendarDate: any
  ) => {
    Swal.fire({
      title: "Are you sure you want to mark it as complete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              uuid: doctorUUid,
              status: "1",
              date: calendarDate,
              time: startTime,
              specialistid: specialistInfo.id,
              menuId: "cce692df-7d87-48b9-8fcf-974ef927bf42",
              action: "command",
              command: [
                {
                  agent: "bookingComplete",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
                {
                  agent: "bookingCompleteMail",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
              ],
            },
            {
              headers: { "x-access-token": `${authContext.token}` },
            }
          )
          .then((result) => {
            if (result.data.statusCode === 200) {
              Swal.fire({
                title: "Completed",
                text: "Your status has been updated",
                icon: "success",
              });
              specialistTotalAppointments();
            }
          })
          .catch((err) => {
            return toast.error("Soemthing went wrong", err);
          });
      }
    });
  };

  return loader ? (
    <span className="spinner-border text-secondary"></span>
  ) : (
    <>
      <main className="page-content categories-wrpper user_wrapper">
        <div className="container mt-5 mt-lg-0 pt-4 pt-lg-0">
          <div className="row">
            <div className="col-lg-12 px-0">
              <div
                className={`tab ${
                  totalAppointments.length !== 0 ? "table-responsive" : ""
                }`}
              >
                <table className="table table--lines table--striped position-relative">
                  <thead className="table__header table__header--sticky text-center">
                    <tr>
                      <th className="text-truncate">
                        <span className="font-14">User Name</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Calendar Date - Day</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Start time - End time</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Price</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Duration</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Status</span>
                      </th>
                    </tr>
                  </thead>
                  {totalAppointments.length !== 0 &&
                  totalAppointments !== null ? (
                    totalAppointments.map((elem: any) => (
                      <tbody className="text-center">
                        <tr className="table__row">
                          <td className="table__td">
                            <div className="media-item">
                              <div className="media-item__right">
                                <h5 className="media-item__title text-truncate font-14 text-truncate pl-3 pl-lg-0">
                                  {elem.name !== null
                                    ? elem.name.toUpperCase()
                                    : null}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-grey text-truncate font-14 text-uppercase">
                              {elem.calendarDay} - {elem.calendarDate}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-truncate font-14">
                              {elem.StartTime} - {elem.EndTime}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-grey text-truncate font-14">
                              {elem.amount}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-grey text-truncate font-14">
                              {elem.Duration}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <div className="table__status text-truncate font-14">
                              <h4>
                                <span
                                  className={`badge badge-${
                                    elem.status === "init"
                                      ? "danger"
                                      : elem.status === "Settled"
                                      ? "success"
                                      : "primary"
                                  } text-white p-1`}
                                >
                                  {elem.status === "init"
                                    ? "INCOMPLETE"
                                    : elem.status === "completed"
                                    ? "COMPLETED"
                                    : elem.status === "Settled"
                                    ? "SETTLED"
                                    : elem.status}
                                </span>
                                {elem.status === "Settled" ||
                                elem.status === "completed" ? null : (
                                  <svg
                                    onClick={() =>
                                      openMarkCompleteModal(
                                        elem.uuid,
                                        elem.StartTime,
                                        elem.calendarDate
                                      )
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 18 18"
                                    className="pl-2 cursor_pointer"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0"
                                    />
                                  </svg>
                                )}
                              </h4>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center money__management--noaplication-available">
                      <p className="text-center mb-0 py-3">
                        No appointments available
                      </p>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MoneyManagement;
