import React, { useContext, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import AuthContext from "../../authContext";
import { toast } from "react-toastify";
import moment from "moment";
import Shimmer from "../../pages/AdminDashboard/Shimmer";

const UserSpecialist: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [catAndSpecialistList, setCatAndSpecialistList] = useState<any>([]);
  const [activeCatId, setActiveCatId] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any>([]);
  const [loader, setLoader] = useState<any>([]);

  const getCategoriesAndSpecialistList = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 1,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
            {
              agent: "categoryDoctorList",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setCatAndSpecialistList(result?.data?.data);
          setLoader(false);
        } else {
          setCatAndSpecialistList([]);
          setLoader(false);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Seomthing went wrong", err);
      });
  };

  useEffect(() => {
    getCategoriesAndSpecialistList();
  }, [authContext.token]);

  const activeCategory = async (activeCatId: any) => {
    setActiveCatId(activeCatId);
    await categoryWiseSpecialistList(activeCatId);
  };

  const categoryWiseSpecialistList = async (categoryId: any) => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 50,
            filters: [
              {
                field: "isActive",
                operator: "eq",
                value: 1,
              },
              {
                field: "uuid",
                operator: "eq",
                value: categoryId,
              },
            ],
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          setSpecialistList(result?.data?.data);
        } else {
          setSpecialistList([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    if (catAndSpecialistList.length > 0 && catAndSpecialistList[0]?.uuid) {
      activeCategory(catAndSpecialistList[0].uuid);
    }
  }, [catAndSpecialistList]);

  return loader ? (
    <Shimmer />
  ) : (
    <main className="page-content categories-wrpper user_wrapper">
      <div className="container pt-2 pt-lg-0">
        <div className="row pb-4">
          <div className="col-lg-12 px-0">
            <div className="row ml-0 mr-0">
              <div className="col-lg-12 pt-3 pb-4">
                <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium ">
                  WELLNESS CATEGORY
                </h1>
              </div>
              {catAndSpecialistList.length !== 0 &&
              catAndSpecialistList !== null ? (
                catAndSpecialistList.map((elem: any) => (
                  <div
                    className="col-lg-2 col-md-6 col-6 position-relative pb-4 pb-lg-0 mb-3 cursor_pointer"
                    key={elem.id}
                    onClick={() => activeCategory(elem.uuid)}
                  >
                    <div
                      className={`wellness_catagory_card ${
                        activeCatId === elem.uuid
                          ? "wellness_catagory_card--active"
                          : ""
                      } position-relative`}
                    >
                      <div className="explore__our__top__interacting__image__block">
                        <img
                          src={elem.image}
                          alt=""
                          className="img-fluid wellness_catagory_card_image w-100"
                          style={{ width: "auto", height: "auto" }}
                          loading="lazy"
                        />
                      </div>
                      <div className="explore__our__top__interacting__content w-100">
                        <h2 className="mb-0 explore__our__top__interacting__title font-14 text-center">
                          {elem.name.toUpperCase()}
                        </h2>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-lg-12 text-center">
                  <span className="not__available text-uppercase">No data available</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-4 pt-1">
        <div className="row">
          {specialistList.length !== 0 && specialistList !== null ? (
            specialistList.map((elem: any) => (
              <div className="col-lg-4 pb-3">
                <div className="today-join-time-premimum-version-box p-3 today__card consumer__specialist__profile">
                  <div className="wellness__category__second__card">
                    <div className="row d-flex align-items-start">
                      <div className="col-lg-8">
                        <div className="row d-flex align-items-start p-2 pt-3">
                          <div className="col px-0 pb-3 pb-lg-0">
                            <div className="wellness__category__second__card__image__block user__specialist--image">
                              <img
                                src={elem.profile}
                                alt=""
                                className="img-fluid w-100 wellness__category__second__card__image user__specialist__image--image"
                                style={{ width: "auto", height: "auto" }}
                              />
                            </div>
                          </div>
                          <div className="col pl-0">
                            <p className="mb-0 font-14 wellness__category__second__card__name secondary--color">
                              {elem.name.toUpperCase()}
                            </p>
                            <p className="mb-0 font-12 wellness__category__second__card__specialization__type">
                              {elem.specialistExprience[0].title.toUpperCase()}
                            </p>
                            <p className="mb-0 extra--small wellness__category__gender">
                              {elem.gender.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 d-none d-lg-block pt-2">
                        <Rating
                          readonly={true}
                          initialValue={elem.rating}
                          size={16}
                        />
                      </div>
                    </div>
                    <div className="row py-3">
                      <div className="col-lg-12">
                        <p className="mb-0 medium--font">
                          {elem.specialistExprience[0].title.toUpperCase()}
                        </p>
                        <p className="mb-0 font-12 wellness__category__second__card__year__of__experience py-2">
                          {elem.yearofexperience} YEARS OF EXPERIENCE
                        </p>
                        <p className="mb-0 extra--small wellness__category__second__card__langauge pb-2">
                          {elem.language.toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-end">
                      <div className="col-4 d-block d-lg-none">
                        <Rating
                          readonly={true}
                          initialValue={elem.rating}
                          size={16}
                        />
                      </div>
                      <div className="col-8 d-flex justify-content-end">
                        <p className="mb-0 register--date font-12 text-right">
                          <span className="extra--small pr-2 wellness__category__second__card--registration--date color-gray text-uppercase">
                            Registration Date :-
                          </span>
                          {moment
                            .utc(
                              elem.doctorCreateAt,
                              "YYYY-MM-DDTHH:mm:ss.SSSZ"
                            )
                            .format("YYYY-MM-DD")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-lg-12 text-center">
              <span className="not__available text-uppercase">No data available</span>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default UserSpecialist;
