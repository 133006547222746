import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../authContext";
import { toast } from "react-toastify";

export const useUserRefundList = () => {
  const [refundList, setRefundList] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    getRefundList();
  }, [authContext.token]);

  const getRefundList = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: [],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userRefundList",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setRefundList(result?.data?.data);
          setLoader(false);
        } else {
          setRefundList([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("SOMETHING WENT WRONG", err);
      });
  };
  return refundList;
};

export default useUserRefundList;
