import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import { CChart } from "@coreui/react-chartjs";
import NoDataFoundImage from "../../../../src/img/content/bg/page-not-found.png";

const Body: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [specialistList, setSpecialistData] = useState<any>([]);
  const [activeSpecialistList, setSpecialistList] = useState<any>([]);
  const [pendingReqCount, setPendingReqCount] = useState<any>("");
  const [adminAnalytics, setAnalyticsData] = useState<any>([]);
  const [analyticsGraphData, setAnalyticsGraphData] = useState<any>([]);
  const [monthArray, setMonthArray] = useState<any>([]);
  const [totalAmountArray, setTotalAmountArray] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [loader, setLoader] = useState<any>(false);
  const navigate = useNavigate();
  const currentDate: any = new Date();
  const currentYear: any = currentDate.getFullYear();

  const getInActiveSpecialistList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 0,
                },
                {
                  field: "role",
                  operator: "eq",
                  value: "specialist",
                },
              ],
              sort: [
                {
                  field: "id",
                  table: "Users",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
          action: "command",
          command: [
            {
              agent: "specialistListsData",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        console.log(result);
        setSpecialistData(result?.data?.data);
        setPendingReqCount(result?.data?.totalCount);
      })
      .catch((err) => {
        toast.error(err);
        return;
      });
  };

  const getList = useCallback(getInActiveSpecialistList, [authContext.token]);

  useEffect(() => {
    getList();
  }, [getList]);

  const goToSpecialistDetailsPage = (id: number, specialistList: any) => {
    navigate(`/dashboard/specialists/specialistsapprove/${id}`, {
      state: { specialistList },
    });
  };

  const getAdminAnalytics = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          action: "command",
          command: [
            {
              agent: "totalUsersOnbording",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setAnalyticsData(result?.data?.data);
        } else {
          setAnalyticsData([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getAdminAnalytics();
  }, []);

  let totalSpecialist: any = 0;
  let totalUsers: any = 0;

  adminAnalytics.forEach((item: any) => {
    if (item.role === "specialist") {
      totalSpecialist += item.total_count;
    } else if (item.role === "user") {
      totalUsers += item.total_count;
    }
  });

  const getSpecialistList = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 4,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: "1",
                },
                {
                  field: "role",
                  operator: "eq",
                  value: "specialist",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "specialistLists",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setSpecialistList(result?.data?.data);
          setLoader(false);
        } else {
          setSpecialistList([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getSpecialistList();
  }, [authContext.token]);

  const getDashboardAnalyticsGraph = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 25,
              filters: [
                {
                  field: "year",
                  operator: "eq",
                  table: "ordersData",
                  value: currentYear,
                },
              ],
              sort: [
                {
                  field: "month",
                  table: "ordersData",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf6",
          action: "command",
          command: [
            {
              agent: "adminAnalytics",
              appName: "selfMaximized",
              folder: "admin",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setAnalyticsGraphData(result?.data?.data);
        } else {
          return toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getDashboardAnalyticsGraphCB = useCallback(getDashboardAnalyticsGraph, [
    authContext.token,
  ]);

  useEffect(() => {
    getDashboardAnalyticsGraphCB();
  }, [authContext.token]);

  useEffect(() => {
    // Build new arrays outside the loop
    const newMonthArray: any = [];
    const newTotalAmountArray: any = [];
    let sumOfTotalAmount: any = 0;

    analyticsGraphData.forEach((elem: any) => {
      newMonthArray.push(elem.month);
      sumOfTotalAmount += Number(elem.grandTotalSum);
      newTotalAmountArray.push(elem.grandTotalSum);
    });

    // Update state once with the new arrays
    setMonthArray(newMonthArray);
    setTotalAmountArray(newTotalAmountArray);
    setTotalAmount(sumOfTotalAmount);
  }, [analyticsGraphData]);

  return (
    <>
      <main className="page-content overflow-auto">
        <div className="container dashboard-wrapper pt-4 pt-lg-0">
          <div className="row dashboard-first-container pb-3">
            <div className="col-lg-4 pb-3 pb-lg-0 pr-0 pr-md-3">
              <div className="dashboard-first-container-left p-3">
                <div className="row new-request-wrapper pb-2 mb-3">
                  <div className="col-6">
                    <p className="new-request mb-0 font-14">NEW REQUEST</p>
                  </div>
                  <div className="col-6">
                    <p className="new mb-0 text-right new-title">
                      <span className="pr-2 text-uppercase">New</span>{" "}
                      <span className="new-number font-weight-normal">
                        {pendingReqCount}
                      </span>
                    </p>
                  </div>
                </div>
                {specialistList.length !== 0 && specialistList !== null ? (
                  specialistList.map((elem: any) => (
                    <div className="row mb-3" key={elem.id}>
                      <div className="col-9">
                        <div className="review-list">
                          <div className="review-list__item d-flex align-items-center mb-0">
                            <div className="review-list__avatar--new--requeat" id="newRequest">
                              <img
                                src={elem.profile ? elem.profile : ""}
                                className="request--img img-fluid"
                                alt="#"
                                style={{ width: "", height: "" }}
                              />
                            </div>
                            <div className="">
                              <h5 className="review-list__name font-14 text-uppercase">
                                {elem.name}
                              </h5>
                              <div className="">
                                <p className="domain mb-0 font-10 text-uppercase">
                                  Org. Admin
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <p className="mb-0 text-center">
                          <Link
                            to=""
                            className="view_more font-12 text-right text-uppercase"
                            onClick={() =>
                              goToSpecialistDetailsPage(elem.uuid, elem)
                            }
                          >
                            View
                          </Link>
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <img
                      src={NoDataFoundImage}
                      alt=""
                      className="img-fluid"
                      style={{ width: "auto", height: "auto" }}
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8 revenue-genrated-wrapper p-3 m-2 m-lg-0">
              <div className="revenue-genrated">
                <div className="row new-request-wrapper pb-2 mb-3">
                  <div className="col-lg-12">
                    <p className="new-request mb-0 font-14">
                      REVENUE GENERATED
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="total-revenue p-2 mb-2">
                      <p className="total-revenue-title mb-0 font-12 text-white text-uppercase">
                        Total Revenue
                      </p>
                      <p className="total-revenue-amount mb-0 font-weight-bold text-white">
                        {totalAmount}
                      </p>
                    </div>
                    <div className="doctor-wrapper p-2 mb-2">
                      <p className="total-revenue-title mb-0 font-12 text-white text-uppercase">
                        Doctor
                      </p>
                      <p className="total-revenue-amount mb-0 font-weight-bold text-white">
                        {totalSpecialist}
                      </p>
                    </div>
                    <div className="consumer-wrapper p-2">
                      <p className="total-revenue-title mb-0 font-12 text-white text-uppercase">
                        Consumer
                      </p>
                      <p className="total-revenue-amount mb-0 font-weight-bold text-white">
                        {totalUsers}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8">
                    <CChart
                      type="line"
                      data={{
                        labels: monthArray,
                        datasets: [
                          {
                            label: currentYear,
                            backgroundColor: "rgba(153, 102, 255, 0.2)",
                            borderColor: "rgba(179,181,198,1)",
                            pointBackgroundColor: "rgba(179,181,198,1)",
                            pointBorderColor: "#fff",
                            pointHoverBackgroundColor: "#fff",
                            pointHoverBorderColor: "rgba(179,181,198,1)",
                            data: totalAmountArray,
                          },
                        ],
                      }}
                      options={{
                        aspectRatio: 1.5,
                        plugins: {
                          tooltip: {
                            enabled: true,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-lg-3 col-md-6 pb-2">
              <div className="total-customer-onboard-wrapper p-2">
                <div className="total-customer-onboard d-flex justify-content-between p-2 mb-2">
                  <p className="total-customer-onboard-title mb-0 font-12 text-uppercase">
                    Total customer Onboarded
                  </p>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path
                      d="M16.0002 17.65H5.00024C4.36512 17.65 3.85024 17.1351 3.85024 16.5V15.8216C3.85024 15.4551 4.02489 15.1106 4.32047 14.894L7.40562 12.633C7.9211 12.2553 8.57794 12.1264 9.19795 12.2814L9.56631 12.3735C10.1794 12.5268 10.8208 12.5268 11.4338 12.3735L11.8022 12.2814C12.4222 12.1264 13.079 12.2552 13.5945 12.633L16.68 14.894C16.9756 15.1106 17.1502 15.4551 17.1502 15.8216V16.5C17.1502 17.1351 16.6354 17.65 16.0002 17.65ZM7.35024 6.99987C7.35024 5.27216 8.77771 3.86294 10.5181 3.87281C12.2446 3.88261 13.6502 5.28618 13.6502 7.00013C13.6502 8.72784 12.2228 10.1371 10.4824 10.1272C8.75592 10.1174 7.35024 8.71382 7.35024 6.99987Z"
                      strokeWidth="1.7"
                    />
                  </svg>
                </div>
                <div className="row align-items-center">
                  <div className="col-4">
                    <p className="onboard-number mb-0 font-weight-bold text-white">
                      {adminAnalytics !== null && adminAnalytics.length !== 0
                        ? adminAnalytics[0].total_count
                        : 0}
                    </p>
                  </div>
                  <div className="col-8">
                    <div className="row align-items-end">
                      <div className="col-4">
                        |{" "}
                        {adminAnalytics.length !== 0
                          ? adminAnalytics[0].last_week_count
                          : 0}
                      </div>
                      <div className="col-8">
                        <p className="since-last-week mb-0 font-10 text-right text-uppercase">
                          Since Last week
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 pb-2">
              <div className="total-customer-onboard-wrapper p-2">
                <div className="total-customer-onboard d-flex justify-content-between p-2 mb-2">
                  <p className="total-customer-onboard-title mb-0 font-12 text-uppercase">
                    Total Doctor Onboarded
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path
                      d="M16.0002 17.65H5.00024C4.36512 17.65 3.85024 17.1351 3.85024 16.5V15.8216C3.85024 15.4551 4.02489 15.1106 4.32047 14.894L7.40562 12.633C7.9211 12.2553 8.57794 12.1264 9.19795 12.2814L9.56631 12.3735C10.1794 12.5268 10.8208 12.5268 11.4338 12.3735L11.8022 12.2814C12.4222 12.1264 13.079 12.2552 13.5945 12.633L16.68 14.894C16.9756 15.1106 17.1502 15.4551 17.1502 15.8216V16.5C17.1502 17.1351 16.6354 17.65 16.0002 17.65ZM7.35024 6.99987C7.35024 5.27216 8.77771 3.86294 10.5181 3.87281C12.2446 3.88261 13.6502 5.28618 13.6502 7.00013C13.6502 8.72784 12.2228 10.1371 10.4824 10.1272C8.75592 10.1174 7.35024 8.71382 7.35024 6.99987Z"
                      strokeWidth="1.7"
                    />
                  </svg>
                </div>
                <div className="row align-items-center">
                  <div className="col-4">
                    <p className="onboard-number mb-0 font-weight-bold text-white">
                      {adminAnalytics !== null && adminAnalytics.length !== 0
                        ? adminAnalytics[1].total_count
                        : 0}
                    </p>
                  </div>
                  <div className="col-8">
                    <div className="row row align-items-end">
                      <div className="col-4">
                        |{" "}
                        {adminAnalytics.length !== 0
                          ? adminAnalytics[1].last_week_count
                          : 0}
                      </div>
                      <div className="col-8">
                        <p className="since-last-week mb-0 font-10 text-right text-uppercase">
                          Since Last week
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 pb-2">
              <div className="total-customer-onboard-wrapper p-2">
                <div className="total-customer-onboard d-flex justify-content-between p-2 mb-2">
                  <p className="total-customer-onboard-title mb-0 font-12 text-uppercase">
                    Total website visits
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path
                      d="M16.0002 17.65H5.00024C4.36512 17.65 3.85024 17.1351 3.85024 16.5V15.8216C3.85024 15.4551 4.02489 15.1106 4.32047 14.894L7.40562 12.633C7.9211 12.2553 8.57794 12.1264 9.19795 12.2814L9.56631 12.3735C10.1794 12.5268 10.8208 12.5268 11.4338 12.3735L11.8022 12.2814C12.4222 12.1264 13.079 12.2552 13.5945 12.633L16.68 14.894C16.9756 15.1106 17.1502 15.4551 17.1502 15.8216V16.5C17.1502 17.1351 16.6354 17.65 16.0002 17.65ZM7.35024 6.99987C7.35024 5.27216 8.77771 3.86294 10.5181 3.87281C12.2446 3.88261 13.6502 5.28618 13.6502 7.00013C13.6502 8.72784 12.2228 10.1371 10.4824 10.1272C8.75592 10.1174 7.35024 8.71382 7.35024 6.99987Z"
                      strokeWidth="1.7"
                    />
                  </svg>
                </div>
                {/* <div className="row align-items-center">
                  <div className="col-4">
                    <p className="onboard-number mb-0 font-weight-bold text-white">
                      {adminAnalytics !== null && adminAnalytics.length !== 0
                        ? adminAnalytics[3].total_count
                        : 0}
                    </p>
                  </div>
                  <div className="col-8">
                    <div className="row row align-items-end">
                      <div className="col-5">
                        |{" "}
                        {adminAnalytics.length !== 0
                          ? adminAnalytics[3].last_week_count
                          : 0}
                      </div>
                      <div className="col-7">
                        <p className="since-last-week mb-0 font-10 text-right">
                          Since Last week
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 pb-2">
              <div className="total-customer-onboard-wrapper p-2">
                <div className="total-customer-onboard d-flex justify-content-between p-2 mb-2">
                  <p className="total-customer-onboard-title mb-0 font-12">
                    Total orders booked
                  </p>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                    <path
                      d="M16.0002 17.65H5.00024C4.36512 17.65 3.85024 17.1351 3.85024 16.5V15.8216C3.85024 15.4551 4.02489 15.1106 4.32047 14.894L7.40562 12.633C7.9211 12.2553 8.57794 12.1264 9.19795 12.2814L9.56631 12.3735C10.1794 12.5268 10.8208 12.5268 11.4338 12.3735L11.8022 12.2814C12.4222 12.1264 13.079 12.2552 13.5945 12.633L16.68 14.894C16.9756 15.1106 17.1502 15.4551 17.1502 15.8216V16.5C17.1502 17.1351 16.6354 17.65 16.0002 17.65ZM7.35024 6.99987C7.35024 5.27216 8.77771 3.86294 10.5181 3.87281C12.2446 3.88261 13.6502 5.28618 13.6502 7.00013C13.6502 8.72784 12.2228 10.1371 10.4824 10.1272C8.75592 10.1174 7.35024 8.71382 7.35024 6.99987Z"
                      strokeWidth="1.7"
                    />
                  </svg>
                </div>
                <div className="row align-items-center">
                  <div className="col-4">
                    <p className="onboard-number mb-0 font-weight-bold text-white">
                      {adminAnalytics !== null && adminAnalytics.length !== 0
                        ? adminAnalytics[5].total_count
                        : 0}
                    </p>
                  </div>
                  <div className="col-8">
                    <div className="row row align-items-end">
                      <div className="col-5">
                        |{" "}
                        {adminAnalytics.length !== 0
                          ? adminAnalytics[5].last_week_count
                          : 0}
                      </div>
                      <div className="col-7">
                        <p className="since-last-week mb-0 font-10 text-right">
                          Since Last week
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="team__wrapper">
            <div className="row p-3 px-0">
              <div className="col-lg-12">
                <p className="my-team-heading mb-0 text-white text-uppercase">My Team</p>
              </div>
            </div>
            {loader ? (
              <div className="d-flex justify-content-center pb-3">
                <span className="spinner-border" role="status"></span>
              </div>
            ) : (
              <div className="row my-team_wrapper mb-3 p-3 row-cols-1 row-cols-sm-2 row-cols-md-4">
                {activeSpecialistList.length !== 0 &&
                activeSpecialistList !== null ? (
                  activeSpecialistList.map((elem: any) => (
                    <div className="col pb-5 pe-2">
                      <div className="team-border-right">
                        <div className="review-list pb-0 pb-lg-3">
                          <div className="review-list__item d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div className="review-list__avatar team__avatar--wrapper">
                              <img
                                src={elem.profile ? elem.profile : ""}
                                alt="#"
                                className="img-fluid w-100"
                                style={{ width: "auto", height: "auto" }}
                                loading="lazy"
                              />
                            </div>
                            <div className="">
                              <h5 className="review-list__name font-12">
                                {elem.name.toUpperCase()}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="email-id-wrpper text-start text-lg-start text-xl-start text-md-start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                          >
                            <path d="M17.4 5H4.6C3.72 5 3.008 5.74483 3.008 6.65517L3 15.3448C3 16.2552 3.72 17 4.6 17H17.4C18.28 17 19 16.2552 19 15.3448V6.65517C19 5.74483 18.28 5 17.4 5ZM16.6 15.3448H5.4C4.96 15.3448 4.6 14.9724 4.6 14.5172V8.31034L10.152 11.9021C10.672 12.2414 11.328 12.2414 11.848 11.9021L17.4 8.31034V14.5172C17.4 14.9724 17.04 15.3448 16.6 15.3448ZM11 10.7931L4.6 6.65517H17.4L11 10.7931Z" />
                          </svg>
                          <span className="team-member-email font-10 pl-1">
                            {elem.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 text-center">
                    <span className="text-white font-14">
                      No data available
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Body;
