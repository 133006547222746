import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../../../authContext";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import Shimmer from "../../Shimmer";

const TopDoctorsAnalytics: React.FC = () => {

  const [loader, setLoader] = useState<any>(false);
  const [specialistDetails, setSpecialistDetails] = useState<any>([]);
  const authCtx = useContext(AuthContext);

  const getSpecialistDetails = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 4,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 1,
                },

                {
                  field: "role",
                  operator: "eq",
                  value: "specialist",
                },
              ],
              sort: [
                {
                  field: "rating",
                  table: "Doctors",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
          action: "command",
          command: [
            {
              agent: "specialistListsData",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authCtx.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setSpecialistDetails(result?.data?.data);
          setLoader(false);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong" + err);
      });
  };

  const getSpecialist = useCallback(getSpecialistDetails, [authCtx.token]);

  useEffect(() => {
    getSpecialist();
  }, [getSpecialist]);


  const showExpData = (type: string, specialistData : any) => {
   
    const areaOfExpertise =
      specialistData.length !== 0 && specialistData?.DoctorExperiences[0]
        ? specialistData?.DoctorExperiences[0].areaofexpertise
        : "";
   
    let parsedAreaOfExp: any;

    if (areaOfExpertise) {
      try {
        parsedAreaOfExp = JSON.parse(areaOfExpertise);
      } catch (error) {
        console.warn("Invalid JSON in areaOfExpertise:", areaOfExpertise);
        parsedAreaOfExp = null;
      }
    }

    if (parsedAreaOfExp && type === "areaofexpertise") {
      return parsedAreaOfExp.map((elem: any) => elem.category).join(" | ");
    }

  };

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <div className="row">
        {specialistDetails.length !== 0 && specialistDetails !== null ? (
          specialistDetails.map((elem: any) => (
            <div className="col-lg-6 px-3 pb-2 h-100" key={elem?.id}>
              <div className="row top-doctors-info p-2">
                <div className="col-lg-12 px-0">
                  <div className="row">
                    <div className="col-5">
                      <div className="analytics__specialist--profile">
                        <img
                          src={elem.profile}
                          className="img-fluid"
                          alt="#"
                          style={{ width: "auto", height: "auto" }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <p className="top-doctors-name mb-0 font-12">
                        {elem?.name?.toUpperCase()}
                      </p>
                      <p className="top-doctors-therapy mb-0 font-14">
                        {showExpData('areaofexpertise', elem)}
                      </p>
                      <Rating
                        readonly={true}
                        initialValue={elem?.rating}
                        size={16}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <span>No Data Available</span>
        )}
      </div>
    </>
  );
};

export default TopDoctorsAnalytics;
