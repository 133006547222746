import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import AuthContext from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import filterIcon from "../../../../img/content/icons/Icon feather-filter.svg";

const AddCategories: React.FC = () => {
  const [domainTypes, setDomainTypes] = useState<any>({
    domainName: "",
    domainSummary: "",
  });
  const [fileResponse, setFileResponse] = useState<any>("");
  const [imgSrc, setImgSrc] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any[]>([]);
  const [searchItem, setSearchItem] = useState<any>("");
  const [fieldName, setFieldName] = useState<any>("");
  const [selectedSpecialist, setSelectedSpecialist] = useState<any>(new Set());
  const [loader, setLoader] = useState<any>("");

  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  const handleSpecialist = async (e: any) => {
    e.preventDefault();
    const specialistSearchValue = e.target.value;
    setSearchItem(specialistSearchValue);
    await getSpecialistList(specialistSearchValue);
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const getSpecialistList = async (specialistSearchValue: string) => {
    let filters = [];
    let tableName = fieldName === "" ? "name" : fieldName;
    if (specialistSearchValue) {
      filters.push(
        {
          field: "isActive",
          operator: "eq",
          value: "1",
        },
        {
          field: `${tableName}`,
          operator: "contain",
          table: `${
            tableName === "title" ||
            tableName === "areaofexpertise" ||
            tableName === "education"
              ? "DoctorExperiences"
              : "Doctors"
          }`,
          value: `${specialistSearchValue}`,
        }
      );
    } else {
      filters = [
        {
          field: "isActive",
          operator: "eq",
          value: "1",
        },
      ];
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 250,
            filters: filters,
            sort: [
              {
                field: "id",
                dir: "desc",
                table: "Users",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
        action: "command",
        command: [
          {
            agent: "specialistLists",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );
    setSpecialistList(response?.data?.data);
  };

  const getSpecialList = useCallback(getSpecialistList, [authCtx.token]);

  useEffect(() => {
    getSpecialList("");
  }, [getSpecialList]);

  const toggleSpecialistSelection = (id: any) => {
    setSelectedSpecialist((prevSelected: any) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleDomainTypes = (event: any) => {
    const { name, value } = event.target;
    setDomainTypes((prevState: any) => ({
      ...prevState,
      [name]: name === "domainName" ? value.toUpperCase() : value,
    }));
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSrc(imageSrc);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSrc("");
        event.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
  };

  const addCategories = async () => {
    let specialistsId: number[] = [];
    let ids: number[] = [];

    if (
      !domainTypes.domainName ||
      !domainTypes.domainSummary ||
      !fileResponse ||
      !imgSrc ||
      selectedSpecialist.size === 0
    ) {
      let errorMessage = "";

      if (!domainTypes.domainSummary) {
        errorMessage += "Domain summary is mandatory. ";
      }
      if (!domainTypes.domainName) {
        errorMessage += "Domain name is mandatory. ";
      }
      if (!imgSrc) {
        errorMessage += "Select the image first. ";
      }
      if (selectedSpecialist.size === 0) {
        errorMessage += "Select specialist. ";
      }
      if (
        !domainTypes.domainName &&
        !domainTypes.domainSummary &&
        !imgSrc &&
        selectedSpecialist.size === 0
      ) {
        errorMessage = "All fields are mandatory.";
      }

      if (domainTypes.domainName) {
        if (
          !domainTypes.domainSummary &&
          !imgSrc &&
          selectedSpecialist.size === 0
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (domainTypes.domainSummary) {
        if (
          !domainTypes.domainName &&
          !imgSrc &&
          selectedSpecialist.size === 0
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (imgSrc) {
        if (
          !domainTypes.domainName &&
          !domainTypes.domainSummary &&
          selectedSpecialist.size === 0
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (selectedSpecialist) {
        if (!domainTypes.domainName && !domainTypes.domainSummary && !imgSrc) {
          errorMessage = "All fields are mandatory.";
        }
      }

      return toast.warning(errorMessage);
    }

    let imageUrl = "";
    if (fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    } else {
      return;
    }

    ids = specialistList.filter((specialist) =>
      selectedSpecialist.has(specialist.id)
    );
    if (ids) {
      ids.forEach((elem: any) => {
        specialistsId.push(elem.id);
      });
    } else {
      specialistsId = [];
    }
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: `${domainTypes.domainName}`,
          description: `${domainTypes.domainSummary}`,
          ip: "127.0.0.1",
          isActive: 1,
          image: imageUrl,
          doctor: specialistsId,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "createCategory",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authCtx.token}`,
          },
        }
      )
      .then(async (response) => {
        // if(response.data.data.response.status === 'success'){
        setDomainTypes({ domainName: "", domainSummary: "" });
        setImgSrc("");
        toast.success("Category Added!");
        setLoader(false);
        navigate("/dashboard/categories");
        // }
      })
      .catch((err) => {
        toast.error(`${err}`);
        setLoader(false);
      });
  };

  const selectField = async (fieldName: any) => {
    setFieldName(fieldName);
    setSearchItem("");
  };
  return (
    <>
      <main className="page-content categories-wrpper overflow-auto">
        <div className="container pt-3 pt-lg-0">
          <div className="row pb-4">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 text-right">
              {loader ? (
                <button
                  className="categories__add-btn btn__border--radius publish-btn category-publish--btn"
                  disabled
                >
                  <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                  <span className="font-weight-bold"> LOADING...</span>
                </button>
              ) : (
                <button
                  className="categories__add-btn publish-btn category-publish--btn btn__border--radius"
                  onClick={addCategories}
                >
                  <span className="font-weight-bold">PUBLISH</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container pb-4 categories_publish-wrapper">
          <div className="py-2 categories_publish-wrapper-inner p-3 p-lg-3">
            <div className="row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1 mb-0 pb-3">
                    WELLNESS CATEGORY
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="domainName"
                    value={domainTypes.domainName}
                    name="domainName"
                    onChange={handleDomainTypes}
                    autoComplete="off"
                    onKeyDown={handleKeyDownEvent}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    WELLNESS SUMMARY
                  </label>
                  <textarea
                    className="form-control text-uppercase"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    value={domainTypes.domainSummary}
                    name="domainSummary"
                    onChange={handleDomainTypes}
                    autoComplete="off"
                    onKeyDown={handleKeyDownEvent}
                  ></textarea>
                </div>
                <p className="mb-0 font-12 pb-2">CHOOSE FEATURED IMAGE</p>
                <div
                  className="d-flex justify-content-center pb-3 categories__publish position-relative add__categories__upload__image"
                  onClick={handleTriggerClick}
                >
                  <img
                    src={imgSrc}
                    alt=""
                    style={{ width: "auto", height: "auto" }}
                    className={
                      imgSrc ? `categories__publish__upload__image w-100` : ""
                    }
                  />
                  <div>
                    <div>
                      <div className="font-10 text-center mt-5">
                        {imgSrc ? (
                          ""
                        ) : (
                          <svg
                            className="icon-icon-plus "
                            viewBox="0 0 14 14"
                            height={40}
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                            />
                          </svg>
                        )}
                        <p className="mb-0 font-14 text-uppercase">
                          {imgSrc ? "" : "Add the featured photo"}
                        </p>
                      </div>
                    </div>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 pt-3 pt-lg-0">
                <p className="mb-0 pb-2 font-12 text-uppercase">
                  Add Doctors for this domain
                </p>
                <div className="categories__right-bg categories-add-right p-2">
                  <>
                    {/* Search bar */}
                    <div className="sticky--header">
                      <div className="header__searchone magazine_wrapper-searchone d-flex align-items-center pt-3 pb-2">
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle toggle_dropdown--btn mr-2"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <div className="global-dropdown-icon">
                              <img
                                src={filterIcon}
                                alt=""
                                className="img-fluid w-100"
                                style={{ width: "auto", height: "auto" }}
                                loading="lazy"
                              />
                            </div>
                          </button>
                          <div
                            className="dropdown-menu dropdown--menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link
                              className="dropdown-item"
                              to={""}
                              onClick={() => selectField("name")}
                            >
                              NAME
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={""}
                              onClick={() => selectField("title")}
                            >
                              TITLE
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={""}
                              onClick={() => selectField("areaofexpertise")}
                            >
                              AREA OF EXPERTISE
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={""}
                              onClick={() => selectField("education")}
                            >
                              EDUCATION
                            </Link>
                          </div>
                        </div>

                        <div className="header__search-mobile w-100">
                          <form className="form-search" action="#" method="GET">
                            <div className="form-search__container">
                              <span className="form-search__icon-left filter__search--icon">
                                <svg viewBox="0 0 18 18" id="icon-search">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.1184 13.4407C11.1132 14.1763 9.87362 14.6106 8.53259 14.6106C5.17579 14.6106 2.45456 11.8894 2.45456 8.53258C2.45456 5.17577 5.17579 2.45455 8.53259 2.45455C11.8894 2.45455 14.6106 5.17577 14.6106 8.53258C14.6106 9.87378 14.1762 11.1135 13.4404 12.1188C13.4461 12.1242 13.4518 12.1297 13.4574 12.1353L15.2716 13.9495C15.6368 14.3147 15.6368 14.9067 15.2716 15.2719C14.9064 15.6371 14.3144 15.6371 13.9492 15.2719L12.135 13.4577C12.1294 13.4521 12.1238 13.4464 12.1184 13.4407ZM12.874 8.53258C12.874 10.9303 10.9303 12.874 8.53259 12.874C6.13488 12.874 4.19114 10.9303 4.19114 8.53258C4.19114 6.13486 6.13488 4.19113 8.53259 4.19113C10.9303 4.19113 12.874 6.13486 12.874 8.53258Z"
                                  />
                                </svg>
                              </span>
                              <input
                                className="form-search__input custome__form__search--input text-dark text-uppercase"
                                type="text"
                                placeholder={
                                  fieldName === "title"
                                    ? "Search by title"
                                    : fieldName === "name"
                                    ? "Search by name"
                                    : fieldName === "areaofexpertise"
                                    ? "Seacrh by area of expertise"
                                    : fieldName === "education"
                                    ? "Search by education"
                                    : "Search"
                                }
                                value={searchItem}
                                name="fieldType"
                                onChange={handleSpecialist}
                              />
                            </div>
                          </form>
                        </div>
                        {/* Drop down */}
                      </div>
                    </div>
                  </>

                  {specialistList &&
                  specialistList.length !== 0 &&
                  specialistList !== null ? (
                    specialistList.map((elem: any) => (
                      <div
                        className="row py-3 align-items-center"
                        key={elem.id}
                      >
                        <div className="col-10">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="media-item">
                              <div className="media-item__icon color-orange">
                                <div className="media-item__icon-text">WS</div>
                                <img
                                  className="media-item__thumb"
                                  src={elem?.profile}
                                  alt="#"
                                />
                              </div>
                              <div className="media-item__right">
                                <h5 className="media-item__title font-13 mb-0 text-uppercase">
                                  {
                                    elem?.DoctorExperiences?.length > 0
                                      ? `${elem?.DoctorExperiences[0]?.title} ${elem?.name} (${elem?.DoctorExperiences[0].education})`
                                      : "No experience found" // Change this to whatever default message you want to display
                                  }
                                </h5>
                                <span className="font-12 expertise text-uppercase">
                                  Fitness expertise
                                </span>
                              </div>
                            </div>
                            <p className="mb-0 font-12">
                              {elem.DoctorExperiences.length > 0
                                ? `${elem.DoctorExperiences[0].yearofexperience} YEARS`
                                : "No data found"}
                            </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="col-auto">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={selectedSpecialist.has(elem.id)}
                                onChange={() =>
                                  toggleSpecialistSelection(elem.id)
                                }
                              />
                              <span className="checkbox__marker">
                                <span className="checkbox__marker-icon">
                                  <svg viewBox="0 0 14 12">
                                    <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                                  </svg>
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <span className="no__slots--available">
                      No Data Available
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCategories;
