import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../../../authContext";
import { useNavigate } from "react-router-dom";
import admin_logo from "../../../../src/img/content/logo/admin-logo.png";
import { toast } from "react-toastify";

const ForgotPassword: React.FC = () => {
  const [forgotPasswordMail, setForgotPasswordMail] = useState<any>("");
  const [response, setResponse] = useState<any>(null);
  const [validationMsg, setValidationMsg] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.isLoggedIn) {
      navigate("/dashboard");
    }
  }, [authContext.isLoggedIn, navigate]);

  const handleChange = (e: any) => {
    setForgotPasswordMail(e.target.value);
    setValidationMsg("");
  };

  const postForgotPasswordMail = async (e: any) => {
    e.preventDefault();

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = regex.test(forgotPasswordMail);

    if (!forgotPasswordMail) {
      return setValidationMsg("Please enter your email id");
    }
    if (forgotPasswordMail && !isValidEmail) {
      return setValidationMsg("Please enter valid email id");
    }

    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          email: forgotPasswordMail,
          baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}/#/changepassword`,
          action: "command",
          command: [
            {
              agent: "forgotPassword",
              appName: "selfMaximized",
              folder: "auth",
            },
          ],
        }
      );
      if (response?.data?.status === "failed") {
        setLoader(false);
        setValidationMsg("");
        setForgotPasswordMail("");
        return toast.info(response?.data?.message);
      }
      setResponse(response.data);
      setLoader(false);
      toast.success("EMAIL SENT");
      setForgotPasswordMail("");
    } catch (error) {
      console.error("Error posting forgot password mail:", error);
    }
  };

  return (
    <>
      {/* Forgot Password */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="auth-logo-inner-r">
              <img
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                className="img-fluid admin__logo mt-2"
                alt="logo"
                style={{ width: "220px", height: "auto" }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-lg-4 mx-auto">
            <form
              className="auth-panel__wrapper"
              onSubmit={postForgotPasswordMail}
            >
              <div className="auth-panel__container">
                <div className="auth-panel__body">
                  <div className="auth-panel__body-wrapper">
                    <div className="login-div-wrapper">
                      <h1 className="login-heading mb-0 font-weight-bold pb-4 text-uppercase">
                        Forgot Password
                      </h1>
                    </div>
                    <div className="form-group mb-0">
                      <label htmlFor="email_id">
                        <span className="text-white mr-1">*</span>EMAIL ID
                      </label>
                      <div className="input_group">
                        <input
                          className="input text-white pl-2"
                          type="email"
                          name="inputEmail"
                          value={forgotPasswordMail}
                          onChange={handleChange}
                          autoComplete="off"
                          required
                          placeholder="Enter Email"
                        />
                        <span className="text-danger text-uppercase font-12 mt-4">
                          {validationMsg}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span className="account__not__found">
                        {" "}
                        {response &&
                        response.data &&
                        response.data.status === "failed" ? (
                          <span className="text-danger mt-2">
                            {!forgotPasswordMail ? "" : null}
                          </span>
                        ) : null}
                      </span>
                    </div>
                    <div className="auth-panel__submit">
                      <button
                        className="button login-btn w-100 rounded-0"
                        type="button"
                        onClick={postForgotPasswordMail}
                        disabled={loader}
                      >
                        <span className="button__text font-weight-bold font-14">
                          {loader ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : response &&
                            response.data &&
                            response.data.status === "failed" ? (
                            <span className="button__text font-weight-bold font-14">
                              SUBMIT
                            </span>
                          ) : (
                            <span className="button__text font-weight-bold font-14">
                              SUBMIT
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
