import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AuthContext from "../../../../authContext";
import axios from "axios";
import BarChart from "./BarChart";
import { toast } from "react-toastify";
import DoughChart from "./DoughChart";
import TopDoctorsAnalytics from "./TopDoctors";

const AdminAnalyticsDetails: React.FC = () => {
  const [loader, setLoader] = useState<any>(false);
  const [specialistDetails, setSpecialistDetails] = useState<any>([]);
  const [specialistDetailsExp, setSpecialistDetailsExp] = useState<any>([]);
  const authCtx = useContext(AuthContext);
  const { id } = useParams();
  const [isActive, setIsActive] = useState<any>(false);
  const [categoryCount, setCategoryCount] = useState<any>([]);
  const [timeSlots, settimeSlots] = useState<any>([]);
  const [pdfLink, setPdfLink] = useState<any>("");

  const getSpecialistDetails = async () => {
    setLoader(true);

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "uuid",
                operator: "eq",
                value: id,
              },
            ],
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
        },
      }
    );
    if (response) {
      setSpecialistDetails(response?.data?.data[0]);
      setSpecialistDetailsExp(response?.data?.data[0].DoctorExperiences);
      setLoader(false);
      response?.data?.data[0]?.isActive == 1
        ? setIsActive(true)
        : setIsActive(false);
      getAnalyticsDetails(response?.data?.data[0]);
    }
  };
  const getAnalyticsDetails = async (specialistId: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  table: "Users",
                  value: "1",
                },
                {
                  field: "role",
                  operator: "eq",
                  table: "Users",
                  value: "specialist",
                },
                {
                  field: "transactionStatus",
                  operator: "eq",
                  table: "Orders",
                  value: "successed",
                },
              ],
              sort: [
                {
                  field: "id",
                  table: "Orders",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf6",
          specialistId: specialistId.id,
          action: "command",
          command: [
            {
              agent: "specialistListsAnalytics",
              appName: "selfMaximized",
              folder: "specialist/analytics",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authCtx.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setPdfLink(result?.data?.reportPDF);
          setCategoryCount(result?.data?.data?.response?.categories);
          settimeSlots(result?.data?.data?.response?.timeSlots);
        } else {
          return toast.error(result?.data?.message);
        }
        console.log(result.data.data);
      })
      .catch((err) => {
        return toast.error("Something went wrong" + err);
      });
  };
  const getSpecialist = useCallback(getSpecialistDetails, [authCtx.token]);

  useEffect(() => {
    getSpecialist();
  }, [getSpecialist]);

  return (
    <>
      <main className="page-content user_wrapper">
        {/*  */}
        <div className="container report-view-more-wrapper pt-5 pt-lg-0">
          <div className="row pb-3">
            <div className="col-lg-10" />
            <div className="col-lg-2 col-md-4 col-6">
              <div className="certifications-download">
                <Link
                  to={pdfLink}
                  className="d-flex justify-content-between align-items-center p-1"
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      className="text-danger ml-1"
                    />
                    <span className="font-12 pl-1">Download PDF</span>
                  </div>
                  <svg className="icon-download mr-1" viewBox="0 0 16 16">
                    <path d="M17.1765 8.64706H13.4118V3H7.76471V8.64706H4L10.5882 15.2353L17.1765 8.64706ZM4 17.1176V19H17.1765V17.1176H4Z" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="row col-lg-12 col-md-6 col-6">
            <div style={{ width: 300 }}>
              <BarChart categoryCount={categoryCount} />
            </div>
          </div>
          <div className="row mb-4 h-100">
            <div className="col-lg-6 subscription-overview-wrapper p-3 py-2 mb-3 mb-lg-0">
              <div className="">
                <div className="row new-request-wrapper pb-2 mb-3">
                  <div className="col-lg-12">
                    <p className="new-request mb-0 font-14">
                      SUBSCRIPTION OVERVIEW
                    </p>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-lg-12">
                    <p className="mb-0 font-12 subscribtion-overview-description">
                      Here we have Break down the revenue generated from the
                      application into premium and loyalty coins.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
                    <DoughChart timeSlots={timeSlots} />
                  </div>
                  <div className="col-lg-6">
                    <div className="subscription__right--box">
                      {timeSlots.length !== 0 && timeSlots !== null ? (
                        timeSlots.map((elem: any) => (
                          <div className="therty-minutes-metting-one p-3 mb-3">
                            <div className="row align-items-center">
                              <div className="col-8">
                                <p className="therty-minute-metting-title mb-0 font-14">
                                  {elem.time} HR Meeting
                                </p>
                              </div>
                              <div className="col-4">
                                <p className="therty-minute-metting-value mb-0 font-weight-bold text-right">
                                  {elem.count}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span className="no__data__available">
                          No Data Available
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="col-lg-6 px-0 px-lg-3">
              <div className="subscription-overview-wrapper p-3 py-2 h-100">
                <div className="row new-request-wrapper pb-2 mb-3">
                  <div className="col-lg-12">
                    <p className="new-request mb-0 font-14">TOP DOCTORS</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-lg-12">
                    <p className="mb-0 font-12 subscribtion-overview-description">
                      Here we have Break down the revenue generated from the
                      application into premium and loyalty coins.
                    </p>
                  </div>
                </div>
                <TopDoctorsAnalytics />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AdminAnalyticsDetails;
