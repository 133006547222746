import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../authContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

const CreateProduct: React.FC = () => {
  const [createProductData, setCreateProduct] = useState<any>({
    duration: "",
    oneHrDuration: "",
    price: "",
    compareatprice: "",
  });
  const [shortTitleValidation, setShortTitleValidation] = useState<any>("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>("");
  const [areaOfExp, setAreaOfExp] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const handleProductData = (event: any) => {
    const { name, value } = event.target;
    setCreateProduct({ ...createProductData, [name]: value });
    setShortTitleValidation("");
  };

  useEffect(() => {
    getCategories();
    getSpecialistProfile();
  }, []);

  const filteredCategories =
    selectedCategory &&
    selectedCategory.map((elem: any) => {
      const parts = elem.value.split(",");
      return {
        category: parts[2], // Get the 3rd part (index 2) for 'category'
        categoryUuid: parts[1], // Get the 2nd part (index 1) for 'categoryUuid'
      };
    });

  const createProduct = async () => {
    let priceRegex = /^[0-9]{0,20}$/;

    let isPriceValid = priceRegex.test(createProductData.duration);
    let isPriceValid2 = priceRegex.test(createProductData.oneHrDuration);

    let errorMessage = "";

    if (
      !createProductData.duration ||
      !createProductData.oneHrDuration ||
      selectedCategory.length === 0 ||
      !isPriceValid ||
      !isPriceValid2
    ) {
      let anyErrorFound = false;

      if (!anyErrorFound && selectedCategory.length === 0) {
        errorMessage = "Please select category";
        anyErrorFound = true;
      }

      if (
        !anyErrorFound &&
        !createProductData.duration &&
        !createProductData.oneHrDuration
      ) {
        errorMessage =
          "Price is mandatory. Please enter price in number format.";
        anyErrorFound = true;
      } else if (
        !anyErrorFound &&
        !isPriceValid &&
        !isPriceValid2 &&
        createProductData.duration &&
        createProductData.oneHrDuration
      ) {
        errorMessage = "Please enter price in number format.";
        anyErrorFound = true;
      }
    }

    if (errorMessage === "") {
      // If no error, proceed with your code
    } else {
      return toast.warning(errorMessage);
    }

    const filterAreaOfExp = selectedCategory.filter((elem: any) => {
      return elem.areaOfExp === false;
    });

    const createProduct = async () => {
      try {
        setLoader(true);
        let arrayOfPrice = [];
        let oneHrDurationArrayOfPrice = [];
        let halfHourResult = null;
        let oneHourResult = null;
        if (selectedCategory.length) {
          if (createProductData.duration) {
            for (let i = 0; i < selectedCategory.length; i++) {
              arrayOfPrice.push(Number(createProductData.duration));
            }
            halfHourResult = await axios.post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                duration: createProductData.duration ? 0.5 : 1,
                shorttitle: "",
                details: "",
                currencyid: 1,
                categories: filteredCategories,
                price: arrayOfPrice,
                compareatprice: arrayOfPrice,
                menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
                action: "command",
                command: [
                  {
                    agent: "createProduct",
                    appName: "selfMaximized",
                    folder: "specialist",
                  },
                ],
              },
              {
                headers: { "x-access-token": `${authContext.token}` },
              }
            );
          }
          if (createProductData.oneHrDuration) {
            for (let i = 0; i < selectedCategory.length; i++) {
              oneHrDurationArrayOfPrice.push(
                Number(createProductData.oneHrDuration)
              );
            }

            oneHourResult = await axios.post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                duration: createProductData.oneHrDuration ? 1 : 0.5,
                shorttitle: "",
                details: "",
                currencyid: 1,
                categories: filteredCategories,
                price: oneHrDurationArrayOfPrice,
                compareatprice: oneHrDurationArrayOfPrice,
                menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
                action: "command",
                command: [
                  {
                    agent: "createProduct",
                    appName: "selfMaximized",
                    folder: "specialist",
                  },
                ],
              },
              {
                headers: { "x-access-token": `${authContext.token}` },
              }
            );
          }
        }

        if (
          (oneHourResult?.data?.statusCode === 200 &&
            halfHourResult?.data?.statusCode === 400) ||
          (oneHourResult?.data?.statusCode === 400 &&
            halfHourResult?.data?.statusCode === 200) ||
          (oneHourResult?.data?.statusCode === 200 &&
            halfHourResult?.data?.statusCode === 200) ||
          oneHourResult?.data?.statusCode === 200 ||
          halfHourResult?.data?.statusCode === 200
        ) {
          setLoader(false);
          setCreateProduct({ duration: "", oneHrduration: "" });
          setSelectedCategory("");
          toast.success("PRODUCT CREATED SUCCESSFULLY");
          navigate("/product");
        }

        if (
          (oneHourResult?.data?.statusCode === 400 &&
            halfHourResult?.data?.statusCode === 400) ||
          halfHourResult?.data?.statusCode === 400 ||
          oneHourResult?.data?.statusCode === 400
        ) {
          setLoader(false);
          toast.error(
            oneHourResult?.data?.message?.toUpperCase()
              ? oneHourResult?.data?.message?.toUpperCase()
              : halfHourResult?.data?.message?.toUpperCase()
          );
          return true; // Indicates that the condition was met and handled
        }
      } catch (err) {
        setLoader(false);

        toast.error("Something went wrong");
      }
    };

    const filter2Exp = (array: any) => {
      let first2Items: any = array.slice(0, 2).map((elem: any) => elem?.label);
      const otherItems = array.length - 2;
      if (array.length > 2) {
        return `${first2Items.join(", ")} +${otherItems}`;
      } else {
        return `${first2Items.join(", ")}`;
      }
    };

    let resultOfFilter2Exp: any = "";

    if (filterAreaOfExp?.length !== 0) {
      resultOfFilter2Exp = filter2Exp(filterAreaOfExp);
    }
    if (filterAreaOfExp.length > 0) {
      Swal.fire({
        title: `ARE YOU SURE YOU WANT TO ADD ${resultOfFilter2Exp} WHICH ${
          filterAreaOfExp.length === 1 ? "IS" : "ARE"
        } NOT YOUR EXPERTISE?`,
        text: "YOU WON'T BE ABLE TO REVERT THIS!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "YES, ADD IT",
      }).then(async (result) => {
        if (result.isConfirmed) {
          createProduct();
          Swal.fire({
            title: "ADDED!",
            text: "YOUR EXPERTISE HAS BEEN ADDED TO YOUR PROFILE",
            icon: "success",
          });
        }
      });
    } else {
      createProduct();
    }
  };

  const getCategories = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCategoryList(result?.data?.data);
        } else {
          setCategoryList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const handleCategory = (selectedOption: any) => {
    setSelectedCategory(selectedOption);
  };

  const getSpecialistProfile = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          command: JSON.stringify([
            {
              agent: "getSpecialistProfile",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ]),
        },
        headers: { "x-access-token": authContext.token },
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setAreaOfExp(
            result?.data?.data[0] &&
              result?.data?.data[0].exprience[0].areaOfExpertise
          );
        } else {
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  let parsedAreaOfExp: any = null;

  if (areaOfExp) {
    try {
      parsedAreaOfExp = JSON.parse(areaOfExp);
    } catch (error) {
      console.error("Failed to parse areaOfExp:", error);
    }
  }

  const parsedAreaOfEcp = categoryList
    .sort((a: any, b: any) => b.isActive - a.isActive)
    .map((elem: any) => {
      let isAreaOfExp = false;

      if (parsedAreaOfExp) {
        isAreaOfExp = parsedAreaOfExp.some(
          (option: any) => option.category === elem.name
        );
      }

      return {
        value: `${elem.id},${elem.uuid},${elem.name}`,
        label: elem.name,
        isActive: elem.isActive,
        areaOfExp: isAreaOfExp,
      };
    });

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper py-0 container p-0">
        <div className="container pb-3 container-two">
          <div className="row user_details-inner_wrapper pb-4">
            <div className="col-lg-12">
              <div className="row pb-4 py-4">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <p className="col-lg-8 px-0">
                      NOTE - YOU CAN SELECT THE DISABLED CATEGORIES FROM THE
                      LIST ONCE THE ADMIN APPROVES!
                    </p>
                    <p className="mb-0 short__title__product font-14 pb-1">
                      SELECT CATEGORY
                    </p>
                    <Select
                      value={selectedCategory}
                      onChange={handleCategory}
                      options={parsedAreaOfEcp.map((option: any) => ({
                        ...option,
                        isDisabled: option.isActive === 0, // Disable options with isActive 0
                      }))}
                      isSearchable={true}
                      name="language"
                      isMulti
                      className="text-dark text-uppercase"
                      styles={{
                        control: (provided: any, state: any) => ({
                          ...provided,
                          fontSize: "16px",
                          color: "inherit",
                          backgroundColor: "#3A3838",
                          letterSpacing: "1px",
                          border: state.isFocused
                            ? "2px solid black"
                            : "2px solid transparent", // Custom border style
                          boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                          "&:hover": {
                            borderColor: "black", // Border color on hover
                          },
                        }),
                        option: (provided: any, state: any) => ({
                          ...provided,
                          color:
                            state.isFocused || state.isSelected
                              ? "black"
                              : "white", // Change color to black when focused or selected
                          backgroundColor: state.isFocused
                            ? "#ffffff"
                            : "#3A3838", // Background color when focused
                          opacity: state.isDisabled ? 0.5 : 1, // Add transparency to disabled options
                        }),
                        singleValue: (provided: any) => ({
                          ...provided,
                          color: "white", // Set color of the selected option to white
                        }),
                        input: (provided: any) => ({
                          ...provided,
                          color: "white",
                        }),
                        placeholder: (provided: any) => ({
                          ...provided,
                          color: "white",
                        }),
                        menu: (provided: any) => ({
                          ...provided,
                          backgroundColor: "#3A3838",
                        }),
                      }}
                      placeholder="Search..."
                    />

                    <p className="text-danger">{shortTitleValidation}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 pt-3 pt-lg-0">
                      <div className="form-group mb-0">
                        <p className="mb-0 short__title__product font-14 pb-1">
                          30 MIN APPOINTMENT PRICE
                        </p>
                        <input
                          name="duration"
                          type="number"
                          id="price"
                          className="form-control w-100"
                          placeholder="$ 0.0"
                          value={createProductData.duration}
                          onChange={handleProductData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 pt-3 pt-lg-0">
                      <div className="form-group mb-0">
                        <p className="mb-0 short__title__product font-14 pb-1">
                          60 MIN APPOINTMENT PRICE
                        </p>
                        <input
                          name="oneHrDuration"
                          type="number"
                          id="price"
                          className="form-control w-100"
                          placeholder="$ 0.0"
                          value={createProductData.oneHrDuration}
                          onChange={handleProductData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-right px-0 pb-3 m-0 pt-4 d-flex justify-content-end">
            <div className="d-flex">
              {!loader ? (
                <button
                  type="button"
                  className="font-weight-bold publish-btn product__publish__btn common-btn-style common-btn-style1"
                  onClick={createProduct}
                >
                  PUBLISH
                </button>
              ) : (
                <div className="d-flex" onClick={createProduct}>
                  <div className="d-flex">
                    <div
                      className="spinner-grow spinner-grow-sm text-dark"
                      role="status"
                    >
                      <span className="sr-only text-dark">LOADING...</span>
                    </div>
                    <span className="text-dark letter_spacing pl-1">
                      LOADING
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateProduct;
