import React from 'react';

const Shimmer = () => {
  return (
    <>
        <div style={{ display: 'flex', alignItems: 'center', padding: '20px', background: '#333', borderRadius: '8px', width: '100%' }} >

            <div style={{ flex: 1 }}>
                <div style={{ marginBottom: '8px', width: '70%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
                <div style={{ width: '50%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            </div>


            <style>
                {`
                @keyframes shimmer-animation {
                    0% {
                        background-position: -200%;
                    }
                    100% {
                        background-position: 200%;
                    }
                }

                @keyframes shimmer-effect {
                    0% {
                    opacity: 0.3;
                    }
                    50% {
                    opacity: 0.6;
                    }
                    100% {
                    opacity: 0.3;
                    }
                }
                `}
            </style>
        </div>
        
        <div style={{ display: 'flex', justifyContent : 'space-between', padding: '20px', background: '#333', borderRadius: '8px', width: '100%' }} >
        
            <div style={{ width: '290px', height: '180px', borderRadius: '8px', marginRight: '20px', background: '#222222', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            <div style={{ width: '290px', height: '180px', borderRadius: '8px', marginRight: '20px', background: '#222222', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            <div style={{ width: '290px', height: '180px', borderRadius: '8px', marginRight: '20px', background: '#222222', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>

            <style>
                {`
                @keyframes shimmer-animation {
                    0% {
                        background-position: -200%;
                    }
                    100% {
                        background-position: 200%;
                    }
                }

                @keyframes shimmer-effect {
                    0% {
                    opacity: 0.3;
                    }
                    50% {
                    opacity: 0.6;
                    }
                    100% {
                    opacity: 0.3;
                    }
                }
                `}
            </style>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', padding: '20px', background: '#333', borderRadius: '8px', width: '100%' }} >
            <div style={{ width: '120px', height: '90px', borderRadius: '8px', marginRight: '20px', background: '#222222', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>

            <div style={{ flex: 1 }}>
                <div style={{ marginBottom: '8px', width: '70%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
                <div style={{ width: '50%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            </div>

            <style>
                {`
                @keyframes shimmer-animation {
                    0% {
                        background-position: -200%;
                    }
                    100% {
                        background-position: 200%;
                    }
                }

                @keyframes shimmer-effect {
                    0% {
                    opacity: 0.3;
                    }
                    50% {
                    opacity: 0.6;
                    }
                    100% {
                    opacity: 0.3;
                    }
                }

                `}
            </style>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'end', padding: '20px', background: '#333', borderRadius: '8px', width: '100%' }} >
        
            <div style={{ flex: 1}}>
                <div style={{ marginBottom: '8px', width: '70%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
                <div style={{ width: '50%', height: '20px', background: '#222222', borderRadius: '4px', animation: 'shimmer-animation 1.5s infinite alternate' }}></div>
            </div>
            <style>
                {`
                @keyframes shimmer-animation {
                    0% {
                        background-position: -200%;
                    }
                    100% {
                        background-position: 200%;
                    }
                }

                @keyframes shimmer-effect {
                    0% {
                    opacity: 0.3;
                    }
                    50% {
                    opacity: 0.6;
                    }
                    100% {
                    opacity: 0.3;
                    }
                }

                `}
            </style>
        </div>
    </>
   
  );
}

export default Shimmer;
