import React from "react";
import PageNotFound from "../../../../src/img/content/bg/page-not-found.png";

const ErrorPage: React.FC = () => {
  return (
    <>
      <div className="page__not__found__card">
        <img
          src={PageNotFound}
          alt=""
          className="img-fluid w-100 error--image"
          style={{ width: "auto", height: "auto" }}
          loading="lazy"
        />
      </div>
    </>
  );
};

export default ErrorPage;
